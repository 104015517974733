import { useState } from 'react';

const useAlert = () => {
  const [alertmsg, setAlert] = useState({
    visible: false,
    type: 'info',
    message: '',
  });

  const showAlert = (type, message) => {
    setAlert({ type, message, visible: true });
  };

  const hideAlert = () => {
    setAlert((prevAlert) => ({ ...prevAlert, visible: false }));
  };

  return { alertmsg, showAlert, hideAlert };
};

export default useAlert;
