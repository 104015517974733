// ResetPasswordForm.js

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setMessageSlice, setShowpopup } from '../../../Redux/Slices/LoginSlice';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';

const ResetPasswordForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, serLoader] = useState(false);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    serLoader(true)
    try {
      await axios.post(`https://boxlane-61739ddb4424.herokuapp.com/api/v1/reset-password/${token}`, { newPassword });
      setMessage('Password reset successful');
      dispatch(setShowpopup(true));
      serLoader(false);
      dispatch(setMessageSlice("reset-password"));
      navigate('/');
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <div className='singup-wrapper'>
          <h2 className="text-center" style={{fontSize:'30px',color:'#1A0F31',fontWeight:'700'}}>Reset Password</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={handlePasswordChange}
                required
              />
            </Form.Group>

            {/* <Button variant="primary" type="submit" className="w-100 mt-3">
              Reset Password
            </Button> */}

            <div className="login-btn">
              <button type="submit" className="w-100 mt-3">{loader ? <Spinner/> : "Reset Password"}</button>
            </div>
          </Form>
          </div>
          {message && <p className="mt-3 text-center">{message}</p>}
        </Col>
      </Row>
    </Container>

  );
};

export default ResetPasswordForm;
