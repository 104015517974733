import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Signin from './Signin/Signin';
import Signup from './Signup/Signup';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageSlice, setShowpopup } from '../../Redux/Slices/LoginSlice';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import AlertComponent from '../AlertComponent/AlertComponent';
import useAlert from '../../hooks/useAlert/useAlert';

const Main = (props) => {
const dispatch = useDispatch();
  const [signinsection, setSigninsection] = useState('Signin');

  const { alertmsg, showAlert, hideAlert } = useAlert();

  const { messageSlice } = useSelector((state) => state.loginpopup);

  const handleClose = () => {
    // props.setShow(false)
    dispatch(setShowpopup(false));
    setSigninsection('Signin')
  };
  // const handleShow = () => setShow(true);

  useEffect(() => {
    let alertShown = false;

    if (messageSlice && messageSlice === 'Email-sent') {
        showAlert("success", "Reset Password Link sent to You email");
        alertShown = true;
    }

    if (messageSlice && messageSlice === 'reset-password') {
      showAlert("success", "Password Update Sucessfully, Login to Your Account");
      alertShown = true;
    }

    return () => {
        if (alertShown && messageSlice) {
            dispatch(setMessageSlice(""));
            alertShown = false;
        }
    };
}, [messageSlice, dispatch, showAlert]);

  return (
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <AlertComponent
          type={alertmsg.type}
          message={alertmsg.message}
          visible={alertmsg.visible}
          onClose={hideAlert}
        />

      <Modal show={props.show} onHide={handleClose} size="lg" centered className="signup-modal">
        <Modal.Header closeButton>
        {signinsection === 'Signin' && <Modal.Title>Sign in to continue</Modal.Title>}
        {signinsection === 'Signup' && <Modal.Title>Sign up to continue</Modal.Title>}
        {signinsection === 'forgot-password' &&<Modal.Title>Enter email to continue</Modal.Title>}

        </Modal.Header>
        <Modal.Body>
          <div className="row">

          {signinsection === 'Signin' && <Signin setSigninsection={setSigninsection} setShow={props.setShow}/>}
          {signinsection === 'Signup' && <Signup setSigninsection={setSigninsection} setShow={props.setShow}/>}
          {signinsection === 'forgot-password' && <ForgotPasswordForm setSigninsection={setSigninsection}/>}

            <div className="col-lg-6">
              <div className="image-holder-wrapper">
                <img src="https://theboxlane.com/assets/3d-images/image.png" alt="" /> 
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Main