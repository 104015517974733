import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import TWEEN from "@tweenjs/tween.js";
// import Form from "../../Form";
import "../../../App.css";
// import mailer1 from './Mailer Box.png';
import { Icon } from '@iconify/react';
import MylarBagForm from "../MylarBagForm/MylarBagForm";
// import Testnew from "../../Testnew";

const MylarBagModel = () => {
  // const [xyzvalue, setxyzValues] = useState({ x: 14 , y: 4, z: 10 });
  const [xyzvalue, setxyzValues] = useState({ x: '' , y: '', z: '' });
  const [showMailerBox, setShowMailerBox] = useState(false); // Updated initial state
 
  const box = new URL("../../../Models_gltf/MylarBag2.gltf", import.meta.url);
  const containerRef = useRef();
  
  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  
    renderer.setPixelRatio(window.devicePixelRatio * 1);

    renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
    renderer.domElement.style.position = "relative";
    renderer.domElement.style.right = "0%";
    renderer.domElement.style.top = "0%";

    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }

    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#fbfaf5"); // Set the background color to light blue
    let cameraFOV = 35; // Default camera FOV
    const camera = new THREE.PerspectiveCamera(cameraFOV, window.innerWidth / window.innerHeight, .5, 1000);
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    // directionalLight.position.set(0.7, 0.7, 1);
    directionalLight.position.set(0, 0, -1);
    scene.add(directionalLight);
    
    const hemisphereLight = new THREE.HemisphereLight("", "" , 1);
    scene.add(hemisphereLight);
    renderer.setClearColor(0xffffff);


    const orbit = new OrbitControls(camera, renderer.domElement);
    camera.position.set(-8, 5, -30);
    // camera.position.set(22, 5, -15);
    // camera.position.set(25, 5, -15);
    orbit.update();

    let mixer;

    if (showMailerBox) {
      const mailerbox = new GLTFLoader();
      mailerbox.load(box.href, function (gltf) {

        const model = gltf.scene;
        
        // model.position.set(0, -0.3, 0);
        model.position.set(0, -4.9, 0);
        scene.add(model);

        console.log("model",model)

        // Animation Part Start
        mixer = new THREE.AnimationMixer(model);


        const clips = gltf.animations;

        clips.forEach(function (clip) {
          const action = mixer.clipAction(clip);
          action.play();
        });
        

        camera.fov = cameraFOV;

      // CAMERA POSITION START
      // camera.position.set(8, 5, -15);

      // camera.fov = cameraFOV;
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

        setxyzValues((prevValues) => {
          const modelObject = model.getObjectByName(model.name); // Assuming "Cube" is the root object in your model
  modelObject.scale.set(
    prevValues.z * .155,
    prevValues.y * .155,
    6 * .31,    
  );
  

  return prevValues;
});
// const modelObject = model.getObjectByName(model.name);
// console.log("modelObject",modelObject)


console.log("xyzvalues",xyzvalue)

        const clock = new THREE.Clock();
        function animate() {
          if (mixer) mixer.update(clock.getDelta());
          renderer.render(scene, camera);
          TWEEN.update();
        }

        renderer.setAnimationLoop(animate);
        // Animation part Close
      });
    }

    return () => {
      if (containerRef.current && renderer.domElement && containerRef.current.contains(renderer.domElement)) {
        containerRef.current.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, [xyzvalue, showMailerBox, box.href]);


  const handleSwitchToggle = () => {
    setShowMailerBox(!showMailerBox);
  };
console.log("xyz",xyzvalue)
  return (
    <div className="container" style={{ padding: "0px 15px" }}>
      <div className="row">
        <div className="col-lg-7">
          <div className="product-details">
            <div className="product-content">
              <h1 className="text-dark">Mylar Bag</h1>
              <p className="text-dark">High quality printing on durable  Corrugated  Cardboard.</p>
            </div>  
            <div className="product-gallery">
           
              <div className="model-container">
                {!showMailerBox && (
                  <img src="https://theboxlane.com/assets/web-images/ediblemylarbags/1_edibles_mylar_bags.webp" alt="Mailer 1" />
                )}
                {
              showMailerBox ?
                
                (xyzvalue.x === '' || xyzvalue.y === '' || xyzvalue.z === '') ?
                <span class="threed-modal-alternative">Please select the dimensions</span>:
                  <div ref={containerRef}></div>
                  
                : null
                }
                
              </div>
              <div className="threed-toggle-btn">
                    <label className="switch">
                      <input type="checkbox" checked={showMailerBox} onChange={handleSwitchToggle} />
                      <span className="slider round "></span>
                    </label>
                    <span className="preview-btn">3d Preview</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="form-container">
            <MylarBagForm xyzvalue={xyzvalue} setxyzValues={setxyzValues} setShowMailerBox={setShowMailerBox} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MylarBagModel;