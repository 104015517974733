import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SelectedOptionContext } from "../../../Utils/Formdata";
import { Icon } from "@iconify/react";
import "../../../App.css";
import { useDispatch } from "react-redux";
import { setShowpopup } from "../../../Redux/Slices/LoginSlice";
import Cookies from "js-cookie";
import { AuthContext } from "../../../context/AuthContext";

const ProductBoxForm = ({ xyzvalue, setxyzValues, setShowMailerBox }) => {
  const { setQuantity } = useContext(SelectedOptionContext);
  const { selectedOption, setSelectedOption } = useContext(
    SelectedOptionContext
  );
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isCustomizeClicked, setIsCustomizeClicked] = useState(false);
  const [isStandardClicked, setIsStandardClicked] = useState(true);
  const [isToggleOn, setIsToggleOn] = useState(false);
  const Dollar = 270;
  const card_rate = 500;
  const [L, setL] = useState("");
  const [W, setW] = useState("");
  const [D, setD] = useState("");
  const [boxtype, setBoxtype] = useState("Cardstock");
  const [Papervalue, setPapervalue] = useState("");
  const [Q, setQ] = useState(0);
  const [gsm, setGsm] = useState("");
  const [unitCost, setunitCost] = useState(0);
  const [Rush, setRush] = useState(false);
  const [standard, setStandard] = useState(true);
  const { unitprice, setUnitPrice } = useContext(SelectedOptionContext);
  const { estimatedCost, setEstimatedCost } = useContext(SelectedOptionContext);
  const { material, setMaterial } = useContext(SelectedOptionContext);
  const { Coating, setCoating } = useContext(SelectedOptionContext);
  const { slides, setSlides } = useContext(SelectedOptionContext);
  const { boxname, setSetboxname } = useContext(SelectedOptionContext);
  const { paper, setPaper } = useContext(SelectedOptionContext);
  const { time, setTime } = useContext(SelectedOptionContext);
  const [lengthErrorMessage, setLengthErrorMessage] = useState("");
  const [widthErrorMessage, setWidthErrorMessage] = useState("");
  const [depthErrorMessage, setDepthErrorMessage] = useState("");
  const [materialErrorMessage, setMaterialErrorMessage] = useState("");
  const [paperErrorMessage, setPaperErrorMessage] = useState("");
  const [LWDErrorMessage, setLWDErrorMessage] = useState("");
  const [slidesErrorMessage, setSlidesErrorMessage] = useState("");

  const [nameError, setnameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [phoneError, setphoneError] = useState("");

  const [name, setname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setemail] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    setSetboxname("Product");
  }, []);

  const { user } = useContext(AuthContext);

  const handlenamechange = (event) => {
    const val = event.target.value;
    setnameError("");
    setname(val);
  };

  const handlephonechange = (event) => {
    const val = event.target.value;
    setphoneError("");
    setPhone(val);
  };

  const handleemailchange = (event) => {
    const val = event.target.value;
    setemailError("");
    setemail(val);
  };

  const handleCustomizeClick = () => {
    setIsCustomizeClicked(true);
    setxyzValues({ x: "", y: "", z: "" });
    setIsStandardClicked(false);
    setIsToggleOn(false);
  };

  const handleSelectChange = (e) => {
    const optionValue = e.target.value;
    setSelectedOption(optionValue);
    setShowMailerBox(true);

    if (
      e.target.value.z === 0 ||
      e.target.value.x === 0 ||
      e.target.value.y === 0
    ) {
      setLWDErrorMessage(
        <span style={{ color: "red" }}>Select L x W x D</span>
      );
    } else {
      setLWDErrorMessage("");
    }

    switch (optionValue) {
      case "3 x 3 x 1":
        setxyzValues({ x: 3, y: 1, z: 3 });
        break;
      case "6 x 4 x 2":
        setxyzValues({ x: 4, y: 2, z: 6 });
        break;
      case "6 x 6 x 2":
        setxyzValues({ x: 6, y: 2, z: 6 });
        break;
      case "8 x 8 x 3":
        setxyzValues({ x: 8, y: 3, z: 8 });
        break;
      case "9 x 6 x 2":
        setxyzValues({ x: 6, y: 2, z: 9 });
        break;
      case "10 x 8 x 3":
        setxyzValues({ x: 8, y: 3, z: 10 });
        break;
      case "10 x 8 x 4":
        setxyzValues({ x: 8, y: 4, z: 10 });
        break;
      case "9 x 12 x 2":
        setxyzValues({ x: 12, y: 2, z: 9 });
        break;
      case "12 x 10 x 4":
        setxyzValues({ x: 10, y: 4, z: 12 });
        break;
      case "14 x 10 x 4":
        setxyzValues({ x: 10, y: 4, z: 14 });
        break;
      default:
        break;
    }
  };

  useEffect(() => {}, [Rush]);

  // Destructure quantity from the context
  const { quantity } = useContext(SelectedOptionContext);

  const submit = (e) => {
    e.preventDefault();

    if (xyzvalue.x === 0 || xyzvalue.x === "" || isNaN(xyzvalue.x)) {
      setLengthErrorMessage(
        <span style={{ color: "red" }}>Select Length</span>
      );
    }

    if (xyzvalue.z === 0 || xyzvalue.z === "" || isNaN(xyzvalue.z)) {
      setWidthErrorMessage(<span style={{ color: "red" }}>Select width</span>);
    }

    if (xyzvalue.y === 0 || xyzvalue.y === "" || isNaN(xyzvalue.y)) {
      setDepthErrorMessage(<span style={{ color: "red" }}>Select Height</span>);
    } else if (xyzvalue.z < 1 || xyzvalue.z > 30 || isNaN(xyzvalue.z)) {
      setLengthErrorMessage(
        <span style={{ color: "red" }}>Length range 1 - 30</span>
      );
    } else if (xyzvalue.x < 1 || xyzvalue.x > 20 || isNaN(xyzvalue.x)) {
      setWidthErrorMessage(
        <span style={{ color: "red" }}>Width range 1 - 20</span>
      );
    } else if (xyzvalue.y < 1 || xyzvalue.y > 20 || isNaN(xyzvalue.y)) {
      setDepthErrorMessage(
        <span style={{ color: "red" }}>Depth range 1 - 20</span>
      );
    } else if (Papervalue === "select" || Papervalue === "") {
      if (boxtype === "Cardstock" && Papervalue === "") {
        // console.log("Papervalue on submit is : ", Papervalue)
        setPaperErrorMessage(
          <span style={{ color: "red" }}>Select Material</span>
        );
      } else if (boxtype === "Corrugated") {
        setPaperErrorMessage("");
      }
    } else if (gsm === "" || gsm === "select") {
      // console.log("gsm on submit is : ", gsm)
      setMaterialErrorMessage(
        <span style={{ color: "red" }}>Select Coating</span>
      );
    }
    // const [paperErrorMessage, setPaperErrorMessage] = useState("");
    else if (slides === "" || slides === "select") {
      setSlidesErrorMessage(
        <span style={{ color: "red" }}>Select Printed Sides</span>
      );
    } else if (Q < 50 || isNaN(Q)) {
      setErrorMessage(
        <span style={{ color: "red" }}>Quantity must be at least 50</span>
      );
    } else {
      setErrorMessage("");
      setLengthErrorMessage("");
      setWidthErrorMessage("");
      setDepthErrorMessage("");
      setLWDErrorMessage("");
      setMaterialErrorMessage("");
      setSlidesErrorMessage("");
      setPaperErrorMessage("");
      setnameError("");
      setemailError("");
      setphoneError("");
      setname("");
      setemail("");
      setPhone("");
      // navigate("/screen2");
      submitFormtoApi();
    }

    setSelectedOption(xyzvalue.z + " x " + xyzvalue.x + " x " + xyzvalue.y);
  };

  const submitFormtoApi = () => {
    const data = {
      Length: L,
      Width: W,
      Depth: D,
      Quantity: Q,
      BoxType: boxtype,
      Paper: Papervalue,
      Material: gsm,
      unitCost: unitCost,
      estimatedCost: estimatedCost,
      PrintedSides: slides,
      name: name,
      email: email,
      phone: phone,
    };

    // const cookieUser = Cookies.get("currentloginUserboxlane");
    // const isuser = localStorage.getItem("currentloginUserboxlane");

    //  console.log("Productform",cookieUser)

    if(!user){
      dispatch(setShowpopup(true));
    }
    if(user){
    navigate("/shipping-address");
    }
  };

  const handleLengthChange = (event) => {
    const newValue = parseFloat(event.target.value);

    // Check if the new value is an empty string
    if (event.target.value === "") {
      setLengthErrorMessage("");
      setL(""); // Assuming you want to clear the state when the input is empty
      setxyzValues((prevValues) => ({ ...prevValues, x: "" }));
      setShowMailerBox(true);
      // console.log(newValue);
      return;
    } else if (newValue < 1 || newValue > 20 || isNaN(newValue)) {
      // setxyzValues((prevValues) => ({ ...prevValues, x: newValue }));
      setLengthErrorMessage(
        <span style={{ color: "red" }}>Length range 1 - 20</span>
      );
      setTimeout(() => {
        setLengthErrorMessage("");
      }, 1500);
    } else {
      setLengthErrorMessage("");
      setL(newValue);
      setxyzValues((prevValues) => ({ ...prevValues, x: newValue }));
      setShowMailerBox(true);
      // console.log(newValue);

      if (newValue > W && newValue !== W) {
        setLengthErrorMessage(
          <span style={{ color: "red" }}>
            Length should not be greater than width
          </span>
        );
      }
    }
  };

  const handleWidthChange = (event) => {
    const newValue = parseFloat(event.target.value);

    // Check if the new value is an empty string
    if (event.target.value === "") {
      setLengthErrorMessage("");
      setWidthErrorMessage(""); // Assuming you want to clear the width error message as well
      setW(""); // Assuming you want to clear the state when the input is empty
      setxyzValues((prevValues) => ({ ...prevValues, z: "" }));
      return;
    } else if (newValue < 1 || newValue > 20 || isNaN(newValue)) {
      setWidthErrorMessage(
        <span style={{ color: "red" }}>Width range 1 - 20</span>
      );
      setTimeout(() => {
        setWidthErrorMessage("");
      }, 1500);
    } else {
      setLengthErrorMessage("");
      setWidthErrorMessage("");
      setW(newValue);
      setxyzValues((prevValues) => ({ ...prevValues, z: newValue }));

      if (newValue < L && newValue !== L) {
        setLengthErrorMessage(
          <span style={{ color: "red" }}>
            Length should not be greater than width
          </span>
        );
      }
    }
  };

  const handleDepthChange = (event) => {
    const newValue = parseFloat(event.target.value);

    // Check if the new value is an empty string
    if (event.target.value === "") {
      setDepthErrorMessage("");
      setD(""); // Assuming you want to clear the state when the input is empty
      setxyzValues((prevValues) => ({ ...prevValues, y: "" }));
      return;
    }

    if (newValue < 1 || newValue > 6 || isNaN(newValue)) {
      setDepthErrorMessage(
        <span style={{ color: "red" }}>Depth range 1 - 6</span>
      );
      setTimeout(() => {
        setDepthErrorMessage("");
      }, 1500);
    } else {
      setDepthErrorMessage("");
      setD(newValue);
      setxyzValues((prevValues) => ({ ...prevValues, y: newValue }));
    }
  };

  const handleQuantityChange = (event) => {
    setQ(parseFloat(event.target.value));
    const qty = parseInt(event.target.value, 10);

    if (qty < 50 || isNaN(qty)) {
      setErrorMessage(
        <span style={{ color: "red", marginLeft: "1rem" }}>
          Quantity must be at least 50
        </span>
      );
    } else if (qty > 10000) {
      setErrorMessage(
        <span style={{ color: "red", marginLeft: "1rem" }}>
          Quantity cannot exceed 10,000
        </span>
      );
    } else {
      setErrorMessage("");
    }

    setQuantity(event.target.value);
  };

  const handleboxtype = (event) => {
    setBoxtype(event.target.value);
    console.log("boxtype CHECKED", boxtype);
    setMaterial(event.target.value);
    setCoating(event.target.value);

    setGsm("");
    setMaterialErrorMessage("");
    setPapervalue("");
  };

  const handlePaper = (event) => {
    setPapervalue(event.target.value);
    setPaper(event.target.value);
    console.log("setPaperErrorMessage", Papervalue);
    if (event.target.value === "select") {
      setPaperErrorMessage(
        <span style={{ color: "red" }}>Select Material</span>
      );
    } else {
      setPaperErrorMessage("");
    }
  };

  const handleGsmChange = (event) => {
    setGsm(event.target.value);
    // console.log("GSM CHECKED" ,gsm)
    setMaterial(event.target.value);
    setCoating(event.target.value);
    if (event.target.value === "select") {
      setMaterialErrorMessage(
        <span style={{ color: "red" }}>Select Material</span>
      );
    } else {
      setMaterialErrorMessage("");
    }
  };

  const handleStandardChange = (event) => {
    setRush(false);
    setStandard(true);
    setTime(event.target.value);
  };

  const handleRushChange = (event) => {
    setRush(true);
    setStandard(false);
    setTime(event.target.value);
  };

  const handlePrintedData = (event) => {
    setSlides(event.target.value);
    if (event.target.valuees === "select") {
      setSlidesErrorMessage(
        <span style={{ color: "red" }}>Select Printed Sides</span>
      );
    } else {
      setSlidesErrorMessage("");
    }
  };

  useEffect(() => {
    if (isStandardClicked) {
      setL(xyzvalue.x);
      setW(xyzvalue.z);
      setD(xyzvalue.y);
    }
  }, [isStandardClicked, xyzvalue]);

  useEffect(() => {
    if (Q <= 10000) {
      let cost = calculateEstimatedCost();
      if (Rush !== false) {
        cost = parseInt(calculateEstimatedCost());
        var addon = cost * 0.35;
        cost = cost + addon;
      }
      if (
        isNaN(L) ||
        L === 0 ||
        L === "" ||
        isNaN(W) ||
        W === 0 ||
        W === "" ||
        isNaN(D) ||
        D === 0 ||
        D === ""
      ) {
        setunitCost("");
        setUnitPrice("");
        setEstimatedCost("");
      } else {
        const UnitCost = parseFloat(cost / Q).toFixed(2);
        setunitCost(UnitCost);
        setUnitPrice(UnitCost);
        setEstimatedCost(cost);
      }
    }
    // Check if quantity is 50 and all dimensions are 1, then set unit price to 121.52
  }, [Papervalue, quantity, Q, gsm, L, W, D, Rush]);

  const calculateEstimatedCost = () => {
    // Check if any input value is NaN or missing
    // (L == 0 || NaN(L) || W == 0 || isNaN(W) || D == 0 || isNaN(D) || Q == 0 || isNaN(Q))

    if (
      (isNaN(xyzvalue.x) || xyzvalue.x === 0 || xyzvalue.x === "") &&
      (isNaN(xyzvalue.z) || xyzvalue.z === 0 || xyzvalue.z === "") &&
      (isNaN(xyzvalue.y) || xyzvalue.y === 0 || xyzvalue.y === "")
    ) {
      return 0;
    }

    const l1 = parseFloat(xyzvalue.y * 3 + xyzvalue.z * 2);
    const w1 = xyzvalue.x;

    // console.log("Papervalue",Papervalue)
    // Calculate gsm_cost based on the selectedMaterial
    // let gsm_cost;
    // if (l1*w1 < 900) {
    //   gsm_cost = 1700;
    // } if (l1*w1 > 900) {
    //   gsm_cost = 1200;
    // }
    let gsm_cost;
    if (Papervalue === "16pt Cardstock") {
      gsm_cost = 300;
    }
    if (Papervalue === "18pt Cardstock") {
      gsm_cost = 350;
    }
    if (Papervalue === "20pt Cardstock") {
      gsm_cost = 500;
    }
    if (Papervalue === "24pt Cardstock") {
      gsm_cost = 600;
    }

    // const wt = [(l1*w1*gsm_cost)/1550000]*[Q+10];
    const wt = ((l1 * w1 * gsm_cost) / 1550000) * (Q + 10);

    const total_wt = wt * 1.06;

    let wt_cost;
    if (total_wt <= 20) {
      wt_cost = total_wt * 4200;
    }
    if (total_wt <= 40) {
      wt_cost = total_wt * 3200;
    }
    if (total_wt > 40) {
      wt_cost = total_wt * 2900;
    }

    //1 card cost
    // const card_cost = [(l1*w1*gsm_cost)/1550000]*[Q+50]*250
    const card_cost = ((l1 * w1 * gsm_cost) / 1550000) * (Q + 150) * 400;

    //2 die cost
    const die_cost = 1500;

    //3 plates cost
    let plates_cost;
    if (l1 * w1 < 280) {
      plates_cost = 2500;
    }
    if (l1 * w1 >= 280 && l1 * w1 < 420) {
      plates_cost = 3500;
    }
    if (l1 * w1 >= 420 && l1 * w1 < 1053) {
      plates_cost = 8000;
    }

    //4 printing cost
    let printing_cost;
    if (l1 * w1 < 280) {
      printing_cost = 4000;
    }
    if (l1 * w1 >= 280 && l1 * w1 < 420) {
      printing_cost = 5000;
    }
    if (l1 * w1 >= 420 && l1 * w1 < 1053) {
      printing_cost = 9000;
    }

    //5 lamination cost
    const lamination_cost = ((l1 * w1) / 144) * (Q * +150) * 4;

    //8 cutting cost
    let cutting_cost;
    if (Q <= 1000) {
      cutting_cost = 1000;
    }
    if (1000 < Q && Q <= 2000) {
      cutting_cost = 2000;
    }
    if (2000 < Q && Q <= 3000) {
      cutting_cost = 3000;
    } else {
      // Add 1000 for every 1000 increase beyond 3000
      cutting_cost = 3000 + Math.floor((Q - 3000) / 1000) * 1000;
    }

    const Dollar_price = 270;
    //production cost
    const production_cost =
      (card_cost +
        die_cost +
        plates_cost +
        printing_cost +
        lamination_cost +
        cutting_cost) *
      1.1;

    console.log("production_cost", production_cost);
    // Calculate the estimated cost
    let estimated_cost = (wt_cost + production_cost) * 1.4;
    console.log("estimated_cost", estimated_cost);

    estimated_cost = (estimated_cost / Dollar_price).toFixed(2);
    console.log("estimated_cost", estimated_cost);

    return estimated_cost;
  };

  return (
    <>
      <div className="controunded">
        <div className="row" style={{ margin: "0" }}>
          <div className="form-wrapper" style={{ padding: "0px 0px" }}>
            <div className="form-header">
              <span>Customize & Check Prices</span>
            </div>
            <div className="form-fields">
              <div>
                <div className="px-3">
                  {/* {isCustomizeClicked ? ( */}
                  <div className="row">
                    <div className="col-lg-4 pe-2">
                      <label for="Length(in)">Length(in)</label>
                      <input
                        type="number"
                        className="form-control"
                        value={L}
                        min="1"
                        onChange={handleLengthChange}
                        placeholder="Length(in)"
                        required
                      />
                      <div>{lengthErrorMessage}</div>
                    </div>
                    <div className="col-lg-4 pe-2">
                      <label for="Length(in)">Width(in)</label>
                      <input
                        type="number"
                        className="form-control"
                        value={W}
                        min="1"
                        onChange={handleWidthChange}
                        placeholder="Width(in)"
                        required
                      />
                      <div>{widthErrorMessage}</div>
                    </div>
                    <div className="col-lg-4">
                      <label for="Length(in)">Height(in)</label>
                      <input
                        type="number"
                        className="form-control"
                        value={D}
                        min="1"
                        onChange={handleDepthChange}
                        placeholder="Height(in)"
                        required
                      />
                      <div>{depthErrorMessage}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="px-3 mt-2">
                    <label for="Select Paper">Select Paper</label>
                    <select
                      name=""
                      id=""
                      value={Papervalue}
                      className="form-select small-input"
                      onChange={handlePaper}
                      required
                    >
                      <option value="select">Paper</option>
                      <option value="16pt Cardstock">16pt Cardstock</option>
                      <option value="18pt Cardstock">18pt Cardstock</option>
                      <option value="20pt Cardstock">20pt Cardstock</option>
                      <option value="24pt Cardstock">24pt Cardstock</option>
                    </select>
                    <div>{paperErrorMessage}</div>
                  </div>
                  {boxtype === "Cardstock" ? (
                    <div className="px-3 mt-2">
                      <label for="Select Coating">Select Coating</label>
                      <select
                        name=""
                        id=""
                        value={gsm}
                        className="form-select small-input"
                        onChange={handleGsmChange}
                        required
                      >
                        <option value="select">Coating</option>
                        <option value="Glossy Aqueous Coating">
                          Glossy Aqueous Coating
                        </option>
                        <option value="High-Gloss Aqueous Coating">
                          High-Gloss Aqueous Coating
                        </option>
                        <option value="Matte Aqueous Coating">
                          Matte Aqueous Coating
                        </option>
                        <option value="Soft Touch Laminate">
                          Soft Touch Laminate
                        </option>
                      </select>
                      <div>{materialErrorMessage}</div>
                    </div>
                  ) : (
                    <div className="px-3 mt-2">
                      <label for="Select Material">Select Material</label>
                      <select
                        name=""
                        id=""
                        value={gsm}
                        className="form-select small-input"
                        onChange={handleGsmChange}
                        required
                      >
                        <option value="select">Corrugated Material </option>
                        <option value="Standard White with Matte Ink (HD Print)">
                          Standard White with Matte Ink (HD Print)
                        </option>
                        <option value="Standard White with Matte Ink (HD Print)">
                          Standard White with Matte Ink (HD Print)
                        </option>
                        <option value="Premium White with Satin Ink (HD Print)">
                          Premium White with Satin Ink (HD Print)
                        </option>
                        <option value="Premium White with Glossy Ink">
                          Premium White with Glossy Ink
                        </option>
                        <option value="Kraft (Brown) with Matte Ink (HD Print)">
                          Kraft (Brown) with Matte Ink (HD Print)
                        </option>
                      </select>
                      <div>{materialErrorMessage}</div>
                    </div>
                  )}
                  <div className="px-3 mt-2">
                    <label for="Select Printed Sides">
                      Select Printed Sides
                    </label>
                    <select
                      name=""
                      id=""
                      value={slides}
                      className="form-select small-input"
                      onChange={handlePrintedData}
                      required
                    >
                      <option value="Outside Only - Full Color">
                        Outside Only - Full Color
                      </option>
                      <option value="Inside Only - Full Color">
                        Inside Only - Full Color
                      </option>
                      <option value="Outside & Inside - Full Color">
                        Outside & Inside - Full Color
                      </option>
                      <option value="No Printing">No Printing</option>
                    </select>
                    <div>{slidesErrorMessage}</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="px-3 mt-2">
                        <label for="Quantity">Quantity</label>
                        <input
                          type="number"
                          className="form-control w-100 small-input"
                          min="50"
                          onChange={handleQuantityChange}
                          placeholder="Quantity"
                          required
                        />
                      </div>
                      <div>{errorMessage}</div>
                    </div>
                    {Q > 0 && (
                      <div className="col-lg-6 col-md-12">
                        <div className="price-panel">
                          {unitCost && (
                            <div className="unitprice">
                              <div className="d-flex justify-content-end ">
                                <span className="me-3 mt-2">Unit Price</span>
                                <p>{Q >= 50 ? "$ " + unitCost : ""}</p>
                              </div>
                            </div>
                          )}
                          {estimatedCost && (
                            <div className="subtotal">
                              <div className="d-flex justify-content-end ">
                                <span className="me-3">Subtotal:</span>
                                <p>{Q >= 50 ? "$ " + estimatedCost : ""}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mx-2 mt-3 px-2 g-1">
                  <div className="col-lg-12 col-md-12">
                    <div className="text-light turnaround-panel">
                      <div className="turnaround">Turnaround Time</div>
                      <div className="shipping-check">
                        <div className="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="shippingOption"
                            value="Standard (10-12 Business Days)"
                            onChange={handleStandardChange}
                            checked={standard}
                            required
                          />
                          <label
                            class="form-check-label"
                            for="Standard (10-12 Business Days)"
                          >
                            Standard (10-12 Business Days)
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="shippingOption"
                            value="Rush (5-6 Business Days)"
                            onChange={handleRushChange}
                            required
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Rush (5-6 Business Days)
                          </label>
                        </div>
                      </div>
                    </div>
                    <p className="design-s">We Offer Free Design Support</p>
                  </div>
                </div>
                <div className="mx-3 mt-1 submit-btn-wrapper">
                  <button onClick={submit}>
                    Order now and Receive Dieline
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBoxForm;