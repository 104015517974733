import React, { useState , useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { SelectedOptionContext } from '../Utils/Formdata';
import { Icon } from '@iconify/react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
// import visa from '../visa.png';
// import master from '../master-card.png';
// import paypal from '../paypal.png';
import { Checkbox, FormControlLabel } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import { PDFDocument, rgb } from 'pdf-lib';
import html2pdf from 'html2pdf.js';
import { useNavigate } from "react-router-dom";
import successgif from '../success-gif.gif';


const Modal2 = (props) => {
  const navigate = useNavigate();
  const [cardNumber, setCardNumber] = useState('');
  const [load, setLoad]= useState(false);
  const [expirationMonth, setExpirationMonth] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [SuccessPopupModal, setSuccessPopupModal] = useState(false);
  
  const [errmsg, setErrmsg] = useState('');

  console.log(expirationMonth , '.........emonth')

  const [expirationYear, setExpirationYear] = useState('');
  console.log(expirationYear , '.........eyear')
  const [cvv, setCvv] = useState('');

  const { fullName } = useContext(SelectedOptionContext);
  const { firstName } = useContext(SelectedOptionContext);
  const { secondName } = useContext(SelectedOptionContext);
  const { unitprice } = useContext(SelectedOptionContext);
  const { totalPrice } = useContext(SelectedOptionContext);
  const { slides } = useContext(SelectedOptionContext);
  const { time } = useContext(SelectedOptionContext);
  const { quantity } = useContext(SelectedOptionContext);
  const { adress } = useContext(SelectedOptionContext);
  const { zipcode } = useContext(SelectedOptionContext);
  const { city } = useContext(SelectedOptionContext);
  const { state } = useContext(SelectedOptionContext);
  const { email } = useContext(SelectedOptionContext);
  const { phone } = useContext(SelectedOptionContext);
  const { estimatedCost } = useContext(SelectedOptionContext);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const { material } = useContext(SelectedOptionContext);
  const [heading, setHeading] = useState(props.orderInfo.boxtype);
  const { selectedOption } = useContext(SelectedOptionContext);
  const { updateorder, setUpdateorder } = useContext(SelectedOptionContext);



  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);

      const [month, day, year] = formattedDate.split(' ');

      return `${month} ${day} ${year}`;
    }

    const formattedDate = getCurrentDate();
    setCurrentDate(formattedDate);
  }, []);


  let materialColumn = '';
    if (props.orderInfo.boxtype === 'Product') {
        materialColumn = `<th>Coating</th>`;
    }
    if (props.orderInfo.boxtype !== 'Product') {
      materialColumn = `<th>Material</th>`;
  }

  let htmlContent = `
            <section class="invoice-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="invoice-card" style="background-color: #fff; border: 0 solid rgba(0,0,0,.125);">
                            <div class="invoice-body" style="padding:20px;">
                                <div class="invoice-title">
                                 
                                    <div class="mb-4">
                                      <h2 class="mb-1 text-muted">INVOICE</h2>
                                    </div>
                                    <div class="text-muted">
                                        <p class="mb-1">info@theboxlane.com</p>
                                        <p class="mb-1">Pen Argyl, PA 18072</p>
                                        <p class="mb-1">info@theboxlane.com</p>
                                        <p>(484) 224-2666</p>
                                        
                                    </div>
                                </div>

                                <hr class="my-4">

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="text-muted">
                                            <h5 class="font-size-16 mb-3">Billed To:</h5>
                                            <h5 class="font-size-15 mb-2">`+ firstName +" "+ secondName+`</h5>
                                            <p class="mb-1">`+adress+" , "+state +" , " + zipcode+` </p>
                                            <p class="mb-1"><i class="uil uil-envelope-alt me-1"></i>`+ email +`</p>
                                            <p><i class="uil uil-phone me-1"></i> `+ phone +`</p>
                                            
                                        </div>
                                    </div>
                                    <!-- end col -->
                                    <div class="col-sm-6">
                                        <div class="text-muted text-sm-end">
                                            <div class="mt-4">
                                                <h5 class="font-size-15 mb-1">Invoice Date:</h5>
                                                <p>`+ currentDate +`</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                                
                                <div class="py-2 row">
                                    <h5 class="font-size-15">Order Summary</h5>
                                    <div class="table-responsive">
                                        <table class="table align-middle table-nowrap table-centered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Per Unit Price</th>
                                                    <th>Quantity</th>
                                                    ${materialColumn}
                                                    <th>Printing Slides</th>
                                                    <th class="text-end" style="width: 120px;">Total</th>
                                                </tr>
                                            </thead><!-- end thead -->
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div>
                                                            <h6 class="text-truncate font-size-12 mb-1">`+ heading + " " + selectedOption+`</h6>
                                                        </div>
                                                    </td>
                                                    <td>$ `+unitprice+`</td>
                                                    <td>`+quantity+`</td>
                                                    <td>`+material+`</td>
                                                    <td>`+slides+`</td>
                                                    <td class="text-end">$ `+estimatedCost+`</td>
                                                </tr>
                                            </tbody><!-- end tbody -->
                                        </table><!-- end table -->
                                    </div><!-- end table responsive -->
                                </div>
                                <div class="row">
                                  <div class="invoice-total">
                                    <p style="font-size: 28px;font-weight: 500;">INVOICE TOTAL</p>
                                    <span style="
              font-size: 39px;
              color: #4c2454;
              font-weight: 500;
              margin-top: -28px;
              display: inline-block;">$ `+estimatedCost+`</span>
                                  </div>
                                </div>
                                <footer class="invoice-footer" style="background-color:#4c2454; padding:10px 15px; border-radius:15px;margin-top:30px;">
                                  <div class="Invoice-copyright row">
                                    <div class="col-lg-12 text-center">
                                      <p style="color: #ffffff; margin-top: 10px;">© 2023 The Box Lane LLC. All Rights Reserved</p>
                                    </div>
                                  </div>
                                </footer>
                            </div>
                        </div>
                    </div><!-- end col -->
                </div>
            </div>
          </section>
            `;

    let emailbodyHTML =  `
    
      <div class="page-wrapper">
    
    
    <style>
      .order-summary  table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
     .order-summary td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    
    .order-summary tr:nth-child(even) {
      background-color: #dddddd;
    }
    .text-muted{
        color: #6c757d;
    }
    p{
        font-size:18px;
        margin: 0px 0px 15px;
    }
    h5{
        margin:10px 0px;
        font-size:20px;
    }
    .Invoice-copyright{
      display:flex;
      justify-content: space-between;
    }
    </style>
    
    
    <section class="invoice-section">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="invoice-card" style="background-color: #fff; border: 0 solid rgba(0,0,0,.125);">
                      <div class="invoice-body" style="padding:20px;">
                          <div class="invoice-title">
                              <div class="mb-4">
                                <h2 style="font-size:24px;color: #6c757d;margin:20px 0px;">INVOICE</h2>
                              </div>
                              <div class="text-muted">
                                  <p style="font-size: 18px;margin: 0px 0px 15px;color: #6c757d;">Pen Argyl, PA 18072</p>
                                  <p style="font-size: 18px;margin: 0px 0px 15px;color: #6c757d;">info@theboxlane.com</p>
                                  <p style="font-size: 18px;margin: 0px 0px 15px;color: #6c757d;">(484) 224-2666</p>
                              </div>
                          </div>
    
                          <hr class="my-4">
    
                          <div class="row" style="display: flex; justify-content: space-between;">
                              <div class="col-sm-6">
                                  <div class="text-muted">
                                      <h5 class="font-size-16 mb-3">Billed To:</h5>
                                      <h5 class="font-size-15 mb-2">`+ firstName +" "+ secondName+`</h5>
                                      <p style="font-size: 18px;margin: 0px 0px 15px;color: #6c757d;">`+adress+" , "+state +" , " + zipcode+`</p>
                                      <p style="font-size: 18px;margin: 0px 0px 15px;color: #6c757d;">`+ email +`</p>
                                      <p style="font-size: 18px;margin: 0px 0px 15px;color: #6c757d;"> `+ phone +`</p>
                                  </div>
                              </div>
                              <!-- end col -->
                              <div class="col-sm-6">
                                  <div class="text-muted text-sm-end">
                                      <div class="mt-4">
                                          <h5 class="font-size-15 mb-1">Invoice Date:</h5>
                                          <p style="font-size: 18px;margin: 0px 0px 15px;color: #6c757d;">`+ currentDate +`</p>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->
                          </div>
                          <!-- end row -->
                          
                          <div class="py-2 order-summary">
                              <h5 style="font-size:24px; color:#000000;margin: 10px 0px;">Order Summary</h5>
                              <div class="table-responsive">
                                  <table class="table align-middle table-nowrap table-centered mb-0" style="width:100%;">
                                      <thead>
                                          <tr>
                                              <th style="border: 1px solid #dddddd; text-align: left;padding: 8px;">Item</th>
                                              <th style="border: 1px solid #dddddd; text-align: left;padding: 8px;">Per Unit Price</th>
                                              <th style="border: 1px solid #dddddd; text-align: left;padding: 8px;">Quantity</th>
                                              <th style="border: 1px solid #dddddd; text-align: left;padding: 8px;">Material</th>
                                              <th style="border: 1px solid #dddddd; text-align: left;padding: 8px;">Printing Slides</th>
                                              <th style="width: 120px;border: 1px solid #dddddd; text-align: left;padding: 8px;">Total</th>
                                          </tr>
                                      </thead><!-- end thead -->
                                      <tbody>
                                          <tr>
                                              <td style="border: 1px solid #dddddd; text-align: left;padding: 8px;">
                                                <b>`+ heading + " " + selectedOption+`</b>
                                              </td>
                                              <td style="border: 1px solid #dddddd; text-align: left;padding: 8px;">$ `+unitprice+`</td>
                                              <td style="border: 1px solid #dddddd; text-align: left;padding: 8px;">`+quantity+`</td>
                                              <td style="border: 1px solid #dddddd; text-align: left;padding: 8px;">`+material+`</td>
                                              <td style="border: 1px solid #dddddd; text-align: left;padding: 8px;">`+slides+`</td>
                                              <td style="border: 1px solid #dddddd; text-align: left;padding: 8px;">$ `+estimatedCost+`</td>
                                          </tr>
                                      </tbody><!-- end tbody -->
                                  </table><!-- end table -->
                              </div><!-- end table responsive -->
                          </div>
                          <div class="row">
                            <div class="invoice-total" style="margin-top:20px;">
                              <p style="font-size: 28px;font-weight: 500;margin-top:20px;">INVOICE TOTAL</p>
                              <span style="
                  font-size: 39px;
                  color: #4c2454;
                  font-weight: 500;
                  margin-top: -28px;
                  display: inline-block;">$ `+estimatedCost+`
                  </span>
                            </div>
                          </div>
                          <footer style="background-color:#4c2454; padding:10px 15px; border-radius:15px;margin-top:30px;">
                            <div style="height: 50px;">
                              <div  style="display: inline-block; float: left;">
                                <p style="color: #ffffff;margin-bottom: 0;
                                margin-top: 15px;">© 2023 The Box Lane LLC. All Rights Reserved</p>
                              </div>
                              <div>
                                <div class="invoce-logo" style="float: right;">
                                    <img src="https://theboxlane.com/landing-assets/images/reft/new-logo-1.png" alt="" style="height:50px;">
                                </div>
                              </div>
                            </div>
                          </footer>
                      </div>
                  </div>
              </div><!-- end col -->
          </div>
      </div>
    </section>
    </div>  
    
    `;

  const Months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const Years = [
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' },
    { value: '2029', label: '2029' },
    { value: '2030', label: '2030' },
    { value: '2031', label: '2031' },
    { value: '2032', label: '2032' },
    { value: '2033', label: '2033' },
    { value: '2034', label: '2034' },
  ];


  const handleSubmitaa = async (event) => {
    try {
      const response = await axios.post('http://localhost:3000/');
      console.log(response.data);
      alert(response.data);
    } catch (error) {
      console.error(error);
      alert('Payment failed');
    }
  };

  const [errorMessages, setErrorMessages] = useState({
    nameOnCard: '',
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    cvv: '',
  });

  const [nameOnCard, setNameOnCard] = useState('')
  

  const handleSubmit = async (event) => {
    // {console.log('email',email,'fullName',fullName,'firstName',firstName ,'secondName',secondName ,'unitprice',unitprice,'totalprice',totalPrice,'slides',slides,'time',time,'cardNumber', cardNumber , 'cvv' , cvv , 'estimatedCost', estimatedCost , 'quantity' , quantity ,'ZipCode', zipcode ,'Phone', phone)}
    event.preventDefault();
// if(!expirationYear && !cardNumber && !cvv && !expirationMonth && !cardNumber){
//   setErrmsg('All Field Must be Filled')
//   return;
// }

let hasError = false;
    const newErrorMessages = { ...errorMessages };

    if (!nameOnCard.trim()) {
      newErrorMessages.nameOnCard = 'Name on card is required';
      hasError = true;
    } else {
      newErrorMessages.nameOnCard = '';
    }

    if (!cardNumber.trim()) {
      newErrorMessages.cardNumber = 'Card number is required';
      hasError = true;
    } else {
      newErrorMessages.cardNumber = '';
    }

    if (!expirationMonth.trim()) {
      newErrorMessages.expirationMonth = 'Expiration month is required';
      hasError = true;
    } else {
      newErrorMessages.expirationMonth = '';
    }

    if (!expirationYear.trim()) {
      newErrorMessages.expirationYear = 'Expiration year is required';
      hasError = true;
    } else {
      newErrorMessages.expirationYear = '';
    }

    if (!cvv.trim()) {
      newErrorMessages.cvv = 'CVV is required';
      hasError = true;
    } else {
      newErrorMessages.cvv = '';
    }

    setErrorMessages(newErrorMessages);


    setLoad(true);
    const paymentdata = {
      ccnumber:cardNumber,
      ccexp: `${expirationMonth}${expirationYear}`,
      cvv:cvv,
      amount:estimatedCost,
      // fullName: firstName+secondName,
      // firstName,
      // secondName,
      // unitprice,
      // quantity,
      // adress,
      // zipcode,
      // city,
      // phone,
      // material,
      // slides,
      // time,
      // heading,
      // selectedOption,
      // state,
      // email,
      emailbodyHTML

    };
    console.log("DATA ", paymentdata)
    if (!hasError) {
      // const paymentdata = {
      //   amount:estimatedCost,
      //   ccnumber:cardNumber,
      //   ccexp:`${expirationMonth}${expirationYear}`,
      //   cvv: cvv
      // }
   try{ 
    // const response = await doSale(login, billing, req.body.amount, req.body.ccnumber, req.body.ccexp, req.body.cvv);

      // const response = await axios.post('https://boxlane-61739ddb4424.herokuapp.com/api/pay', paymentdata, {credentials: "include"});
      const response = await fetch('https://boxlane-61739ddb4424.herokuapp.com/api/pay', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify(paymentdata)
});

      console.log("API RESPONSE",response);
      const data = await response.json();

      // Handle response data
      console.log("json data",data);
      
      // while (data.status !== "Approved") {
      //   // Wait until payment is approved
      //   await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
      // }
      console.log("updateorder:", updateorder);

      if (updateorder) {
        console.log("updateorder: true",);
        await updateordertodb(updateorder);
      } else {
        console.log("updateorder: false",);
        await addordertodb();
      }
      
     
    } catch (error) {
      console.error(error);
      alert('Payment failed');
  }
}
setLoad(false);
console.log("loader",load);
    
  };

  let orderDetails;
  if(props.orderInfo.boxtype === 'Product'){
  orderDetails = {
    payment: 'paid',
    boxtype: props.orderInfo.boxtype,
    quantity: props.orderInfo.quantity,
    length: props.orderInfo.length,
    width: props.orderInfo.width,
    depth: props.orderInfo.depth,
    Coating: props.orderInfo.Coating,
    paper: props.orderInfo.paper,
    printerSides: props.orderInfo.printerSides,
    turnaroundTime: props.orderInfo.turnaroundTime,
    pricePerItem: props.orderInfo.pricePerItem,
    totalPrice: props.orderInfo.totalPrice,
  };
}
  else{
    orderDetails = {
      payment: 'paid',
    boxtype: props.orderInfo.boxtype,
    quantity: props.orderInfo.quantity,
    length: props.orderInfo.length,
    width: props.orderInfo.width,
    depth: props.orderInfo.depth,
    material: props.orderInfo.material,
    printerSides: props.orderInfo.printerSides,
    turnaroundTime: props.orderInfo.turnaroundTime,
    pricePerItem: props.orderInfo.pricePerItem,
    totalPrice: props.orderInfo.totalPrice,
    };
  }

  const addordertodb = async () => {

    const apiUrl = `https://boxlane-61739ddb4424.herokuapp.com/api/v1/addorder`;
        
        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers if needed
          },
          credentials: "include",
          body: JSON.stringify(orderDetails),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`Request failed with status: ${response.status}`);
            }
            return response.json();
          })
          .then(orderInfo => {
            console.log('Order added successfully:', orderInfo);
             generatePDF();
          })
          .catch(error => {
            console.error('Error adding order:', error);
            // Handle the error as needed
          });
  }

  // const updateordertodb = (updateorder) => {
  //   const apiUrl = `https://boxlane-61739ddb4424.herokuapp.com/api/v1/updateorder/${updateorder}`;
        
  //       fetch(apiUrl, {
  //         method: 'PUT',
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Add the JWT token stored in localStorage
  //           'Content-Type': 'application/json',
  //           // Add any other headers if needed
  //         },
  //         // body: JSON.stringify(orderDetails),
  //         body: JSON.stringify({
  //           payment: 'paid',
  //         }),
  //       })
  //         .then(response => {
  //           if (!response.ok) {
  //             throw new Error(`Request failed with status: ${response.status}`);
  //           }
  //           return response.json();
  //         })
  //         .then(orderInfo => {
  //           console.log('Order added successfully:', orderInfo);
  //           setUpdateorder(null)
  //            generatePDF();
  //         })
  //         .catch(error => {
  //           setUpdateorder(null)
  //           console.error('Error adding order:', error);
  //           // Handle the error as needed
  //         });
  // }

  // Assuming orderId, payment, and date are variables containing the order ID, updated payment status, and date respectively

const updateordertodb = async(orderId) => {

  const apiUrl = `https://boxlane-61739ddb4424.herokuapp.com/api/v1/updateorder/${orderId}`;
        
        fetch(apiUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers if needed
          },
          credentials: "include",
          body: JSON.stringify(orderDetails),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`Request failed with status: ${response.status}`);
            }
            return response.json();
          })
          .then(orderInfo => {
            console.log('Order added successfully:', orderInfo);
            setUpdateorder(null)
             generatePDF();
          })
          .catch(error => {
            console.error('Error adding order:', error);
            setUpdateorder(null)
            // Handle the error as needed
          });
};

  const generatePDF = () => {
    const opt = {
      margin:       1,
      filename:     'receipt.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
    };

    const element = document.createElement('div');
    element.innerHTML = htmlContent;

    html2pdf().from(element).set(opt).save();

    setSuccessPopupModal(true);

     // Wait for 1 second using setTimeout
      // setTimeout(() => {
      //   // Navigate and refresh
      //   navigate("/");
      //   window.location.reload();
      // }, 1000); // 1000 milliseconds = 1 second


  }

  const backtoHome = () => {
        // Navigate and refresh
        navigate("/");
        window.location.reload();
  }

  const handleShowOrderDetails = () => {
    setShowOrderDetails(!showOrderDetails);
  };

  // const generatePDF = async () => {
  // try{
  
  //   const pdfDoc = await PDFDocument.create();
  //   const page = pdfDoc.addPage();

  //   // Add text content
  //   page.drawText('Payment Details:', { x: 50, y: 500, size: 14 });

  //   // Customize and add more content as needed using paymentData
  //   const paymentData = {
  //     Title: quantity + " " + heading + " " + selectedOption,
  //     EstimatedCost: '$' + estimatedCost,
  //     UnitPrice: unitprice,
  //     quantity: quantity,
  //     date: 'August 23, 2023',
  //     CradNumber: cardNumber,
  //     Expiration: `${expirationMonth}/${expirationYear}`,
  //     CVV:cvv,
  //     Name: firstName + " "+secondName,
  //     Address : adress,
  //     ZipCode: zipcode,
  //     City: city,
  //     Phone: phone,
  //     Material: material,
  //     Slides: slides,
  //     State: state
  //     // Add more payment details...
  //   };

  //   let currentY = 460;
  //   Object.entries(paymentData).forEach(([key, value]) => {
  //     page.drawText(`${key}: ${value}`, { x: 50, y: currentY, size: 12 });
  //     currentY -= 20; // Adjust for spacing
  //   });

  //   // Save and open the PDF
  //   const pdfBytes = await pdfDoc.save();
  //   const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  //   const pdfUrl = URL.createObjectURL(pdfBlob);
  //   window.open(pdfUrl);

  // }
  // catch(error) {
  //   console.log(" PDF ERROR" , error)
  //     alert('No payment data available');
  //   }
  // };

  return (
   <>
   {SuccessPopupModal ? 
      <div className='successPopup'>
        <div className="modal show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Payment Successful</h5>
              </div>
              <div className="modal-body text-center">
              <img src={successgif} alt="" style={{ width: "230px" }} />
              <p style={{ fontSize: "20px" }}>
                Payment Successful! Please check your email for the receipt or your download folder.
              </p>
              </div>
              <div className="modal-footer d-flex align-items-center justify-content-center">
                <button onClick={backtoHome} type="button" className="btn bg-purple text-white rounded-pill" >
                    Explore More
                </button>
              </div>
            </div>
          </div>
      </div> 
    </div>
    :   
    <div>
        <div className="payment-form">
        <div className="payment-header">
                    <p>Credit Card Info</p>
                  </div>
          <div className="row px-3">
                <div className="col-lg-12">
                  
                  <div className="text-field">
                    <label>Name On Card</label>
                    <TextField
                      required
                      id="outlined-required"
                      label="Name on card"
                      size="small"
                      value={nameOnCard}
                      onChange={(e) => setNameOnCard(e.target.value)}
                      fullWidth
                      error={!!errorMessages.nameOnCard}
                      helperText={errorMessages.nameOnCard}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  <label>Card Number*</label>
                  <div className="text-field">
                    <TextField
                      required
                      id="outlined-required"
                      label="Card Number"
                      size="small"
                      fullWidth
                      value={cardNumber}
                      onChange={(e) => setCardNumber(e.target.value)}
                      error={!!errorMessages.cardNumber}
                      helperText={errorMessages.cardNumber}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="text-field mt-3">
                    <label>Expire Month*</label>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Expiry Month"
                      value={expirationMonth}
                      onChange={(e) => setExpirationMonth(e.target.value)}
                      size="small"
                      fullWidth
                      required
                      error={!!errorMessages.expirationMonth}
                      helperText={errorMessages.expirationMonth}
                    >
                      {Months.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                <label>Expire Year*</label>
                  <div className="text-field ">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Expiry Year"
                      value={expirationYear}
                      onChange={(e) => setExpirationYear(e.target.value)}
                      size="small"
                      fullWidth
                      required
                      error={!!errorMessages.expirationYear}
                      helperText={errorMessages.expirationYear}
                    >
                      {Years.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                <label>CVV*</label>
                  <div className="text-field">  
                    <TextField
                      required
                      id="outlined-required"
                      label="CVV"
                      size="small"
                      fullWidth
                      value={cvv}
                      onChange={(e) => setCvv(e.target.value)}
                      error={!!errorMessages.cvv}
                      helperText={errorMessages.cvv}
                    />
                  </div>
                </div>
                {/* <span style={{fontSize:'12px', color:'red', margin: '0 auto'}}>{errmsg}</span> */}
                <div className="col-md-12 mt-4 d-flex justify-content-center" >                        
                    <button onClick={handleSubmit}  className="rounded-pill bg-purple text-white w-50 p-2">{load ? <Spinner/> :"Place Order"}</button>                    
                </div>
          </div>
        </div>
</div>} 
</>

  );
};

export default Modal2;
