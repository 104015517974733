// UserSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("currentloginUser") || null, // Initial state of user is null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload; // Set user data to the state
    },
    clearUser(state) {
      state.user = null; // Clear user data from the state
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
