import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import TWEEN from "@tweenjs/tween.js";
// import Form from "../../Form";
import "../../../App.css";
// import mailer1 from './Mailer Box.png';
import { Icon } from '@iconify/react';
// import Testnew from "../../Testnew";
import MailerBoxForm from "../MailerBoxForm/MailerBoxForm";

const Mailerbox = () => {
  // const [xyzvalue, setxyzValues] = useState({ x: 14 , y: 4, z: 10 });
  const [xyzvalue, setxyzValues] = useState({ x: '' , y: '', z: '' });
  const [animateLid, setAnimateLid] = useState(false);
  const [showMailerBox, setShowMailerBox] = useState(false); // Updated initial state
  const [buttonType, setButtonType] = useState('reverse');
 
  const [selectedColor, setSelectedColor] = useState('#FFA500');
  const [getLid, setLid] = useState({ x: 1 , y: 1, z: 1 });


  const box = new URL("../../../Models_gltf/final1.gltf", import.meta.url);
  const containerRef = useRef();
  const lidRef = useRef();
  
  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  
    renderer.setPixelRatio(window.devicePixelRatio * 1);

    renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
    renderer.domElement.style.position = "relative";
    renderer.domElement.style.right = "0%";
    renderer.domElement.style.top = "0%";

    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }

    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#fbfaf5"); // Set the background color to light blue
    let cameraFOV = 10; // Default camera FOV
    const camera = new THREE.PerspectiveCamera(cameraFOV, window.innerWidth / window.innerHeight, .5, 1000);
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0.7, 0.7, 1);
    scene.add(directionalLight);
    
    const hemisphereLight = new THREE.HemisphereLight("", "" , 1);
    scene.add(hemisphereLight);
    renderer.setClearColor(0xffffff);


    const orbit = new OrbitControls(camera, renderer.domElement);
    // camera.position.set(8, 5, -15);
    // camera.position.set(22, 5, -15);
    camera.position.set(25, 2.9, -7);
    orbit.update();

    let mixer;

    if (showMailerBox) {
      const mailerbox = new GLTFLoader();
      mailerbox.load(box.href, function (gltf) {

        const model = gltf.scene;
        
        // model.position.set(0, -0.3, 0);
        model.position.set(0, -0.4, 0);
        scene.add(model);

        // Animation Part Start
        mixer = new THREE.AnimationMixer(model);


        const clips = gltf.animations;

        clips.forEach(function (clip) {
          const action = mixer.clipAction(clip);
          action.play();
        });
        // Find the lid object and store it in a ref
        const lid = model.getObjectByName("Bone");
        const lid1 = model.getObjectByName("Bone");
        lidRef.current = lid1;
 
        lidRef.current = lid;
        
        const lidScale = new THREE.Vector3(1, 1, 1);

        lid.rotation.x = animateLid ? Math.PI / -1 : 0;
        lid.rotation.z = 1
        lid.rotation.y = 0

        lid1.rotation.x = 0
        lid1.rotation.z = 1.4
        lid1.rotation.y = 0
        
        lid.scale.set(1,1,1)
        lid1.scale.set(1,1,1)

          // CAMERA POSITION START
        const maxDimension = xyzvalue.x + xyzvalue.y + xyzvalue.z;
        // const maxDimension = Math.sqrt(xyzvalue.x ** 2 + xyzvalue.y ** 2 + xyzvalue.z ** 2);

console.log("maxDimension",maxDimension)

        if (maxDimension <= 4) {
          cameraFOV = 2;
          // model.position.x = 0.3
        } else if (maxDimension > 4 && maxDimension <= 9) {
          cameraFOV = 2;
          // model.position.x = 0.3
        } else if (maxDimension > 9 && maxDimension <= 19) {
          cameraFOV = 3.4;
        } 
        // else if (maxDimension >= 14 && maxDimension < 19) {
        //   cameraFOV = 4;
        //   // model.position.x = 0.3
        // }
        else if (maxDimension > 19 && maxDimension <= 24) {
          cameraFOV = 3.9;
          // model.position.x = 0.3
        } else if (maxDimension > 24 && maxDimension <= 29) {
          cameraFOV = 4.5;
          // model.position.x = 0.3
        } else if (maxDimension > 29 && maxDimension <= 34) {
          cameraFOV = 5;
          // model.position.x = 0.3
        }
        else {
          cameraFOV = 6;
        }
  
        if (xyzvalue.y === 6) {
          model.position.y = -1;
          if(xyzvalue.z && xyzvalue.z < 6){
            cameraFOV = 5;
          }
        }
         else if (xyzvalue.y === 5) {
          model.position.y = -0.7;
          if(xyzvalue.z && xyzvalue.z < 6){
            cameraFOV = 5;
          }
        } else if (xyzvalue.y === 4) {
          model.position.y = -0.7;
          if(xyzvalue.z && xyzvalue.z < 6){
            cameraFOV = 5;
          }
        } else if (xyzvalue.y === 3) {
          model.position.y = -0.5;
          if(xyzvalue.z && xyzvalue.z < 6){
            cameraFOV = 3;
          }
        }
        else if (xyzvalue.y === 2) {
          model.position.y = -0.4;
          
        } else {
          model.position.y = -0.2;
          
        }

        camera.fov = cameraFOV;

      // CAMERA POSITION START
      // camera.position.set(8, 5, -15);

      // camera.fov = cameraFOV;
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

        setxyzValues((prevValues) => {
          const modelObject = model.getObjectByName(model.name); // Assuming "Cube" is the root object in your model
  modelObject.scale.set(
    prevValues.x / 10,
    prevValues.y / 5,
    prevValues.z / 10
  );
  


  lid.scale.set(1,0.8,1)
  lid1.scale.set(1,1.2,1)
  lid.scale.set(1,1,1)
  lid1.scale.set(1,1,1)

  return prevValues;
});
// const modelObject = model.getObjectByName(model.name);
// console.log("modelObject",modelObject)


console.log("xyzvalues",xyzvalue)

        const clock = new THREE.Clock();
        function animate() {
          if (mixer) mixer.update(clock.getDelta());
          renderer.render(scene, camera);
          TWEEN.update();
        }

        renderer.setAnimationLoop(animate);
        // Animation part Close
      });
    }

    return () => {
      if (containerRef.current && renderer.domElement && containerRef.current.contains(renderer.domElement)) {
        containerRef.current.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, [xyzvalue, showMailerBox]);


  const handleButtonClick = () => {
    setButtonType('customized');
    // Update the animateLid state variable
    setAnimateLid(true);

    // Delay the start of the TWEEN animation to close the lid
    setTimeout(() => {
      // Create a TWEEN animation to rotate the lid
      const lid = lidRef.current;

      const lid1 = lidRef.current;

      lid.scale.set(1,1,1)
      lid1.scale.set(1,1,1)
      
      new TWEEN.Tween(lid.rotation)
        .to({ z: Math.PI / -2.01 }, 1000)
        .onComplete(() => {
          // Reset the animateLid state variable when the animation is complete
          
          setAnimateLid(false);

          // Create a TWEEN animation to rotate the second lid
          const lid1 = lidRef.current.getObjectByName("Bone_1");
          new TWEEN.Tween(lid1.rotation)
            .to({ z: Math.PI / -1.9 }, 500)
            .start()
            .onComplete(() =>{
              lid.scale.set(0.1,1,1)
            })
        })
        .start();
    }, 0);
  };

  const handleReverseButtonClick = () => {
  setButtonType('reverse');
  // Create a TWEEN animation to rotate the child lid back to its original position
  const lid1 = lidRef.current.getObjectByName("Bone_1");
  const lid = lidRef.current;
  
  lid.scale.set(1, 1, 1);
  const childLidAnimation = new TWEEN.Tween(lid1.rotation)
    .to({ z: 0 }, 1000)
    .onComplete(() => {
      // Once the child lid animation is complete, animate the parent lid
      const parentLidAnimation = new TWEEN.Tween(lid.rotation)
        .to({ z: 1.4 }, 1000)
        .start();
        
        // lid.scale.set(1,0.8,1)
        // lid1.scale.set(1,1.2,1)
        lid.scale.set(1,1,1)
        lid1.scale.set(1,1,1)
        
      parentLidAnimation.start();
    });

  childLidAnimation.start();
};


  const handleSwitchToggle = () => {
    setShowMailerBox(!showMailerBox);
  };
console.log("xyz",xyzvalue)
  return (
    <div className="container" style={{ padding: "0px 15px" }}>
      <div className="row">
        <div className="col-lg-7">
          <div className="product-details">
            <div className="product-content">
              {/* <p className="text-dark"> <span className="rounded-circle text-center  homeicon bg-purple"><Icon icon="heroicons:home-solid" color="white" /></span> <span>/</span> <b>Mailer Box</b></p> */}
              <h1 className="text-dark">Mailer Boxes </h1>
              <p className="text-dark">High quality printing on durable  Corrugated  Cardboard.</p>
            </div>  
            <div className="product-gallery">
              {/* {showMailerBox ?   <div className="app">
              <div className="sidebar">
                {colorsData.map((color, index) => (
                  <div
                    key={index}
                    className={`color-circle ${selectedColor === color.code ? 'active' : ''}`}
                    style={{ backgroundColor: color.code }}
                    onClick={() => handleColorClick(color.code)}
                  ></div>
                ))}
              </div>
              
            </div> : ''} */}
           
              <div className="model-container">
                {!showMailerBox && (
                  <img src="https://theboxlane.com/assets/web-images/home/home-mailer-box-style.webp" alt="Mailer 1" />
                )}
                {/* {showMailerBox ? xyzvalue.z === 0 ? <span class="threed-modal-alternative">Please select the dimensions</span> : <div ref={containerRef}></div>
                :undefined} */}
                {
              showMailerBox ?
                
                (xyzvalue.x === '' || xyzvalue.y === '' || xyzvalue.z === '') ?
                <span class="threed-modal-alternative">Please select the dimensions</span>:
                  <div ref={containerRef}></div>
                  
                : null
                }
                {showMailerBox && (
                  <div className="btn-group border rounded-pill open-close-toggle ">
                    <button onClick={handleReverseButtonClick} className={`btn btngrouphover ${buttonType === 'reverse' ? 'active' : ''} rounded-pill`}>
                      Open Lid
                    </button>
                    <button onClick={handleButtonClick} className={`btn  btngrouphover ${buttonType === 'customized' ? 'active' : ''} rounded-pill`}>
                      Close Lid
                    </button>
                  </div>
                )}
              </div>
              <div className="threed-toggle-btn">
                    <label className="switch">
                      <input type="checkbox" checked={showMailerBox} onChange={handleSwitchToggle} />
                      <span className="slider round "></span>
                    </label>
                    <span className="preview-btn">3d Preview</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="form-container">
            <MailerBoxForm xyzvalue={xyzvalue} setxyzValues={setxyzValues} setShowMailerBox={setShowMailerBox} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mailerbox;
