import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { SelectedOptionProvider } from './Utils/Formdata';
// import Screen2 from './assets/Screen2';

import MailboxMain from './Pages/MailerBox/MailboxMain';
import MagneticboxMain from './Pages/MagneticBox/MagneticboxMain';
import ProductboxMain from './Pages/ProductBox/ProductboxMain';
import UserDashboard from './Pages/userDashboard/UserDashboard';
import AccountSetting from './Pages/AccountSetting/AccountSetting';

import Footer from './Components/Footer/Footer';


import { useContext, useEffect, useState } from 'react';
import ForgotPasswordForm from './Components/signup-login/ForgotPasswordForm/ForgotPasswordForm';
import ResetPasswordForm from './Components/signup-login/ResetPasswordForm/ResetPasswordForm';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './Redux/Slices/UserSlice';
import { AuthContext } from './context/AuthContext';
import Navbar from './Components/Navbar/Navbar';
import Screen2 from './Pages/Payment/Screen2';
import MylarBagMain from './Pages/MylarBag/MylarBagMain';
import MylarBagModel from './Pages/MylarBag/MylarBagModel/MylarBagModel';

function App() {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const storedToken = localStorage.getItem("currentloginUser") || null;
  //   if (storedToken) {
  //     dispatch(setUser(storedToken));
  //   }
  // }, [dispatch]); 

  const ProtectedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);
    console.log("user",user)
    if (!user) {
      return <Navigate to="/product-boxes" />;
    }

    return children;
  };

  return (

      <SelectedOptionProvider>
        <Navbar />
        {/* <Routes>
       
        <Route path='/' element={<Navigate to="/product-boxes" />} />
          <Route path="/product-boxes" element={<ProductboxMain />} />
          <Route path="/mailer-boxes" element={<MailboxMain />} />
          <Route path="/magnetic-boxes" element={<MagneticboxMain />} />
          
          <Route path="/dashboard">
            <Route index element={
                  <ProtectedRoute>
                   <UserDashboard />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/shipping-address">
            <Route index element={
                  <ProtectedRoute>
                   <Screen2 />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/account-setting">
            <Route index element={
                  <ProtectedRoute>
                   <AccountSetting />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/reset-password/:token">
            <Route index element={
                  <ProtectedRoute>
                   <ResetPasswordForm/>
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="*" element={<Navigate to="/product-boxes" />} />
        </Routes> */}
        <Routes>
        <Route path="/" element={<Navigate to="/product-boxes" />} />
        <Route path="/product-boxes" element={<ProductboxMain />} />
        <Route path="/mailer-boxes" element={<MailboxMain />} />
        <Route path="/magnetic-boxes" element={<MagneticboxMain />} />
        <Route path="/mylar-bag" element={<MylarBagMain />} />
        {/* <Route path="/mylarbag-boxes" element={<MylarBagModel />} /> */}
        
        <Route path="/dashboard" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
        <Route path="/shipping-address" element={<ProtectedRoute><Screen2 /></ProtectedRoute>} />
        <Route path="/account-setting" element={<ProtectedRoute><AccountSetting /></ProtectedRoute>} />
        <Route path="/reset-password/:token" element={<ProtectedRoute><ResetPasswordForm /></ProtectedRoute>} />

        
        <Route path="*" element={<Navigate to="/product-boxes" />} />
      </Routes>
        <Footer />
      </SelectedOptionProvider>
  );
}

export default App;
