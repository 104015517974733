import { createSlice } from "@reduxjs/toolkit";

const LoginSlice = createSlice({
    name: 'loginpopup',
    initialState: {
        showpopup: false,
        messageSlice: '',
    },
    reducers: {
        setShowpopup(state, action) {
            state.showpopup = action.payload; //updating progressbar value
        },
        setMessageSlice(state, action) {
            state.messageSlice = action.payload; //updating progressbar value
        }
    },
})

export const { setShowpopup,setMessageSlice } = LoginSlice.actions;
export default LoginSlice.reducer;