import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import TWEEN from "@tweenjs/tween.js";
// import Form from "../../Form";
import "../../../App.css";
// import mailer1 from './Mailer Box.png';
import { Icon } from '@iconify/react';
import ProductBoxForm from "../ProductBoxForm/ProductBoxForm";

const Productbox = () => {
  const [xyzvalue, setxyzValues] = useState({ x: '' , y: '', z: '' });
  const [animateLid, setAnimateLid] = useState(false);
  const [showMailerBox, setShowMailerBox] = useState(false); // Updated initial state
  const [buttonType, setButtonType] = useState('reverse');
 
  // const [selectedColor, setSelectedColor] = useState(0xffa500);
  const [getLid, setLid] = useState({ x: 1 , y: 1, z: 1 });

  // const colorsData = [
  //   // { name: 'Red', code: '#ff0000' },
  //   // { name: 'Pink', code: '#ffc0cb' },
  //   // { name: 'Purple', code: '#800080' },
  //   // { name: 'Yellow', code: '#ffff00' },
  //   { name: 'Orange', code: '#ffa500' },
  //   { name: 'Green', code: '#00ff00' },
  //   { name: 'Grey', code: '#808080' },
  //   { name: 'Brown', code: '#8b4513' },
  // ];

  // const handleColorClick = (color) => {
  //   console.log('COLOR' , color)
  //   setSelectedColor(color);
  // };

  const box = new URL("../../../Models_gltf/ProductBoxNew.gltf", import.meta.url);
  const containerRef = useRef();
  const lidRef = useRef();
  const rightlidRef = useRef();
  const leftlidRef = useRef();

  // useEffect(() => {
  //   const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  
  //   renderer.setPixelRatio(window.devicePixelRatio * 1);

  //   renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
  //   renderer.domElement.style.position = "relative";
  //   renderer.domElement.style.right = "0%";
  //   renderer.domElement.style.top = "0%";

  //   if (containerRef.current) {
  //     containerRef.current.appendChild(renderer.domElement);
  //   }

  //   const scene = new THREE.Scene();
  //   // scene.background = new THREE.Color("#ffffff"); // Set the background color to light blue
  //   let cameraFOV = 15; // Default camera FOV
  //   const camera = new THREE.PerspectiveCamera(cameraFOV, window.innerWidth / window.innerHeight, 0.5, 1000);
    
  //   const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
  //   scene.add(ambientLight);

  //   const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  //   directionalLight.position.set(0.7, 0.7, 1);
  //   scene.add(directionalLight);
    
  //   const hemisphereLight = new THREE.HemisphereLight(selectedColor, selectedColor , 0.3);
  //   scene.add(hemisphereLight);
  //   renderer.setClearColor(0xffffff);

  //   const orbit = new OrbitControls(camera, renderer.domElement);
  //   camera.position.set(8, 5, 15);
  //   orbit.update();

  //   let mixer;

  //   if (showMailerBox) {
  //     const mailerbox = new GLTFLoader();
  //     mailerbox.load(box.href, function (gltf) {

  //       const model = gltf.scene;
        
  //       scene.add(model);

  //       // Animation Part Start
  //       mixer = new THREE.AnimationMixer(model);

  //       const clips = gltf.animations;

  //       clips.forEach(function (clip) {
  //         const action = mixer.clipAction(clip);
  //         action.play();
  //       });
  //       // Find the lid object and store it in a ref
  //       const lid = model.getObjectByName("Bone");//main
  //       const lid1 = model.getObjectByName("Bone_1"); //top main
  //       const lid2 = model.getObjectByName("Bone_2"); //right
  //       const lid3 = model.getObjectByName("Bone_3"); //left
  //       const lid3Cube = model.getObjectByName("Cube004");
        
  //       // fixedLid(lid);
  //       // lid.scale.set(1,1,1)

  //       lidRef.current = lid;
  //       rightlidRef.current = lid2;
  //       leftlidRef.current = lid3;

  //       // Set the initial rotation of the lid
  //       // lid.rotation.x = Math.PI / 2.2;
  //       // lid.rotation.x = 0;
  //       // lid.rotation.y = 0;
  //       // lid.rotation.z = -2;
        
  //       // lid1.rotation.x = Math.PI / 1.9;
  //       // lid1.rotation.x = 0.12202662550108893;
  //       // lid1.rotation.y = -0.08426581162750807;
  //       // lid1.rotation.z = -0.01022671563924526;

  //       // lid2.rotation.x = -0.2;
  //       // lid2.rotation.y = 0.15;
  //       // lid2.rotation.z = Math.PI / 0.9;

  //       // lid2.rotation.x = -0.29321199353174937;
  //       // lid2.rotation.y = -0.19024356366750597;
  //       // lid2.rotation.z = -0.8638484472286047;

  //       // lid3.rotation.x = 0.2;
  //       // lid3.rotation.y = -0.3572435519739038;
  //       // lid3.rotation.z = 0.5;

  //       // lid3.rotation.x = 0.2932120159181036;
  //       // lid3.rotation.y = 0.1902435519739038;
  //       // lid3.rotation.z = 2.2638485210497563; 
  //       // lid3.position.set(0,0,0)
  //       // lid3.position.set(-0.009315459057688713,0.008905612863600254,1.862645149230957e-9)
        

  //         // CAMERA POSITION START
        
  //       const maxDimension = xyzvalue.x + xyzvalue.y + xyzvalue.z;

  //       // if (maxDimension < 18) {
  //       //   cameraFOV = 15; // Adjust the FOV for small boxes
  //       //   camera.position.set(8, 5, 15);
  //       // }
  //       // if (maxDimension >= 18 && maxDimension < 28) {
  //       //   cameraFOV = 16; // Adjust the FOV for small boxes
  //       //   camera.position.set(8, 5, 15);
  //       // }
  //       // if (maxDimension >= 28 && maxDimension < 38) {
  //       //   cameraFOV = 17; // Adjust the FOV for small boxes
  //       //   camera.position.set(8, 5, 15);
  //       // }else {
  //       //   cameraFOV = 5; // Adjust the FOV for larger boxes
  //       //   // camera.position.set(8, 5, 25);
  //       // }

  //       if (maxDimension < 18) {
  //         cameraFOV = 8;
  //       } else if (maxDimension >= 18 && maxDimension < 28) {
  //         cameraFOV = 12;
  //       } else if (maxDimension >= 28 && maxDimension < 38) {
  //         cameraFOV = 18;
  //       }
  //       else if (maxDimension >= 38 && maxDimension < 48) {
  //         cameraFOV = 20;
  //       }
  //       else if (maxDimension >= 48 && maxDimension < 60) {
  //         cameraFOV = 22;
  //       } else {
  //         cameraFOV = 24;
  //       }

  //       // cameraFOV = 8;

  //       camera.position.set(8, 5, 15);

  //       camera.fov = cameraFOV;
  //       camera.aspect = window.innerWidth / window.innerHeight;
  //       camera.updateProjectionMatrix();
  //       // CAMERA POSITION END

  //       // Set the initial scale of the model
  //       setxyzValues((prevValues) => {
  //         const modelObject = model.getObjectByName(model.name);
  //         modelObject.scale.x = prevValues.x * 0.09;
  //         modelObject.scale.y = prevValues.y * 0.09;
  //         modelObject.scale.z = prevValues.z * 0.09;

  //         return prevValues;
  //       });

        
  //       const clock = new THREE.Clock();
  //       function animate() {
  //         if (mixer) mixer.update(clock.getDelta());
  //         renderer.render(scene, camera);
  //         TWEEN.update();
  //       }

  //       renderer.setAnimationLoop(animate);
  //       // Animation part Close
  //     });
  //   }

  //   return () => {
  //     if (containerRef.current && renderer.domElement && containerRef.current.contains(renderer.domElement)) {
  //       containerRef.current.removeChild(renderer.domElement);
  //     }
  //     renderer.dispose();
  //   };
  // }, [xyzvalue, showMailerBox , selectedColor]);

  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  
    renderer.setPixelRatio(window.devicePixelRatio * 1);

    renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
    renderer.domElement.style.position = "relative";
    renderer.domElement.style.right = "0%";
    renderer.domElement.style.top = "0%";

    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }

    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#fbfaf5"); // Set the background color to light blue
    let cameraFOV = 15; // Default camera FOV
    const camera = new THREE.PerspectiveCamera(cameraFOV, window.innerWidth / window.innerHeight, 0.5, 1000);
    
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0.7, 0.7, 1);
    scene.add(directionalLight);
    
    const hemisphereLight = new THREE.HemisphereLight("", "" , 1);
    scene.add(hemisphereLight);
    renderer.setClearColor(0xffffff);

    const orbit = new OrbitControls(camera, renderer.domElement);

    camera.position.set(8, 8, 15);

    // camera.position.set(8, 5.5, 15);
    orbit.update();

    let mixer;

    if (showMailerBox) {
      const mailerbox = new GLTFLoader();
      mailerbox.load(box.href, function (gltf) {

        const model = gltf.scene;
        // model.position.set(0, -0.4, 0);
        model.position.set(0, 0, 0);
        // model.position.set(0, -0.6, 0);
        scene.add(model);
  
        // Animation Part Start
        mixer = new THREE.AnimationMixer(model);
        const clips = gltf.animations;
  
        clips.forEach(function (clip) {
          const action = mixer.clipAction(clip);
          action.play();
        });
  
        // Find the lid object and store it in a ref
        const lid = model.getObjectByName("Bone");
        const lid1 = model.getObjectByName("Bone_1");
        const lid2 = model.getObjectByName("Bone_2");
        const lid3 = model.getObjectByName("Bone_3");
        const lid3Cube = model.getObjectByName("Cube004");
  
        lidRef.current = lid;
        rightlidRef.current = lid2;
        leftlidRef.current = lid3;
 
        lidRef.current = lid;

       // Set the initial rotation and scale of the lid
        lid.rotation.x = animateLid ? Math.PI / -1 : 0;
        lid.rotation.z = 0.8;
        lid.rotation.y = 0;
        lid.scale.set(1, 1, 1);

        // const maxDimension = xyzvalue.x + xyzvalue.y + xyzvalue.z;
        const maxDimension = Math.sqrt(xyzvalue.x ** 2 + xyzvalue.y ** 2 + xyzvalue.z ** 2);

        console.log("maxDimension",maxDimension);

        if (maxDimension <= 2) {
          cameraFOV = 3;
        } else if (maxDimension > 2 && maxDimension < 4) {
          cameraFOV = 6;
        } else if (maxDimension > 4 && maxDimension < 7) {
          cameraFOV = 8;
        } else if (maxDimension >= 7 && maxDimension < 9) {
          cameraFOV = 10;
        } else if (maxDimension >= 9 && maxDimension < 11) {
          cameraFOV = 12;
        } else if (maxDimension >= 11 && maxDimension < 13) {
          cameraFOV = 14;
        } else if (maxDimension >= 13 && maxDimension < 17) {
          cameraFOV = 16;
        } else if (maxDimension >= 17 && maxDimension < 19) {
          cameraFOV = 22;
        } else if (maxDimension >= 19 && maxDimension < 21) {
          cameraFOV = 25;
        } else if (maxDimension >= 21 && maxDimension < 23) {
          cameraFOV = 27;
        } 
        else if (maxDimension >= 23 && maxDimension < 25) {
          cameraFOV = 28;
        } else if (maxDimension >= 25 && maxDimension < 27) {
          cameraFOV = 30;
        } else if (maxDimension >= 27 && maxDimension < 29) {
          cameraFOV = 35;
        } else {
          cameraFOV = 15;
        }
  
        
        // camera.position.set(8, 5.5, 15);
        camera.fov = cameraFOV;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
  
        setxyzValues((prevValues) => {
          const modelObject = model.getObjectByName(model.name);
          // modelObject.scale.x = prevValues.x * 0.09;
          // modelObject.scale.y = prevValues.y * 0.09;
          // modelObject.scale.z = prevValues.z * 0.09;
          modelObject.scale.x = prevValues.x *.155
          modelObject.scale.y = prevValues.y *0.0775
          modelObject.scale.z = prevValues.z *.155
          // console.log("modelObject",modelObject)
        
          // Set the length and width of the lid without changing the depth
          // const lidWidth = prevValues.y * 0.09; // Adjust the scaling factor as needed
          // const lidLength = prevValues.x * 0.09; // Use the actual x value // Adjust the scaling factor as needed
        
          // // Set the scale of the lid
          // lid.scale.set(lidLength, lidWidth, lid.scale.z); // Keep the original depth
          return prevValues;
        });
        
        const clock = new THREE.Clock();
        function animate() {
          if (mixer) mixer.update(clock.getDelta());
          renderer.render(scene, camera);
          TWEEN.update();
        }
  
        renderer.setAnimationLoop(animate);
        // Animation part Close
      });
    }
  
    return () => {
      if (containerRef.current && renderer.domElement && containerRef.current.contains(renderer.domElement)) {
        containerRef.current.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, [xyzvalue.x, xyzvalue.y, xyzvalue.z, showMailerBox]);

  // console.log("xyz",xyzvalue)

  const handleButtonClick = () => {
    setButtonType('customized');
    // Update the animateLid state variable
    setAnimateLid(true);

    // Delay the start of the TWEEN animation to close the lid
    setTimeout(() => {
      // Create a TWEEN animation to rotate the lid
      const lid = lidRef.current;

      // Set the initial rotation and scale of the lid
    lid.rotation.x = animateLid ? Math.PI / -1 : 0;
    lid.rotation.z = 0.8;
    lid.rotation.y = 0;
    lid.scale.set(1, 1, 1);
    
    const rightlid = rightlidRef.current.getObjectByName("Bone_2");
    new TWEEN.Tween(rightlid.rotation)
        .to({ x:0.26, y: 0.22, z: -3.9 }, 0)
        .start()

    const leftlid = leftlidRef.current.getObjectByName("Bone_3");
    new TWEEN.Tween(leftlid.rotation)
        .to({ z: 2.2638485210497563, y: 0.1902435519739038, x:0.2932120159181036 }, 0)
        .start()
        leftlid.position.set(0,0,0)
        
    new TWEEN.Tween(lid.rotation)
      .to({ z: -1.955 }, 1000)
      .start()
      .onComplete(() => {
      // Reset the animateLid state variable when the animation is complete
      setAnimateLid(false);
    })
    }, 0);
  };
  
const handleReverseButtonClick = () => {
    setButtonType('reverse');
  
    const lid = lidRef.current;
    

    // Animate the main lid to open
    new TWEEN.Tween(lid.rotation)
      .to({ z: 0.8 }, 1000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .start()
      .onComplete(() => {
        lid.scale.set(1, 1, 1);


    // Set the initial rotation of the lid
    lid.rotation.x = animateLid ? Math.PI / -1 : 0;
    lid.rotation.z = 0.8
    lid.rotation.y = 0
        // Reset the animateLid state variable when the animation is complete
        setAnimateLid(false);
      });
  
    // Animate the right lid to open
    const rightlid = rightlidRef.current.getObjectByName("Bone_2");
    new TWEEN.Tween(rightlid.rotation)
    .to({ x:-0.29321199353174937, y: -0.19024356366750597, z: -0.8638484472286047 }, 0)
      .easing(TWEEN.Easing.Quadratic.Out)
      .start();
  
    // Animate the left lid to open
    const leftlid = leftlidRef.current.getObjectByName("Bone_3");
    new TWEEN.Tween(leftlid.rotation)
    .to({ x:-0.2932120159181036, y: -0.1902435519739038, z: -0.8638485210497563 }, 0)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onComplete(() => {
        // Reset the left lid position when the animation is complete
        leftlid.position.set(-0.009315459057688713,0.008905612863600254,1.862645149230957e-9)
      })
      .start();
  };

  const handleSwitchToggle = () => {
    setShowMailerBox(!showMailerBox);
  };

  return (
    <div className="container" style={{ padding: "0px 15px" }}>
      <div className="row">
        <div className="col-lg-7">
          <div className="product-details">
            <div className="product-content">
              {/* <p className="text-dark"> <span className="rounded-circle text-center  homeicon bg-purple"><Icon icon="heroicons:home-solid" color="white" /></span> <span>/</span> <b>Mailer Box</b></p> */}
              <h1 className="text-dark">Product Boxes </h1>
              <p className="text-dark">High quality printing on durable Corrugated  Cardboard.</p>
            </div>
            <div className="product-gallery">
              {/* {showMailerBox ?   <div className="app">
              <div className="sidebar">
                {colorsData.map((color, index) => (
                  <div
                    key={index}
                    className={`color-circle ${selectedColor === color.code ? 'active' : ''}`}
                    style={{ backgroundColor: color.code }}
                    onClick={() => handleColorClick(color.code)}
                  ></div>
                ))}
              </div> */}
              {/* <div className="main-content">
                {selectedColor && <p>Selected Color: {selectedColor.name}</p>}
              </div> */}
            {/* </div> : ''} */}
           
              <div className="model-container">
                {!showMailerBox && (
                  <img src="https://theboxlane.com/assets/web-images/home/product_.webp" alt="Mailer 1" />
                )}
                {/* {showMailerBox ? xyzvalue.z === 0 || xyzvalue.z === '' ? <span class="threed-modal-alternative">Please select the dimensions</span> : <div ref={containerRef}></div>
                :undefined} */}
                {
                showMailerBox ?
                (xyzvalue.x === '' || xyzvalue.y === '' || xyzvalue.z === '') ?
                <span class="threed-modal-alternative">Please select the dimensions</span>:
                  <div ref={containerRef}></div> 
                : null
                }
                {showMailerBox && (
                  <div className="btn-group rounded-pill open-close-toggle ">
                    <button onClick={handleReverseButtonClick} className={`btn btngrouphover ${buttonType === 'reverse' ? 'active' : ''} rounded-pill`}>
                      Open Lid
                    </button>
                    <button onClick={handleButtonClick} className={`btn  btngrouphover ${buttonType === 'customized' ? 'active' : ''} rounded-pill`}>
                      Close Lid
                    </button>
                  </div>
                )}
              </div>
              <div className="threed-toggle-btn">
                    <label className="switch">
                      <input type="checkbox" checked={showMailerBox} onChange={handleSwitchToggle} />
                      <span className="slider round "></span>
                    </label>
                    <span className="preview-btn">3d Preview</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="form-container">
            <ProductBoxForm xyzvalue={xyzvalue} setxyzValues={setxyzValues} setShowMailerBox={setShowMailerBox} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productbox;
