import React from 'react'

const MaterialTab = () => {
  return (
    <div><section className="cta-wrapper-footer">
    <div className="container">
        <div className="row ">
            <div className="col-lg-12">
                <div className="cta-banner-content">
                    <p className="sub-heading">Need Help?</p>
                    <h3>Get In Touch for a Consultation</h3>
                    <p>Need help finding the perfect custom boxes? Super easy.<br/> Contact us now for a free consultation with a trained packaging specialist.</p>
                    <a className="btn btn-yellow w-icon-right" href="https://theboxlane.com/contact-us/">Contact Us<i className="fa fa-solid fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="material-wrapper">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="tab-navigation">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="best-materials-tab" data-bs-toggle="tab" href="#best-materials" role="tab" aria-controls="best-materials" aria-selected="true">Best Materials</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="finishing-options-tab" data-bs-toggle="tab" href="#finishing-options" role="tab" aria-controls="finishing-options" aria-selected="false">Finishing Options</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="add-on-tab" data-bs-toggle="tab" href="#add-on" role="tab" aria-controls="add-on" aria-selected="false">Add On Choices</a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade active show" id="best-materials" role="tabpanel" aria-labelledby="best-materials-tab">
                        <div className="pt-5 web-product-material animated fadeInUp row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Premium-Cardstock.webp" alt="Premium Cardstock"/>
                                    <div className="home-material-content">
                                        <span>Premium Cardstock</span>
                                        <p>We offer premium white cardboard stock in 16pt-24pt thickness, there is no limit on printing colors.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Brown-Kraft-Stock.webp" alt="Brown Kraft Stock"/>
                                    <div className="home-material-content">
                                        <span>Brown Kraft Stock</span>
                                        <p>Kraft stock is a brown, eco-friendly, recyclable material that is available in 16pt-24pt thickness range.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Corrugated-Stock.webp" alt="Corrugated Stock"/>
                                    <div className="home-material-content">
                                        <span>Corrugated Stock</span>
                                        <p>Hard, shock-absorbing material, also called e-flute, corrugated stock is the go-to material for shipping boxes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Rigid-Stock.webp" alt="Rigid Stock"/>
                                    <div className="home-material-content">
                                        <span>Rigid Stock</span>
                                        <p>Thickest available material for cardboard packaging, Rigid stock is available in 1000 gsm-1900 gsm weight.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="finishing-options" role="tabpanel" aria-labelledby="finishing-options-tab">
                        <div className="web-finishing-options pt-5 animated fadeInUp row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Glossy.webp" alt="Glossy"/>
                                    <div className="home-material-content">
                                        <span>Glossy</span>
                                        <p>A glossy finish adds shine and resistance to the surface and increases its life.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Matte.webp" alt="Matte"/>
                                    <div className="home-material-content">
                                        <span>Matte</span>
                                        <p>Matte finish tones down the colors and makes it look professional, it does it with a thin layer of ink coating.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Soft-Touch.webp" alt="Soft Touch"/>
                                    <div className="home-material-content">
                                        <span>Soft Touch</span>
                                        <p>A soft touch raises your logo or image a little and makes it look more prominent.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/solid-uv-coating.webp" alt="Solid UV Coating"/>
                                    <div className="home-material-content">
                                        <span>Solid UV Coating</span>
                                        <p>Solid UV coating is a solid, clear paste that gives shiny finish and pairs well with all materials.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="add-on" role="tabpanel" aria-labelledby="add-on-tab">
                        <div className="pt-5 web-add-on animated fadeInUp row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Embossing.webp" alt="Embossing"/>
                                    <div className="home-material-content">
                                        <span>Embossing</span>
                                        <p>Embossing is the technique of lifting an image or text a little off the surface to highlight it.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Debossing.webp" alt="Debossing"/>
                                    <div className="home-material-content">
                                        <span>Debossing</span>
                                        <p>Debossing is the printing technique that suppresses the text or image.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Spot-uv-coating.webp" alt="Spot UV Coating"/>
                                    <div className="home-material-content">
                                        <span>Spot UV Coating</span>
                                        <p>Spot UV coating gives more gloss and shine to an area that you want to highlight.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="home-material-card">
                                    <img src="https://theboxlane.com/assets/images/homepage/Raised-Ink.webp" alt="Raised Ink"/>
                                    <div className="home-material-content">
                                        <span>Raised Ink</span>
                                        <p>Raised ink gives text a raised and shiny look that brings an impression of luxury.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section></div>
  )
}

export default MaterialTab