import React from 'react'
import { useNavigate } from 'react-router-dom'

const DetailComponent = (props) => {
    console.log("props",props)
    const navigate =useNavigate();

    const formatPhoneNumber = (phoneNumber) => {
      // Check if the input value is a valid number
      if (phoneNumber) {
        const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
      const match = formattedPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      }
    return phoneNumber;
  };
  
  return (
    <div class="row">
       
            {/* {props.userDetails.map((user) => ( */}
                <div class="col-12 col-md-7 col-lg-6 col-xl-12">   
                  <div className="useer-table">
                  <h3 class="form-heading">Basic Information</h3>
                      <table class="table table-striped">
                      <tbody>
                        {props.userDetails.firstName &&<tr>
                          <th>First Name</th>
                          <td>{props.userDetails.firstName}</td>
                          </tr>}
                          {props.userDetails.lastName &&<tr>
                          <th>Last Name</th>
                          <td>{props.userDetails.lastName}</td>
                          </tr>}
                          {props.userDetails.email &&<tr>
                          <th>Email Address</th>
                          <td>{props.userDetails.email}</td>
                          </tr>}
                          {props.userDetails.attention &&<tr>
                          <th>Attention</th>
                          <td>{props.userDetails.attention}</td>
                          </tr>}
                          {props.userDetails.streetAddress &&<tr>
                          <th>Street Address</th>
                          <td>{props.userDetails.streetAddress}</td>
                          </tr>}
                          {props.userDetails.apartment && <tr>
                          <th>Appartment, suite etc</th>
                          <td>{props.userDetails.apartment}</td>
                          </tr>}
                          {props.userDetails.zipcode &&<tr>
                          <th>Zip Code</th>
                          <td>{props.userDetails.zipcode}</td>
                          </tr>}
                          {props.userDetails.state &&<tr>
                          <th>State</th>
                          <td>{props.userDetails.state}</td>
                          </tr>}
                          {props.userDetails.city &&<tr>
                          <th>City</th>
                          <td>{props.userDetails.city}</td>
                          </tr>}
                          {props.userDetails.phone &&<tr>
                          <th>Phone</th>
                          <td>{formatPhoneNumber(props.userDetails.phone)}</td>
                          </tr>}
                      </tbody>
                      </table>
                      <span onClick={()=> navigate('/account-setting')} className='btn bg-purple text-white'>Edit Information</span>
                  </div>
                </div>
          {/* ))} */}
            </div>
  )
}

export default DetailComponent