import React, { useState, useEffect , useContext} from "react";
import "./Newform.css";
import { SelectedOptionContext } from "../../Utils/Formdata";
import Modal from "../../Modal";
import Modal2 from '../../Components/Modal2';
// import Navbar from "../Components/Navbar";
import Footer from "../../Components/Footer/Footer";
// import shopping from "../Shopping.png";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { TailSpin } from "react-loader-spinner";
import './screen2.css';
import useAlert from "../../hooks/useAlert/useAlert";
import AlertComponent from "../../Components/AlertComponent/AlertComponent";
import Cookies from "js-cookie";
import { AuthContext } from "../../context/AuthContext";

const Screen2 = () => {
  const navigate = useNavigate();
  const [secondnameerror, setSecondNameError] = useState(false);
  const [cityerror, setCityError] = useState(false);
  const [phoneerror, setPhoneError] = useState(false);
  const [ziperror, setZipError] = useState(false);
  const [adresserror, setAdressError] = useState(false);
  const [error, setError] = useState(false);
  const { selectedOption } = useContext(SelectedOptionContext);

  const { unitprice } = useContext(SelectedOptionContext);
  const { estimatedCost } = useContext(SelectedOptionContext);
  const [proceed, setProceed] = useState(false);

  const { firstName, setFirstName } = useContext(SelectedOptionContext);
  const { secondName, setSecondName } = useContext(SelectedOptionContext);
  const { address, setAdress } = useContext(SelectedOptionContext);
  const { zipcode, setZipcode } = useContext(SelectedOptionContext);
  const { city, setCity } = useContext(SelectedOptionContext);
  const { phone, setPhone } = useContext(SelectedOptionContext);
  const { state, setState } = useContext(SelectedOptionContext);
  const { email, setEmail } = useContext(SelectedOptionContext);
  const [currentStep, setCurrentStep] = useState(1); // Added state for current step

  const [currency, setCurrency] = useState("$");
  const { paymentmoadal , setPaymentmoadal } = useContext(SelectedOptionContext);




  const [chk, setchk] = useState(false);

  const Length = /^(\d+)/; // Regex pattern to match the first number
  const depth = /x (\d+)/; // Regex pattern to match the second number
  const width = /x (\d+)$/; // Regex pattern to match the third number
  const matches = selectedOption.match(Length);
  const matches1 = selectedOption.match(depth);
  const matches2 = selectedOption.match(width);
  const firstNumber = matches ? matches[0] : "";
  const secondtNumber = matches1 ? matches1[1] : "";
  const thirdnumber = matches2 ? matches2[1] : "";
  const { quantity } = useContext(SelectedOptionContext);
  const { material } = useContext(SelectedOptionContext);
  const { slides } = useContext(SelectedOptionContext);
  const { time } = useContext(SelectedOptionContext);
  const { Coating } = useContext(SelectedOptionContext);
  const { boxname } = useContext(SelectedOptionContext);
  const { paper } = useContext(SelectedOptionContext);
  

  useEffect(()=> {
    if (
      !firstNumber ||
      !secondtNumber ||
      !thirdnumber ||
      !estimatedCost ||
      // !material ||
      !quantity ||
      !slides ||
      !time ||
      !boxname ||
      !unitprice
    ) {
      const data={
        firstNumber: firstNumber,
        secondtNumber: secondtNumber,
        thirdnumber: thirdnumber,
        estimatedCost: estimatedCost,
        material: material,
        quantity: quantity,
        slides: slides,
        time: time,
        boxname: boxname,
        unitprice: unitprice
      }
     console.log("navigate", data)
      navigate('/product-boxes');
    }
  },[navigate])

  const { user } = useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event to prevent the default browser prompt
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = '';

      // Show a custom message to the user
      return 'Your changes may not be saved. Are you sure you want to leave?';
    };

    const cleanupBeforeUnload = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

    // Add event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove event listener when the component unmounts
    return cleanupBeforeUnload;
  }, [location]);
  

  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    address: "",
    apartment: "",
    zipcode: "",
    attention: "",
    city: "",
    phone: "",
    state:"",
    email:""
  });

 


  useEffect(() => {
    if(isFormFilled()){
      setchk(true)
      setFirstName(formData.firstName)
      setSecondName(formData.secondName)
      setAdress(formData.address)
      setZipcode(formData.zipcode)
      setCity(formData.city)
      setPhone(formData.phone)
      setState(formData.state)
      setEmail(formData.email)
  
      console.log("State",formData.state)
      console.log("Email",formData.email)
      // console.log("phone", formData.phone)
    }else{
      setchk(false)
    }

  }, [formData]);


  const [saveddesign, setSaveddesign] = useState(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the input field is for phone number
    if (name === "phone") {
        // Remove all non-numeric characters from the input value
        const numericValue = value.replace(/\D/g, "");

        // Format the phone number with dashes
        const formattedPhoneNumber = numericValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

        // Update the form data state with the formatted phone number
        setFormData({ ...formData, [name]: formattedPhoneNumber });
       
    } else {
        // For other input fields, update the form data state directly
        setFormData({ ...formData, [name]: value });
        
    }
    setSaveddesign(true)
};

  const isFormFilled = () => {
    return (
      formData.firstName.trim() !== "" &&
      formData.secondName.trim() !== "" &&
      formData.address.trim() !== "" &&
      formData.zipcode.trim() !== "" &&
      formData.city.trim() !== "" &&
      formData.phone.trim() !== "" &&
      formData.state.trim() !== "" &&
      formData.email.trim() !== "" 
    );
  };

  const backtoMailerbox = () => {
    navigate("/");
        window.location.reload();
  }

  const [userDetails, setUserDetails] = useState(null);
  const [usererror, setuserError] = useState(null);

  useEffect(() => {
    // Function to fetch current user details
    const fetchCurrentUserDetails = async () => {
      try {
        // Make a request to the /current-user endpoint
        const response = await fetch('https://boxlane-61739ddb4424.herokuapp.com/api/v1/current-user', {
          method: 'GET',
 
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers if needed
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        console.log("response",response)
        // Parse the response JSON
        const data = await response.json();
        setFormData({
          ...formData,

            firstName: data.user.firstName || "",
            secondName: data.user.lastName || "",
            email: data.user.email || "",
            address: data.user.streetAddress || "",
            apartment: data.user.apartment || "",
            zipcode: data.user.zipcode || "",
            attention: data.user.attention || "",
            city: data.user.city || "",
            phone: data.user.phone || "",
            state: data.user.state || ""
          
        });
        console.log("data",data)
        setUserDetails(data.user);
      } catch (error) {
        setuserError(error.message);
      }
    };

    // Call the function to fetch user details
    fetchCurrentUserDetails();
  }, []);

 
  let orderInfo;

  if(boxname === 'Product'){
     orderInfo = {
      payment: 'not-paid',
      boxtype: boxname,
      quantity: quantity,
      length: firstNumber,
      width: secondtNumber,
      depth: thirdnumber,
      Coating: material,
      paper: paper,
      printerSides: slides,
      turnaroundTime: time,
      pricePerItem: unitprice,
      totalPrice: estimatedCost,
    };
  }
  if(boxname === 'Mailer' || boxname === 'Magnetic'){
     orderInfo = {
      payment: 'not-paid',
      boxtype: boxname,
      quantity: quantity,
      length: firstNumber,
      width: secondtNumber,
      depth: thirdnumber,
      material: material,
      printerSides: slides,
      turnaroundTime: time,
      pricePerItem: unitprice,
      totalPrice: estimatedCost,
    };
    
  }
  if(boxname === 'mylarbag'){
     orderInfo = {
      payment: 'not-paid',
      boxtype: boxname,
      quantity: quantity,
      length: firstNumber,
      // width: secondtNumber,
      depth: thirdnumber,
      material: material,
      printerSides: slides,
      turnaroundTime: time,
      pricePerItem: unitprice,
      totalPrice: estimatedCost,
    };
  }

  //without payment save draft
      const saveorderinfodraft = (e) => {
        e.preventDefault()
        hideAlert();
        
        if(saveddesign){
        setSaveddesign(false)
        updateuserinfo();
        addordertodb();
        }
        if(!saveddesign){
          showAlert("info", "Design already saved");
        }
      }

//go to payment
      const handlepaymentorder = (e) => {
        e.preventDefault();
        updateuserinfo();
        setPaymentmoadal(true);
      }

      const updateuserinfo = () => {
        // const currentloginUser = localStorage.getItem("currentloginUser")
        // const cookieUser = Cookies.get("currentloginUserboxlane");

        const apiUrl = `https://boxlane-61739ddb4424.herokuapp.com/api/v1/update/${user._id}`; 
        const updatedUserInfo = {
          firstName: formData.firstName,
          lastName: formData.secondName,
          attention: formData.attention,
          streetAddress: formData.address,
          apartment: formData.apartment,
          zipcode: formData.zipcode,
          state: formData.state,
          city: formData.city,
          phone: formData.phone
        };
      
        fetch(apiUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers if needed
          },
          credentials: "include",
          body: JSON.stringify(updatedUserInfo),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`Request failed with status: ${response.status}`);
            }
            return response.json();
          })
          .then(updatedUser => {
            console.log('User updated successfully:', updatedUser);
            // addordertodb();
          })
          .catch(error => {
            console.error('Error updating user:', error);
            // Handle the error as needed
          });
      }


const addordertodb= () => {
  const apiUrl = `https://boxlane-61739ddb4424.herokuapp.com/api/v1/addorder`;
        
        fetch(apiUrl, {
          method: 'POST',
 
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers if needed
          },
          credentials: "include",
          body: JSON.stringify(orderInfo),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`Request failed with status: ${response.status}`);
            }
            return response.json();
          })
          .then(orderInfo => {
            console.log('Order added successfully:', orderInfo);
            // setSaveddesign(false)
            showAlert("success", "Order Saved successfully");
          })
          .catch(error => {
            console.error('Error adding order:', error);
            // Handle the error as needed
          });
}

const formatPhoneNumber = (phoneNumber) => {
  // Check if the input value is a valid number
  if (phoneNumber) {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
  const match = formattedPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  }
return phoneNumber;
};
const { alertmsg, showAlert, hideAlert } = useAlert();

console.log("material",material)

  return (
<>
{
    firstNumber &&
      // secondtNumber &&
      thirdnumber &&
      estimatedCost &&
      // material &&
      quantity &&
      slides &&
      time &&
      boxname &&
      unitprice &&
    <>
    <AlertComponent
          type={alertmsg.type}
          message={alertmsg.message}
          visible={alertmsg.visible}
          onClose={hideAlert}
        />

      {!proceed ? (
        <>
           <Modal address={formData.address}/>
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-lg-7">
                {paymentmoadal ? <Modal2 orderInfo={orderInfo}/> :   
              <div className="shipping-adrees-form form-wrapper">
                <div className="form-fields">
                  <div
                    className="form-header">
                    <span className="ms-3 fs-5 p-2" style={{fontWeight:'700'}}>Shipping Address</span>
                  </div>
                  <div className="fields screen3form mt-2 px-4">
                    <form action="modal">
                      <div className="row mb-3">
                        <div className="col-lg-6 col-md-12 mb-3 pe-2">
                          <label for="First Name *">
                            First Name *
                          </label>
                          <input
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            className="form-control small-input "
                            type="text"
                            placeholder="First Name *"
                          />
                          <div>{error}</div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3">
                          <label for="Last Name *">
                            Last Name *
                          </label>
                          <input
                            id="secondName"
                            name="secondName"
                            value={formData.secondName}
                            onChange={handleInputChange}
                            className="form-control small-input"
                            type="text"
                            placeholder="Last Name *"
                          />
                          <div>{secondnameerror}</div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3 pe-2">
                          <label for="Email Address *">
                            Email Address *
                          </label>
                          <input
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="form-control small-input"
                            type="email"
                            placeholder="Email Address *"
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3">
                          <label for="Attention (optional)">
                            Attention (optional)
                          </label>
                          <input
                            className="form-control small-input"
                            type="text"
                            name="attention"
                            id="attention"
                            value={formData.attention}
                            onChange={handleInputChange}
                            placeholder="Attention (optional)"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3 pe-2">
                          <label for="Street Address *">
                            Street Address *
                          </label>
                          <input
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            className="form-control small-input"
                            type="text"
                            placeholder="Street Address *"
                          />
                          <div>{adresserror}</div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3">
                          <label for="Apartment, suite, etc. (optional)">
                            Apartment, suite, etc. (optional)
                          </label>
                          <input
                            className="form-control small-input"
                            type="text"
                            name="apartment"
                            id="apartment"
                            value={formData.apartment}
                            onChange={handleInputChange}
                            placeholder="Apartment, suite, etc. (optional)"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3">
                          <label for="Zip Code *">Zip Code *</label>
                          <input
                            id="zipcode"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleInputChange}
                            className="form-control small-input"
                            type="number"
                            placeholder="Zip Code *"
                            maxLength={5}
                          />
                          <div>{ziperror}</div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3">
                          <div>
                            <div className="ms-2">
                            <label for="State *">State *</label>
                              <select
                                 id="state"
                                 name="state"
                                 value={formData.state}
                                 onChange={handleInputChange}
                                className="form-select small-input"
                                aria-label="Default select example"
                                style={{width: "100%" }}
                                placeholder="State *"
                              >
                                <option selected>Select State</option>
                                <option selected>AL-Alabama</option>
                                <option selected>AK-Alaska</option>
                                <option selected>AZ-Arizona</option>
                                <option selected>AR-Arkansas</option>
                                <option selected>CA-California</option>
                                <option selected>CO-Colorado</option>
                                <option selected>CT-Connecticut</option>
                                <option selected>DE-Delaware</option>
                                <option selected>FL-Florida</option>
                                <option selected>GA-Georgia</option>
                                <option selected>HI-Hawaii</option>
                                <option selected>ID-Idaho</option>
                                <option selected>IL-Illinois</option>
                                <option selected>IN-Indiana</option>
                                <option selected>IA-Iowa</option>
                                <option selected>KS-Kansas</option>
                                <option selected>KY-Kentucky</option>
                                <option selected>LA-Louisiana</option>
                                <option selected>ME-Maine</option>
                                <option selected>MD-Maryland</option>
                                <option selected>MA-Massachusetts</option>
                                <option selected>MI-Michigan</option>
                                <option selected>MN-Minnesota</option>
                                <option selected>MS-Mississippi</option>
                                <option selected>MO-Missouri</option>
                                <option selected>MT-Montana</option>
                                <option selected>NE-Nebraska</option>
                                <option selected>NV-Nevada</option>
                                <option selected>NH-New Hampshire</option>
                                <option selected>NJ-New Jersey</option>
                                <option selected>NM-New Mexico</option>
                                <option selected>NY-New York</option>
                                <option selected>NC-North Carolina</option>
                                <option selected>ND-North Dakota</option>
                                <option selected>OH-Ohio</option>
                                <option selected>OK-Oklahoma</option>
                                <option selected>OR-Oregon</option>
                                <option selected>PA-Pennsylvania</option>
                                <option selected>RI-Rhode Island</option>
                                <option selected>SC-South Carolina</option>
                                <option selected>TN-Tennessee</option>
                                <option selected>SD-South Dakota</option>
                                <option selected>TX-Texas</option>
                                <option selected>UT-Utah</option>
                                <option selected>VT-Vermont</option>
                                <option selected>VA-Virginia</option>
                                <option selected>WA-Washington</option>
                                <option selected>WV-West Virginia</option>
                                <option selected>WI-Wisconsin</option>
                                <option selected>WY-Wyoming</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3 pe-2">
                          <label for="City *">City *</label>
                          <input
                           
                            id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            className="form-control small-input"
                            type="text"
                            placeholder="City *"
                          />
                          <div>{cityerror}</div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-3 pb-3">
                          <label for="Phone *">Phone *</label>
                          <input
                            id="phone"
                            name="phone"
                            value={formatPhoneNumber(formData.phone)}
                            onChange={handleInputChange}
                            className="form-control small-input"
                            type="tel"
                            maxLength="12"
                            placeholder="Phone *"
                          />
                          <div>{phoneerror}</div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                </div>}
               
              </div>
              <div className="col-lg-4 ms-2">
                <div className="icon">
                  <div className="row mb-3">
                    <div className="col-3">
                      <span className="rounded-circle text-center homeicon" style={{ backgroundColor: "#CED9FD" }}>
                        <Icon icon="mdi:shopping-outline" color="white" />
                      </span>
                      <span className="shopping">
                        Order list{" "}
                        
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="rounded-circle text-center homeicon" style={{ backgroundColor: "#F9CBA0" }}>
                        <Icon icon="la:shipping-fast" color="white" />
                      </span>
                      <span className="shopping ms-1">
                        Shipping{" "}
                        
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="rounded-circle text-center homeicon" style={{ backgroundColor: "#97E2BE" }}>
                        <Icon icon="ic:outline-payment" color="white" />
                      </span>
                      <span className="shopping">
                        Payment{" "}
                        
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="rounded-circle text-center homeicon" style={{ backgroundColor: "#CED9FD" }}>
                        <Icon icon="tabler:receipt" color="white" />
                      </span>
                      <span className="shopping ms-1">
                        Receipt{" "}
                       
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-3 mb-3 product-spec-wrap">
                  <div id="accordion">
                    <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        PRODUCT SPECIFICATION
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        <form>
                          <div className="d-flex justify-content-between mx-1">
                            <h5 className="fw-bold mt-1" style={{fontSize:'14px'}}>
                              {quantity} x {boxname} ({selectedOption}){" "}
                            </h5>
                            <h5 className="bg-purple text-light rounded p-1"  style={{fontSize:'14px'}}>
                              {currency}
                              {estimatedCost}
                            </h5>
                          </div>
                          <div className="row mt-2">
                            <ul className="product-spec-wrapper">
                                <li>
                                  <label className="spec-name">Quantity:</label>
                                  <div>
                                    <span className="spec-value">{quantity} </span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">Length(in):</label>
                                  <div>
                                    <span className="spec-value">{firstNumber} </span>
                                  </div>
                                </li>
                               {boxname !== 'mylarbag' && <li>
                                  <label className="spec-name">Width(in):</label>
                                  <div>
                                    <span className="spec-value">{secondtNumber} </span>
                                  </div>
                                </li>}
                                <li>
                                  <label className="spec-name">Height(in):</label>
                                  <div>
                                    <span className="spec-value">{thirdnumber} </span>
                                  </div>
                                </li>

                               {boxname === 'Product' && <li>
                                  <label className="spec-name">Paper:</label>
                                  <div>
                                    <span className="spec-value">{paper} </span>
                                  </div>
                                </li>}

                                {boxname === 'Product' && <li>
                                 <>
                                  <label className="spec-name">Coating:</label>
                                 <div>
                                    <span className="spec-value">{material} </span>
                                  </div>
                                  </>
                                </li>}

                                {material && boxname !== 'Product' &&
                                <li>
                                 <label className="spec-name">Material:</label>
                                 <div>
                                    <span className="spec-value">{material} </span>
                                  </div>
                                </li>
                                }
                                <li>
                                  <label className="spec-name">Printer Sides:</label>
                                  <div>
                                    <span className="spec-value">{slides} </span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">Turnaround Time:</label>
                                  <div>
                                    <span className="spec-value">{time} </span>
                                  </div>
                                </li>
                            </ul>
                            <button 
                              className="btn btn-danger rounded-pill mt-3"
                              onClick={backtoMailerbox}>
                              Remove / Back to Home
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                {paymentmoadal ? <div className="mt-3 product-spec-wrap">
                  <div id="accordion">
                    <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        SHIPPING ADDRESS
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        <form>
                          <div className="row mx-1 mt-2 py-2 rounded p-2">
                            <ul className="product-spec-wrapper">
                                <li>
                                  <label className="spec-name">Name:</label>
                                  <div>
                                    <span className="spec-value">{firstName} {secondName} </span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">Email:</label>
                                  <div>
                                    <span className="spec-value">{email}</span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">Street Address:</label>
                                  <div>
                                    <span className="spec-value">{formData.address}</span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">Zip Code:</label>
                                  <div>
                                    <span className="spec-value">{zipcode} </span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">State:</label>
                                  <div>
                                    <span className="spec-value">{state} </span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">City:</label>
                                  <div>
                                    <span className="spec-value">{city} </span>
                                  </div>
                                </li>
                                <li>
                                  <label className="spec-name">Phone:</label>
                                  <div>
                                    <span className="spec-value">{phone} </span>
                                  </div>
                                </li>
                            </ul>
                          </div>
                        </form>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>: <form className="shadow border-0 rounded px-3 bg-light py-4">
                  <div
                    className="rounded-top">
                    <h4 style={{fontWeight:'600'}}>Order Summary</h4>
                  </div>
                  <p style={{color:"#D8AC48"}}>
                    <span
                      className="bg-purple"
                      style={{ padding: "0px 7px", color:"#1A0F31", fontWeight:"700" }}
                    >
                      1
                    </span>{" "}
                    {quantity} x {boxname} (
                    {selectedOption})
                  </p>
                  <p style={{ padding: "10", border:"1px solid #BDBDBD" , borderRadius:"10px" }}>
                    <span
                      className="bg-danger text-light rounded"
                      style={{ padding: "4px 5px" }}
                    >
                      Note
                    </span>{" "}
                    <span>Delivery Charges Includes</span>
                  </p>

                  <div className="total pt-3" style={{display:"flex", alignItems:"center", justifyContent:"space-evenly"}}>
                    <h4 style={{color:"#D8AC48", fontSize:"30px", fontWeight:"700" }}>{unitprice} Each</h4>
                    <h4 style={{color:"#1A0F31", fontSize:"18px", marginBottom:"0" }}>
                      Subtotal:
                      {currency}
                      {estimatedCost}
                      
                    </h4>
                  </div>


                  <div className="d-grid mt-2">
                    {chk ? (
                      <button
                        onClick={handlepaymentorder}
                        className="btn bg-purple text-white rounded-pill"
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#staticBackdrop"
                      >
                        Proceed To Payment
                      </button>
                    ) : (
                      <div
                        className="notshow"
                      >
                        Proceed To Payment
                      </div>
                    )}
                    {saveddesign ? 
                    <button
                        onClick={saveorderinfodraft}
                        style={{marginTop: '12px'}}
                        className="btn bg-purple text-white rounded-pill"
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#staticBackdrop"
                      >
                        Saved For Later
                      </button>
                      :
                      <button
                        onClick={saveorderinfodraft}
                        style={{marginTop: '12px'}}
                        disabled
                        className="notshow"
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#staticBackdrop"
                      >
                        Saved For Later
                      </button>
                      }
                  </div>
                </form>}
                
              </div>
            </div>
          </div>
        </>
      )  : (
        <>
       
          {/* <div className="container  mt-5 mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <h3 className="fs-3">SHIPPING</h3>
                
              </div>
              
            </div>
          </div> */}
        </>
      )}
    </>
    }
    </>
    
    
  );
};

export default Screen2;
