import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { SelectedOptionContext } from './Utils/Formdata';
import { useContext } from 'react';
import Modal2 from './Components/Modal2';


const Modal = (props) => {
  const [showPayPalButtons, setShowPayPalButtons] = useState(false);
  const { estimatedCost } = useContext(SelectedOptionContext);
  const [show, setShow] = useState(true);
  const { paymentmoadal , setPaymentmoadal } = useContext(SelectedOptionContext);
// const { adress, setAdress } = useContext(SelectedOptionContext);


const handlePlaceOrderClick = () => {
  console.log("1st MOdal close");
  const closeButton = document.querySelector('.btn-close-white'); // Adjust the selector based on your close button class or structure
  if (closeButton) {
    closeButton.click();
  }
  setPaymentmoadal(true);
};

  return (
    <>
      {/* <Modal2 /> */}
      {show && (
        <div>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-purple py-2 px-2 text-white rounded-top">
                  <h5 className="modal-title text-light" id="staticBackdropLabel">
                    Shipping Address Validation
                  </h5>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  {/* <div className="modal-body-content">
                    Sorry! We were not able to verify your shipping address. Please confirm your address as entered or modify it and try again.
                  </div> */}
                  <div className="body">
                    <div className="sc4head"></div>
                    <div className="ms-3 rounded">
                      <textarea className="rounded fw-bold" name="" id="" cols="50" rows="3" value={props.adress}></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="sc4btn-container mt-2 mb-2 col-12 justify-content-center d-flex">
                      <button
                        onClick={handlePlaceOrderClick}
                        type="button"
                        className="bg-purple text-white sc4btnmodal ms-3"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Please Confirm
                      </button>
                      <PayPalScriptProvider options={{ 'client-id': 'AS0DbClbtb7FOXrIr8p5GhJlkZgd9LxSz2zt5n2SeMmX1gfKic40pIJsOx5RV134yj5Zwm2xRsk9JEka' }}>
                        {showPayPalButtons && (
                          <PayPalButtons
                            createOrder={(data, actions) => {
                              return actions.order.create({
                                purchase_units: [
                                  {
                                    amount: {
                                      value: estimatedCost,
                                    },
                                  },
                                ],
                              });
                            }}
                          />
                        )}
                      </PayPalScriptProvider>
                    </div>
                    {/* <div className="sc4btn-container mt-2 mb-2 col-6">
                      <button className="text-dark sc4btnmodal" id="sc4btnconfirmadress">
                        Confirm Address As Is
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
