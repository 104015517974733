import React from 'react'
import Mailerbox from './MailerBoxModel/Mailerbox'
import MaterialTab from '../../Components/MaterialTab/MaterialTab'
// import Navbar from '../Components/Navbar'

const MailboxMain = () => {
    return (
        <>
          <div>       
            <div className="breadcrump-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="breadcrump-menu">
                                <ul>
                                    <li><a href="/"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.35888 13.7714C9.2618 13.7312 9.17881 13.6632 9.12042 13.5758C9.06203 13.4885 9.03085 13.3858 9.03083 13.2807L9.03083 9.03069L2.65583 9.03069C2.51494 9.03069 2.37981 8.97472 2.28018 8.87509C2.18055 8.77546 2.12458 8.64034 2.12458 8.49944C2.12458 8.35855 2.18055 8.22342 2.28018 8.12379C2.37981 8.02416 2.51494 7.96819 2.65583 7.96819L9.03083 7.96819L9.03083 3.71819C9.03075 3.61306 9.06186 3.51027 9.12023 3.42283C9.1786 3.33539 9.2616 3.26723 9.35872 3.22699C9.45585 3.18674 9.56273 3.17622 9.66584 3.19675C9.76895 3.21729 9.86364 3.26795 9.93794 3.34233L14.7192 8.12358C14.7686 8.17292 14.8078 8.23151 14.8345 8.296C14.8612 8.3605 14.875 8.42963 14.875 8.49944C14.875 8.56926 14.8612 8.63839 14.8345 8.70288C14.8078 8.76737 14.7686 8.82596 14.7192 8.8753L9.93794 13.6566C9.8636 13.7308 9.76891 13.7814 9.66584 13.8018C9.56276 13.8223 9.45594 13.8117 9.35888 13.7714Z" fill="#d7ab48" ></path>
                                        </svg>Home</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="breadcrump-links">
                                <ul>
                                    <li><a href="https://theboxlane.com/faqs/">Help &amp; FAQ</a></li>
                                    <span></span>
                                    <li>Questions? <a href="https://theboxlane.com/contact-us/"><b>Message Now</b></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
           
            
            <div className="product-form-gallery"><Mailerbox /></div>
            

            <MaterialTab/>       
    
             
            
            <section className="product-content-banner-wrapper" style={{ background: '#F3F4F6', marginTop: 0 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="product-content-banner-img">
                                <img src="https://theboxlane.com/assets/images/3d-product/mailer-boxes/banner_1_mailer_boxes.webp" alt="What are Custom Mailer Boxes | The Box Lane"/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="product-content-banner-wrap">
                                <h2>What are Custom Mailer Boxes? </h2>
                                <p>Your mailer box doesn’t need to be a simple brown box. It is valuable real estate that you can and must use for branding and marketing.</p>
                                <h3>Custom Mailer Box is Free Marketing</h3>
                                <p>You work on the product and product packaging. These two make the most of the buying experience. A mailer box with proper branding is a very tasty cherry on top that packs more surprises for the customer.</p>
                                <p>Customers will likely keep the mailer box if it is as good as your product packaging. Make the box visually appealing, put a lovely card with a personalized message in the box, add a free goodie to the box, and you have already won a lovely Instagram post from that customer.</p>
                                <h3>Custom Mailer Boxes are Environment-Friendly</h3>
                                <p>The most popular material for customized mailer boxes is corrugated stock. This is 100% recyclable material which is easy on the environment and goes great with the customers.</p>
                                <h3> Custom Mailer Boxes are Luxury</h3>
                                <p>Use rigid material for mailer boxes and create an effective, lasting impression of luxury and up your brand’s perceived value.</p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
    
            
            <div className="faq">
                <div className="container">
                    <div className="row d-flex justify-content-center pb-2">
                        <div className="col-xl-9 col-lg-9">
                            <div className="heading-section">
                                <h2>Frequently Asked <span className="sign-line z-index-2">Questions</span></h2>
                                <p>These are some of the repeated questions we get. Ask us on WhatsApp or Chat if we missed something.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <div className="faq-content responsive-pd">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Can I customize the size and design of the Mailer Boxes?  
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            Yes, you can get the size and design of your choice.  
    
     
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What is the minimum order quantity I can order? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We offer low minimums. You can order as low as 100 units only.   
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Do you offer design consultation? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We offer free design consultation for all customers. Contact us with all the details about your order and our trained custom packaging expert will get back to you.  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingSeven">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                   Which payment method do you accept?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                                We accept PayPal, Square, Credit Card, Cash Deposit or Wire. 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingEight">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                How do you know what the final product will look like?   
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            You can see the final product in two ways. First, order a custom sample and get the final product in your hands. Second, see digital mockups of the product in 2D and 3D.    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingNine">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                Do you ship worldwide?  
    
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We ship to the whole world. You get free shipping in the USA, UK, Australia, and Canada.  
    
       
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTen">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                What file type do you need for printing?  
       
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We need PSD, PDF, Ai format with a minimum of 300 dpi for printing.  
       
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingEleven">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                Can I purchase a sample before placing an order? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            Yes, you can order a sample. See a 3D mockup or get a free sample to your address and check the quality of our materials, finishing, and add-ons. Also see how your custom packaging will actually look like.  
    
      
         
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwelve">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                Can you provide me with a dyeline template? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            Yes, but you can get dyeline once you have placed a sample or a batch order. 
    
     
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThirteen">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                What is the delivery time for Mailer Boxes? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We ship expedited orders in 8-10 days and regular orders in 14-18 days.
       
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="more-faqs">
                                    <a href="https://theboxlane.com/faq/"><i className="fa fa-solid fa-arrow-right"></i>MORE QUESTION & ANSWERS   </a>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
         
            <section className="category-content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="category-content-left">
                                <h2> Customized Mailer Boxes are your Brand Ambassador  </h2>
                                <p> The term of a mailer box being a brand ambassador may be unheard of till now but a good custom shipping packaging acts the same way.</p>
                                <h3>Made to Fit the Size</h3>
                                <p>The mailer boxes are first customized to the product's size.  You can save on cost and time when you work with a reliable custom packaging partner like The Box Lane.</p>
                                <h3>Offers Space for Gifts</h3>
                                <p>When you use a mailer box to create a deeper connection with the customer, it becomes a way of sending personalized gifts and surprises.</p>
                                <p>For inspiration, you can send a personalized note in the mailer box, a small gift, a coupon, or a sweet message printed on the inside of the box. This will surely win you praise on Instagram.</p>
                                <h3>Perfect Your Buying Experience</h3>
                                <p>The last thing in making a buying experience perfect is personalized shipping boxes. When a customer finds a personal note from a brand, the loyalty you earn is unparalleled.</p>
                                
                                <a href="https://theboxlane.com/get-a-free-quote/" className="btn btn-black w-icon-right">Get a Free Quote <i className="fa fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="category-content-right">
                                <img src="https://theboxlane.com/assets/images/3d-product/mailer-boxes/custom_mailer_banner_3.webp" alt="Mailer Boxes For All Industries | The Box Lane"/>
                                <h2>Mailer Boxes for All Industries </h2>
                                <p>You will see customized mailer boxes most popular in cosmetics, personalized goods (like the ones sold on Etsy), local businesses, and small brands.</p>
                                <p>All industries use mailer boxes. Everyone can use custom size shipping boxes for their products. Customization is always helpful, even when you don’t put a custom note inside it.</p>
                                <p>Only a few brands now use simple brown mailer boxes. For all the right purposes, brands are using custom mailer boxes with logo. A simple, humble customization such as a brand logo on the box makes you stand out from the crowd. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
          
          
          </div>
        </>
      )
}

export default MailboxMain