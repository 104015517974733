import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AcountSetting.css";
import axios from "axios";
import AlertComponent from "../../Components/AlertComponent/AlertComponent";
import useAlert from "../../hooks/useAlert/useAlert";
import Cookies from "js-cookie";
import { AuthContext } from "../../context/AuthContext";

const AccountSetting = () => {
  // const [user, setUser] = useState(()=> localStorage.getItem("currentloginUser"));
  // const user = Cookies.get("currentloginUserboxlane");
  const { user } = useContext(AuthContext);

  const [isFormDataChanged, setIsFormDataChanged] = useState(false);

  // const [showToast, setShowToast] = useState(false);

  const { alertmsg, showAlert, hideAlert } = useAlert();

  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    address: "",
    apartment: "",
    zipcode: "",
    attention: "",
    city: "",
    phone: "",
    state: "",
    email: "",
  });

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [ispasswordDataChanged, setIsPasswordDataChanged] = useState(false);

  const [message, setMessage] = useState("");

  const togglePasswordVisibility = (fieldName) => {
    setShowPassword({ ...showPassword, [fieldName]: !showPassword[fieldName] });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIsFormDataChanged(true);
    // Check if the input field is for phone number
    if (name === "phone") {
      // Remove all non-numeric characters from the input value
      const numericValue = value.replace(/\D/g, "");

      // Format the phone number with dashes
      const formattedPhoneNumber = numericValue.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "$1-$2-$3"
      );

      // Update the form data state with the formatted phone number
      setFormData({ ...formData, [name]: formattedPhoneNumber });
    } else {
      // For other input fields, update the form data state directly
      setFormData({ ...formData, [name]: value });
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Check if the input value is a valid number
    if (phoneNumber) {
      const formattedPhoneNumber = phoneNumber.replace(/\D/g, "");
      const match = formattedPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
    }
    return phoneNumber;
  };

  const navigate = useNavigate();

  useEffect(() => {
    // const authToken = localStorage.getItem("currentloginUser");
    // setUser(authToken)
    // if(!user){
    //     navigate('/')
    // }
    const fetchCurrentUserDetails = async () => {
      try {
        // Make a request to the /current-user endpoint
        const response = await fetch(
          "https://boxlane-61739ddb4424.herokuapp.com/api/v1/current-user",
          {
            method: "GET",
            // headers: {
            //   Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Add the JWT token stored in localStorage
            //   'Content-Type': 'application/json',
            // },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        // console.log("response",response)
        // Parse the response JSON
        const data = await response.json();

        console.log("data", data);
        setFormData({
          firstName: data.user.firstName,
          secondName: data.user.lastName,
          address: data.user.streetAddress,
          apartment: data.user.apartment,
          zipcode: data.user.zipcode,
          attention: data.user.attention,
          city: data.user.city,
          phone: data.user.phone,
          state: data.user.state,
          email: data.user.email,
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchCurrentUserDetails();
  }, []);

  const updateuserinfo = (e) => {
    e.preventDefault();

    hideAlert();
    setIsFormDataChanged(false);
    const apiUrl = `https://boxlane-61739ddb4424.herokuapp.com/api/v1/update/${user._id}`;
    const updatedUserInfo = {
      firstName: formData.firstName,
      lastName: formData.secondName,
      attention: formData.attention,
      streetAddress: formData.address,
      apartment: formData.apartment,
      zipcode: formData.zipcode,
      state: formData.state,
      city: formData.city,
      phone: formData.phone,
    };

    fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(updatedUserInfo),
    })
      .then((response) => {
        if (!response.ok) {
          setIsFormDataChanged(true);
          throw new Error(`Request failed with status: ${response.status}`);
        }
        return response.json();
      })
      .then((updatedUser) => {
        console.log("User updated successfully:", updatedUser);
        // setIsFormDataChanged(false)
        showAlert("success", "User updated successfully");
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        // Handle the error as needed
      });
  };

  const handlepasswordChange = (event) => {
    const { name, value } = event.target;
    setIsPasswordDataChanged(true);
    setMessage("");
    // For other input fields, update the form data state directly
    setPasswordData({ ...passwordData, [name]: value });
  };

  const updatepassword = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setMessage("Incorrect Password");
    }
    try {
      // Send a request to the backend to update the password
      await axios.put(`https://boxlane-61739ddb4424.herokuapp.com/api/v1/update-password/${user._id}`, {
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
      });
      // setMessage(response.data.message);
      showAlert("success", "Password updated successfully");
      setPasswordData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setIsPasswordDataChanged(false);
    } catch (error) {
      // setMessage(error.response.data.message);
    }
  };
  return (
    <>
      <AlertComponent
        type={alertmsg.type}
        message={alertmsg.message}
        visible={alertmsg.visible}
        onClose={hideAlert}
      />

      <div className="fields screen3form container">
        <div className="account-setting-wrapper">
          <form action="modal">
            <h3 className="form-heading">Basic Information</h3>
            <div className="row">
              <div className="col-lg-6 col-md-12 mb-3 pe-2">
                <label for="First Name *">First Name *</label>
                <input
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="form-control small-input "
                  type="text"
                  placeholder="First Name *"
                />
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <label for="Last Name *">Last Name *</label>
                <input
                  id="secondName"
                  name="secondName"
                  value={formData.secondName}
                  onChange={handleInputChange}
                  className="form-control small-input"
                  type="text"
                  placeholder="Last Name *"
                />
              </div>
              <div className="col-lg-6 col-md-12 mb-3 pe-2">
                <label for="Email Address *">Email Address *</label>
                <input
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="form-control small-input"
                  type="email"
                  placeholder="Email Address *"
                  disabled={true}
                />
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <label for="Attention (optional)">Attention (optional)</label>
                <input
                  className="form-control small-input"
                  type="text"
                  name="attention"
                  id="attention"
                  value={formData.attention}
                  onChange={handleInputChange}
                  placeholder="Attention (optional)"
                />
              </div>
              <div className="col-lg-6 col-md-12 mb-3 pe-2">
                <label for="Street Address *">Street Address *</label>
                <input
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="form-control small-input"
                  type="text"
                  placeholder="Street Address *"
                />
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <label for="Apartment, suite, etc. (optional)">
                  Apartment, suite, etc. (optional)
                </label>
                <input
                  className="form-control small-input"
                  type="text"
                  name="apartment"
                  id="apartment"
                  value={formData.apartment}
                  onChange={handleInputChange}
                  placeholder="Apartment, suite, etc. (optional)"
                />
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <label for="Zip Code *">Zip Code *</label>
                <input
                  id="zipcode"
                  name="zipcode"
                  value={formData.zipcode}
                  onChange={handleInputChange}
                  className="form-control small-input"
                  type="number"
                  placeholder="Zip Code *"
                />
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <div>
                  <div className="ms-2">
                    <label for="State *">State *</label>
                    <select
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="form-select small-input"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      placeholder="State *"
                    >
                      <option selected>Select State</option>
                      <option selected>AL-Alabama</option>
                      <option selected>AK-Alaska</option>
                      <option selected>AZ-Arizona</option>
                      <option selected>AR-Arkansas</option>
                      <option selected>CA-California</option>
                      <option selected>CO-Colorado</option>
                      <option selected>CT-Connecticut</option>
                      <option selected>DE-Delaware</option>
                      <option selected>FL-Florida</option>
                      <option selected>GA-Georgia</option>
                      <option selected>HI-Hawaii</option>
                      <option selected>ID-Idaho</option>
                      <option selected>IL-Illinois</option>
                      <option selected>IN-Indiana</option>
                      <option selected>IA-Iowa</option>
                      <option selected>KS-Kansas</option>
                      <option selected>KY-Kentucky</option>
                      <option selected>LA-Louisiana</option>
                      <option selected>ME-Maine</option>
                      <option selected>MD-Maryland</option>
                      <option selected>MA-Massachusetts</option>
                      <option selected>MI-Michigan</option>
                      <option selected>MN-Minnesota</option>
                      <option selected>MS-Mississippi</option>
                      <option selected>MO-Missouri</option>
                      <option selected>MT-Montana</option>
                      <option selected>NE-Nebraska</option>
                      <option selected>NV-Nevada</option>
                      <option selected>NH-New Hampshire</option>
                      <option selected>NJ-New Jersey</option>
                      <option selected>NM-New Mexico</option>
                      <option selected>NY-New York</option>
                      <option selected>NC-North Carolina</option>
                      <option selected>ND-North Dakota</option>
                      <option selected>OH-Ohio</option>
                      <option selected>OK-Oklahoma</option>
                      <option selected>OR-Oregon</option>
                      <option selected>PA-Pennsylvania</option>
                      <option selected>RI-Rhode Island</option>
                      <option selected>SC-South Carolina</option>
                      <option selected>TN-Tennessee</option>
                      <option selected>SD-South Dakota</option>
                      <option selected>TX-Texas</option>
                      <option selected>UT-Utah</option>
                      <option selected>VT-Vermont</option>
                      <option selected>VA-Virginia</option>
                      <option selected>WA-Washington</option>
                      <option selected>WV-West Virginia</option>
                      <option selected>WI-Wisconsin</option>
                      <option selected>WY-Wyoming</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mb-3 pe-2">
                <label for="City *">City *</label>
                <input
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="form-control small-input"
                  type="text"
                  placeholder="City *"
                />
              </div>
              {/* <div className="col-lg-6 col-md-12 mb-3 pb-3">
                          <label for="Phone *">Phone *</label>
                          <input
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            className="form-control small-input"
                            type="tel"
                            maxLength="12"
                            placeholder="Phone *"
                          />
                        </div> */}
              <div className="col-lg-6 col-md-12 mb-3 pb-3">
                <label htmlFor="phone">Phone *</label>
                <input
                  id="phone"
                  name="phone"
                  value={formatPhoneNumber(formData.phone)}
                  onChange={handleInputChange}
                  className="form-control small-input"
                  type="text"
                  maxLength="14"
                  placeholder="Phone *"
                />
              </div>
            </div>
          </form>
          <div className="" style={{ textAlign: "center" }}>
            {isFormDataChanged ? (
              <span
                style={{ width: "200px", background: "#D8AC48"}}
                onClick={updateuserinfo}
                className="btn text-white rounded-pill"
              >
                Update Details
              </span>
            ) : (
              <span
                style={{
                  cursor: "not-allowed",
                  background: "rgb(211, 211, 211)",
                  color: "gray",
                  width: "200px",
                  opacity: ".65",
                  pointerEvents: "none",
                }}
                className="btn rounded-pill"
              >
                Update Details
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="fields screen3form container">
        <div className="account-setting-wrapper">
          <form action="modal">
            <h3 className="form-heading">Update Password</h3>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="oldPassword">Old Password *</label>
                <div className="password-input-container">
                  <input
                    id="oldPassword"
                    name="oldPassword"
                    value={passwordData.oldPassword}
                    onChange={handlepasswordChange}
                    className="form-control small-input"
                    type={showPassword.oldPassword ? "text" : "password"}
                    placeholder="Old Password *"
                  />
                  <span
                    className="password-toggle"
                    onClick={() => togglePasswordVisibility("oldPassword")}
                  >
                    {passwordData.oldPassword &&
                      (showPassword.oldPassword ? (
                        <i className="fa fa-eye"></i>
                      ) : (
                        <i className="fa fa-eye-slash"></i>
                      ))}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="newPassword">New Password *</label>
                <div className="password-input-container">
                  <input
                    id="newPassword"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlepasswordChange}
                    className="form-control small-input"
                    // type="password"
                    type={showPassword.newPassword ? "text" : "password"}
                    placeholder="New Password *"
                  />
                  <span
                    className="password-toggle"
                    onClick={() => togglePasswordVisibility("newPassword")}
                  >
                    {passwordData.newPassword &&
                      (showPassword.newPassword ? (
                        <i className="fa fa-eye"></i>
                      ) : (
                        <i className="fa fa-eye-slash"></i>
                      ))}
                  </span>
                </div>
              </div>
              {message !== "" && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {message}
                </span>
              )}
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="confirmPassword">Confirm Password *</label>
                <div className="password-input-container">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handlepasswordChange}
                    className="form-control small-input"
                    // type="password"
                    type={showPassword.confirmPassword ? "text" : "password"}
                    placeholder="Confirm Password *"
                  />
                  <span
                    className="password-toggle"
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {passwordData.confirmPassword &&
                      (showPassword.confirmPassword ? (
                        <i className="fa fa-eye"></i>
                      ) : (
                        <i className="fa fa-eye-slash"></i>
                      ))}
                  </span>
                </div>
              </div>
              {message !== "" && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {message}
                </span>
              )}
            </div>
          </form>
          <div className="text-center">
            {ispasswordDataChanged ? (
              <button
                style={{
                  cursor: "pointer",
                  background: "#D8AC48",
                  color: "#fff",
                  width: "200px",
                }}
                onClick={updatepassword}
                className="btn rounded-pill"
              >
                Update Password
              </button>
            ) : (
              <button
                style={{
                  cursor: "not-allowed",
                  background: "#D3D3D3",
                  color: "gray",
                  width: "200px",
                }}
                className="btn rounded-pill"
                disabled
              >
                Update Password
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSetting;
