import React from 'react'
import { Icon } from '@iconify/react';

const Footer = () => {
  return (
  <>
  <section className="cta-wrapper-footer">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="cta-banner-content">
                            <p className="sub-heading">Need Help?</p>
                            <h3>Get In Touch for a Consultation</h3>
                            <p>Need help finding the perfect custom boxes? Super easy.<br/> Contact us now for a free consultation with a trained packaging specialist.</p>
                            <a className="btn btn-yellow w-icon-right" href="https://theboxlane.com/contact-us/">Contact Us<i className="fa fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 <footer>
    <div className="container">
        <div className="row">
            <div className="col-lg-3">
                <div className="footer-col-1">
                    <img className="img-fluid" src="https://theboxlane.com/assets/images/logo-white.svg" alt="The Box lane"/>
                    <p>The Box Lane is an experienced and reliable custom packaging and custom boxes supplier for the last 8 years. We offer a one-stop solution to all custom packaging needs of brands and retailers. With a team of competent packaging experts and designers, we keep delivering quality consistently.</p>
                    <ul>
                        <li><img src="https://theboxlane.com/assets/images/footer/country-1.png" alt=""/></li>
                        <li><img src="https://theboxlane.com/assets/images/footer/country-2.png" alt=""/></li>
                        <li><img src="https://theboxlane.com/assets/images/footer/country-3.png" alt=""/></li>
                        <li><img src="https://theboxlane.com/assets/images/footer/country-4.png" alt=""/></li>
                    </ul>
                    <span><b>100%</b> Free Shipping All Across</span>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="footer-col-2">
                    <span className="footer-title">INDUSTRIES</span>
                    <ul className="footer-list">
                         <li><a href="https://theboxlane.com/custom-cbd-boxes/">Custom CBD Packaging</a></li>
                        <li><a href="https://theboxlane.com/custom-apparel-boxes/">Apparel & Fashion Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-candle-boxes/">Candle Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-jewelry-boxes">Jewelry Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-vape-boxes/">Custom Vape Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-food-packaging/">Custom Food Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-printed-gift-boxes/">Custom Gift Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-two-piece-boxes/">Two Piece Box Style</a></li>
                        <li><a href="https://theboxlane.com/all-custom-packaging-categories/">View All<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.7" d="M11.2462 5.64471L7.29616 1.69471C7.114 1.50611 7.0132 1.25351 7.01548 0.991311C7.01776 0.729114 7.12293 0.478301 7.30834 0.292893C7.49375 0.107485 7.74456 0.00231622 8.00675 3.78026e-05C8.26895 -0.00224062 8.52155 0.0985538 8.71016 0.280712L14.3672 5.93771C14.4603 6.03036 14.5343 6.14053 14.5848 6.26186C14.6352 6.38319 14.6612 6.5133 14.6612 6.64471C14.6612 6.77612 14.6352 6.90623 14.5848 7.02757C14.5343 7.1489 14.4603 7.25906 14.3672 7.35171L8.71016 13.0087C8.61791 13.1042 8.50756 13.1804 8.38556 13.2328C8.26356 13.2852 8.13234 13.3128 7.99956 13.314C7.86678 13.3151 7.7351 13.2898 7.6122 13.2395C7.48931 13.1893 7.37765 13.115 7.28376 13.0211C7.18987 12.9272 7.11562 12.8156 7.06533 12.6927C7.01505 12.5698 6.98975 12.4381 6.99091 12.3053C6.99206 12.1725 7.01965 12.0413 7.07205 11.9193C7.12446 11.7973 7.20065 11.687 7.29616 11.5947L11.2462 7.64471H1.66016C1.39494 7.64471 1.14059 7.53935 0.953049 7.35182C0.765513 7.16428 0.660156 6.90993 0.660156 6.64471C0.660156 6.3795 0.765513 6.12514 0.953049 5.9376C1.14059 5.75007 1.39494 5.64471 1.66016 5.64471H11.2462Z" fill="white"/>
                        </svg>
                        </a></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="footer-col-3">
                    <span className="footer-title">Categories</span>
                    <ul className="footer-list">
                        <li><a href="https://theboxlane.com/custom-hemp-boxes/">Hemp Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-mailer-boxes/">Shipping & Mailer Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-freeze-dried-packaging/">Dried Freeze Packaging</a></li>
                        <li><a href="https://theboxlane.com/custom-kraft-boxes/">Kraft Boxes</a></li>
                        <li><a href="https://theboxlane.com/customized-mylar-bags/">Mylar Bags</a></li>
                        <li><a href="https://theboxlane.com/custom-dispenser-boxes/">Dispenser Boxes</a></li>
                        <li><a href="https://theboxlane.com/custom-tray-and-sleeves-boxes">Trays And Sleeves</a></li>
                        <li><a href="https://theboxlane.com/custom-label-and-stickers/">Label and Stickers</a></li>
                        <li><a href="https://theboxlane.com/custom-christmas-boxes/">Christmas Boxes</a></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="footer-col-4">
                    <span className="footer-title">COMPANY</span>
                    <ul className="footer-list">
                        <li><a href="https://theboxlane.com/about/">About</a></li>
                        <li><a href="https://theboxlane.com/contact-us/">Contact Us</a></li>
                        <li><a href="https://theboxlane.com/faqs/">FAQs</a></li>
                        <li><a href="">Blogs</a></li>
                        <li><a href="https://theboxlane.com/terms-and-conditions/">Terms & Conditions</a></li>
                        <li><a href="https://theboxlane.com/privacy-policy/">Privacy Policy</a></li>
                        <li><a href="https://theboxlane.com/get-a-free-quote/">Get Free Quote</a></li>
                    </ul>
                    <img src="https://theboxlane.com/assets/images/footer/ssl.png" alt="SSL" />
                    <p><b>100%</b> Secure Payment</p>
                    <img src="https://theboxlane.com/assets/images/footer/payment.png" alt="Payment" />
                </div>
            </div>
        </div>
    </div>
</footer>


<div className="copyright-footer-wrapper"> 
    <div className="container">
        <div className="copyright-footer">
            <p>© 2024 The Box Lane LLC. All Rights Reserved</p>
            <ul className="footer-social">
                                    <li><a target="_blank" href="https://www.pinterest.com/officialtheboxlane/">
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_236_6526)">
                                                <path d="M8.53904 14.0839C8.018 16.8119 7.38304 19.427 5.49997 20.793C4.9195 16.6703 6.35285 13.5747 7.01951 10.288C5.88332 8.37623 7.1562 4.53084 9.55041 5.47881C12.4974 6.64372 6.9987 12.5832 10.6896 13.3261C14.5439 14.0997 16.1169 6.63976 13.7267 4.21386C10.2745 0.709218 3.67633 4.13164 4.4886 9.14888C4.68572 10.3752 5.95266 10.7467 4.99478 12.4395C2.78482 11.9502 2.1251 10.2068 2.21029 7.88293C2.34699 4.07914 5.62676 1.41748 8.91744 1.04799C13.0788 0.581435 16.9846 2.57644 17.5245 6.49018C18.1317 10.9091 15.6464 15.6956 11.1957 15.3508C9.98923 15.2567 9.48404 14.6584 8.53904 14.0839Z" fill="#ffffff"></path>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_236_6526">
                                                <rect width="19.8114" height="19.8114" fill="white" transform="translate(0 0.981201)"></rect>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </li>
                                    <li><a target="_blank" href="https://www.youtube.com/@TheBoxLane">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.6434 4.96974H6.08638C3.81798 4.96974 1.98047 6.78744 1.98047 9.03009V14.7437C1.98047 16.9863 3.81897 18.805 6.08638 18.805H17.6434C19.9118 18.805 21.7493 16.9863 21.7493 14.7437V9.03009C21.7493 6.78744 19.9108 4.96875 17.6434 4.96875V4.96974ZM14.8668 12.1643L9.46125 14.715C9.42828 14.7308 9.39183 14.7381 9.35529 14.7361C9.31876 14.7341 9.28332 14.7229 9.25228 14.7035C9.22123 14.6842 9.1956 14.6572 9.17775 14.6253C9.15991 14.5934 9.15043 14.5574 9.15021 14.5208V9.26188C9.15087 9.2251 9.16083 9.18909 9.17916 9.1572C9.19749 9.12531 9.22359 9.09858 9.25504 9.07949C9.28648 9.06041 9.32224 9.0496 9.35899 9.04806C9.39575 9.04652 9.43229 9.05431 9.46522 9.0707L14.8717 11.7799C14.9078 11.7979 14.938 11.8256 14.9589 11.86C14.9799 11.8943 14.9907 11.9339 14.9902 11.9741C14.9897 12.0144 14.9778 12.0536 14.956 12.0874C14.9342 12.1213 14.9033 12.1482 14.8668 12.1652V12.1643Z" fill="#ffffff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li><a target="_blank" href="https://www.threads.net/@theboxlane">
                                            <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_236_6529)">
                                                <path d="M7.16121 11.2377C7.16121 10.7778 7.36838 10.0464 8.97738 10.0464C9.47059 10.0464 9.77451 10.0845 10.1825 10.1821C10.0521 11.9836 9.22556 12.2214 8.42212 12.2214C7.98253 12.2214 7.16121 11.9769 7.16121 11.2377Z" fill="#ffffff"></path>
                                                <path d="M2.72373 18.8607H14.1024C14.8248 18.8607 15.5176 18.5546 16.0284 18.0098C16.5392 17.4649 16.8261 16.7259 16.8261 15.9554V3.81815C16.8261 3.04762 16.5392 2.30864 16.0284 1.76379C15.5176 1.21894 14.8248 0.912842 14.1024 0.912842L2.72373 0.912842C2.00135 0.912842 1.30856 1.21894 0.797762 1.76379C0.286963 2.30864 0 3.04762 0 3.81815L0 15.9554C0 16.7259 0.286963 17.4649 0.797762 18.0098C1.30856 18.5546 2.00135 18.8607 2.72373 18.8607ZM6.16888 7.54233C6.76515 6.63372 7.55177 6.27925 8.64021 6.27925C9.4079 6.27925 10.061 6.5552 10.5279 7.07681C10.9938 7.59954 11.2598 8.34662 11.3208 9.30122C11.5785 9.41676 11.8172 9.55249 12.0338 9.70842C12.9088 10.3355 13.3904 11.2732 13.3904 12.3479C13.3904 14.6329 11.6342 16.6172 8.45618 16.6172C5.72719 16.6172 2.89199 14.9234 2.89199 9.88229C2.89199 4.8681 5.6378 3.15632 8.44776 3.15632C9.74548 3.15632 12.79 3.36048 13.9341 7.39314L12.8615 7.68928C11.976 4.81762 10.1199 4.35995 8.41937 4.35995C5.60625 4.35995 4.01618 6.18615 4.01618 10.073C4.01618 13.5582 5.79344 15.4091 8.45618 15.4091C10.6467 15.4091 12.2799 14.1954 12.2799 12.4174C12.2799 11.2071 11.3271 10.6282 11.2777 10.6282C11.0916 11.6659 10.5931 13.4124 8.4036 13.4124C7.12797 13.4124 6.02691 12.4724 6.02691 11.2407C6.02691 9.48182 7.59174 8.84468 8.8274 8.84468C9.29012 8.84468 9.84749 8.87833 10.1388 8.94114C10.1388 8.40495 9.71393 7.48737 8.63916 7.48737C7.68112 7.48737 7.41927 7.81043 7.11429 8.18734L7.08906 8.21762C6.87663 8.06619 6.16888 7.54233 6.16888 7.54233Z" fill="#ffffff"></path>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_236_6529">
                                                <rect width="16.8261" height="17.9479" fill="white" transform="translate(0 0.912842)"></rect>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </li>
                                    <li><a target="_blank" href="https://www.facebook.com/theboxlane/">
                                            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.45687 6.152V8.72079H6.57422V11.8617H8.45687V21.1964H12.3213V11.8626H14.9153C14.9153 11.8626 15.1583 10.3567 15.2761 8.70957H12.3372V6.56144C12.3372 6.24081 12.7578 5.80894 13.1747 5.80894H15.2817V2.53906H12.4176C8.36059 2.53906 8.45687 5.68274 8.45687 6.152Z" fill="#ffffff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li><a target="_blank" href="https://twitter.com/theboxlane">
                                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_236_6534)">
                                                <path d="M15.0184 1.72778H17.9426L11.5542 8.63917L19.0696 18.044H13.1842L8.57527 12.34L3.30158 18.044H0.375682L7.20867 10.6515L0 1.72778H6.03301L10.1991 6.94145L15.0167 1.72778H15.0184ZM13.9922 16.3873H15.6124L5.15347 3.29749H3.41473L13.9922 16.3873Z" fill="#ffffff"></path>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_236_6534">
                                                <rect width="19.0696" height="17.9479" fill="white" transform="translate(0 0.912842)"></rect>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </li>
                                    <li><a target="_blank" href="https://www.instagram.com/theboxlane/">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.349 1.91675H7.61522C6.10408 1.91852 4.65535 2.51968 3.5869 3.58831C2.51845 4.65694 1.91754 6.10577 1.91602 7.61691L1.91602 15.3507C1.91779 16.8618 2.51894 18.3105 3.58758 19.379C4.65621 20.4474 6.10504 21.0484 7.61618 21.0499H15.3499C16.8611 21.0481 18.3098 20.4469 19.3783 19.3783C20.4467 18.3097 21.0476 16.8609 21.0491 15.3497V7.61596C21.0474 6.10481 20.4462 4.65608 19.3776 3.58763C18.309 2.51918 16.8601 1.91827 15.349 1.91675V1.91675ZM19.1248 15.3497C19.1248 15.8456 19.0271 16.3365 18.8374 16.7947C18.6476 17.2528 18.3695 17.669 18.0189 18.0196C17.6683 18.3702 17.252 18.6484 16.7939 18.8381C16.3358 19.0279 15.8448 19.1255 15.349 19.1255H7.61522C6.61398 19.1253 5.65382 18.7274 4.94593 18.0193C4.23803 17.3112 3.84035 16.351 3.84035 15.3497V7.61596C3.8406 6.61471 4.23853 5.65456 4.9466 4.94666C5.65468 4.23876 6.61493 3.84108 7.61618 3.84108H15.3499C16.3512 3.84134 17.3113 4.23926 18.0192 4.94734C18.7271 5.65541 19.1248 6.61567 19.1248 7.61691V15.3507V15.3497Z" fill="#ffffff"></path>
                                                <path d="M11.4821 6.53491C10.1705 6.53694 8.91329 7.05891 7.98598 7.98641C7.05866 8.9139 6.53693 10.1712 6.53516 11.4828C6.53668 12.7947 7.05843 14.0524 7.98598 14.9801C8.91353 15.9079 10.1712 16.4298 11.483 16.4316C12.7951 16.4301 14.0529 15.9082 14.9807 14.9805C15.9085 14.0527 16.4303 12.7948 16.4319 11.4828C16.4298 10.1709 15.9076 8.91338 14.9797 7.98601C14.0518 7.05864 12.794 6.53714 11.4821 6.53587V6.53491ZM11.4821 14.5073C10.6802 14.5073 9.91113 14.1887 9.34411 13.6217C8.77708 13.0547 8.45853 12.2856 8.45853 11.4837C8.45853 10.6819 8.77708 9.9128 9.34411 9.34578C9.91113 8.77875 10.6802 8.4602 11.4821 8.4602C12.284 8.4602 13.053 8.77875 13.62 9.34578C14.1871 9.9128 14.5056 10.6819 14.5056 11.4837C14.5056 12.2856 14.1871 13.0547 13.62 13.6217C13.053 14.1887 12.284 14.5073 11.4821 14.5073Z" fill="#ffffff"></path>
                                                <path d="M16.4413 7.75764C17.096 7.75764 17.6268 7.22689 17.6268 6.57218C17.6268 5.91747 17.096 5.38672 16.4413 5.38672C15.7866 5.38672 15.2559 5.91747 15.2559 6.57218C15.2559 7.22689 15.7866 7.75764 16.4413 7.75764Z" fill="#ffffff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li><a target="_blank" href="https://www.linkedin.com/company/the-box-lane/">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.7513 13.5896V20.899H17.5136V14.0789C17.5136 12.3652 16.9004 11.1964 15.367 11.1964C14.1962 11.1964 13.4988 11.9848 13.1927 12.7466C13.0808 13.019 13.0521 13.3984 13.0521 13.7798V20.899H8.81243C8.81243 20.899 8.86988 9.34894 8.81243 8.15134H13.0521V9.95814L13.0243 9.99974H13.0521V9.95814C13.6147 9.09139 14.6201 7.85219 16.8717 7.85219C19.6602 7.85219 21.7513 9.67484 21.7513 13.5896ZM4.37864 2.00684C2.92943 2.00684 1.98047 2.95778 1.98047 4.20887C1.98047 5.43223 2.9017 6.4119 4.32317 6.4119H4.3509C5.82982 6.4119 6.74808 5.43223 6.74808 4.20887C6.72233 2.95778 5.83081 2.00684 4.37963 2.00684H4.37864ZM2.23207 20.899H6.46973V8.15134H2.23207V20.899Z" fill="#ffffff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
       
        </div>
    </div>
</div>


  </>
  )
}

export default Footer