import { createContext, useEffect, useReducer } from "react";
import Cookies from "js-cookie";

// user: JSON.parse(localStorage.getItem("currentloginUser")) || null,

// const INITIAL_STATE = {
//   user: localStorage.getItem("currentloginUserboxlane") || null,
//   loading: false,
//   error: null,
// };

// export const AuthContext = createContext(INITIAL_STATE);

const INITIAL_STATE = {
  user: null,
  loading: false,
  error: null,
};

const cookieUser = Cookies.get("currentloginUserboxlane");
if (cookieUser) {
  try {
    // INITIAL_STATE.user = JSON.parse(cookieUser);
    INITIAL_STATE.user = JSON.parse(cookieUser);
  } catch (error) {
    console.error("Error parsing user from cookie:", error);
  }
}

export const AuthContext = createContext(INITIAL_STATE);

const AuthReducer = (state, action) => {
  console.log("action.payload",action.payload)

  switch (action.type) {
    case "LOGIN_START":
      return {
        user: null,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        user: action.payload,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return {
        user: null,
        loading: false,
        error: action.payload,
      };
    case "LOGOUT":
      return {
        user: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  // console.log("state",state)

  // useEffect(() => {
  //   localStorage.setItem("currentloginUserboxlane", state.user);
  // }, [state.user]);

  useEffect(() => {
    // localStorage.setItem("theboxlaneadminuser", JSON.stringify(state.user));
    // Cookies.set("currentloginUserboxlane", JSON.stringify(state.user), { expires: 7 }); // Expires in 7 days
    Cookies.set("currentloginUserboxlane", JSON.stringify(state.user), {
      expires: 7, // Expires in 7 days
      path: '/', // Cookie available for entire domain
      secure: true, // Cookie sent only over HTTPS
      sameSite: 'strict' // Protection against CSRF attacks
  });
  }, [state.user]);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};