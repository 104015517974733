import React from 'react'
import Magneticbox from './MagneticBoxModel/Magneticbox'
import MaterialTab from '../../Components/MaterialTab/MaterialTab'
// import Navbar from '../Components/Navbar'

const MagneticboxMain = () => {
    return (
        <>
          <div>       
            <div className="breadcrump-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="breadcrump-menu">
                                <ul>
                                    <li><a href="/"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.35888 13.7714C9.2618 13.7312 9.17881 13.6632 9.12042 13.5758C9.06203 13.4885 9.03085 13.3858 9.03083 13.2807L9.03083 9.03069L2.65583 9.03069C2.51494 9.03069 2.37981 8.97472 2.28018 8.87509C2.18055 8.77546 2.12458 8.64034 2.12458 8.49944C2.12458 8.35855 2.18055 8.22342 2.28018 8.12379C2.37981 8.02416 2.51494 7.96819 2.65583 7.96819L9.03083 7.96819L9.03083 3.71819C9.03075 3.61306 9.06186 3.51027 9.12023 3.42283C9.1786 3.33539 9.2616 3.26723 9.35872 3.22699C9.45585 3.18674 9.56273 3.17622 9.66584 3.19675C9.76895 3.21729 9.86364 3.26795 9.93794 3.34233L14.7192 8.12358C14.7686 8.17292 14.8078 8.23151 14.8345 8.296C14.8612 8.3605 14.875 8.42963 14.875 8.49944C14.875 8.56926 14.8612 8.63839 14.8345 8.70288C14.8078 8.76737 14.7686 8.82596 14.7192 8.8753L9.93794 13.6566C9.8636 13.7308 9.76891 13.7814 9.66584 13.8018C9.56276 13.8223 9.45594 13.8117 9.35888 13.7714Z" fill="#d7ab48" ></path>
                                        </svg>Home</a></li>
                                      {/* <li><a href="https://theboxlane.com/custom-window-box-packaging/"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.35888 13.7714C9.2618 13.7312 9.17881 13.6632 9.12042 13.5758C9.06203 13.4885 9.03085 13.3858 9.03083 13.2807L9.03083 9.03069L2.65583 9.03069C2.51494 9.03069 2.37981 8.97472 2.28018 8.87509C2.18055 8.77546 2.12458 8.64034 2.12458 8.49944C2.12458 8.35855 2.18055 8.22342 2.28018 8.12379C2.37981 8.02416 2.51494 7.96819 2.65583 7.96819L9.03083 7.96819L9.03083 3.71819C9.03075 3.61306 9.06186 3.51027 9.12023 3.42283C9.1786 3.33539 9.2616 3.26723 9.35872 3.22699C9.45585 3.18674 9.56273 3.17622 9.66584 3.19675C9.76895 3.21729 9.86364 3.26795 9.93794 3.34233L14.7192 8.12358C14.7686 8.17292 14.8078 8.23151 14.8345 8.296C14.8612 8.3605 14.875 8.42963 14.875 8.49944C14.875 8.56926 14.8612 8.63839 14.8345 8.70288C14.8078 8.76737 14.7686 8.82596 14.7192 8.8753L9.93794 13.6566C9.8636 13.7308 9.76891 13.7814 9.66584 13.8018C9.56276 13.8223 9.45594 13.8117 9.35888 13.7714Z" fill="#d7ab48" ></path>
                                        </svg>Custom Magnetic Boxes</a></li> */}
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="breadcrump-links">
                                <ul>
                                    <li><a href="https://theboxlane.com/faqs/">Help &amp; FAQ</a></li>
                                    <span></span>
                                    <li>Questions? <a href="https://theboxlane.com/contact-us/"><b>Message Now</b></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
                       
            <div className="product-form-gallery"><Magneticbox /></div>
    

            <MaterialTab/>
    
             
            
            <section className="product-content-banner-wrapper" style={{ background: '#F3F4F6', marginTop: 0 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="product-content-banner-img">
                                <img src="https://theboxlane.com/assets/images/3d-product/magnetic-boxes/banner_1_magnetic_closure_boxes.webp" alt="What are Custom Mailer Boxes | The Box Lane"/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="product-content-banner-wrap">
                                <h2>Play with Perception with Magnetic Closure Boxes </h2>
                                <p>What do you think about the product when you see it in a magnetic closure box? Does it look premium? It must.  Magnetic boxes make a product look premium. Just look at all the jewelry boxes.</p>
                                <p>The magnetic closure boxes are made of rigid stock. This material is hard and it speaks splendor. You must have seen the famous Louis Vuitton boxes with magnetic closure. They became a thing on the internet as soon as they came.</p>
                                <p>A custom box with magnetic closure increases the perceived value of your brand. It is an affordable value-addition that can help you charge more for the same item.</p>
                                <p>It is about value as well as price. For example, if you select magnetic gift boxes for bridesmaids, print their names on the box in silver or golden color, and then put their gifts inside. The gift unboxing will match the love and affection of the gift-giver.</p>
                                <p>Hint! A black magnetic closure with a box made of rigid material would be perfect for the occasion.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
    
    
            
            <div className="faq">
                <div className="container">
                    <div className="row d-flex justify-content-center pb-2">
                        <div className="col-xl-9 col-lg-9">
                            <div className="heading-section">
                                <h2>Frequently Asked <span className="sign-line z-index-2">Questions</span></h2>
                                <p>These are some of the repeated questions we get. Ask us on WhatsApp or Chat if we missed something.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <div className="faq-content responsive-pd">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Can I order Magnetic Closure Boxes in the design and size that I want?  
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                                Yes, we offer custom magnetic closure boxes with full customization. We make all custom boxes in custom sizes and designs.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What types of gifts can use the magnetic closure boxes for? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            Magnetic boxes, or more precisely, magnetic gift boxes are great for cosmetics, treats, small gadgets, jewelry, or other special gifts like wristwatches, mobile phones, etc. 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Can I order Magnetic Closure Boxes for personal or corporate events?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            Absolutely, you can order custom boxes for your personal events or business events. We offer low minimums and quick delivery. Contact us for more information.  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingSeven">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                What payment methods do you accept? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We accept PayPal, Square, Credit Card, Cash Deposit, or Wire.   
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingEight">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                How do you know what the final product will look like? 
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            You can see the final product in two ways. First, order a custom sample and get the final product in your hands. Second, see digital mockups of the product in 2D and 3D.    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingNine">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                Do you ship worldwide?  
    
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We ship to the whole world. You get free shipping in the USA, UK, Australia, and Canada.  
    
       
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTen">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                What file type do you need for printing?  
       
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                            We need PSD, PDF, Ai format with a minimum of 300 dpi for printing.  
       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="more-faqs">
                                    <a href="https://theboxlane.com/faq/"><i className="fa fa-solid fa-arrow-right"></i>MORE QUESTION & ANSWERS   </a>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
         
            <section className="category-content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="category-content-left">
                                <h2> One Box for All Happenings </h2>
                                <p>A magnetic closure box is great for a product package but there are many other ways you can use it. </p>                       
                                <p>For product packaging, you can use it for anything. You will only need to adjust the size and the box is ready to use.</p>
                                <p>A rigid box with magnetic closure is a perfect gift packaging. Since it shouts extravagance, it is a very suitable gift packaging for weddings and many types of showers that surround weddings.</p>
                                <p>Holding a high-end party! Use magnetic boxes for gifts and listen to praises for months for your excellent aesthetic sense.</p>
                                <p>Sell organic, exotic products in a box that matches the customer’s perception of the same. A kraft box doesn’t go well with a product that you developed in months or imported from the other side of the world. Not using a magnetic closure box will only lower its perceived value and hence overall brand value. This is an avoidable loss.</p>
                                <p>Upgrade your product packaging with a magnetic closure box today!</p>
                                <a href="https://theboxlane.com/get-a-free-quote/" className="btn btn-black w-icon-right">Get a Free Quote <i className="fa fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="category-content-right">
                                <img src="https://theboxlane.com/assets/images/3d-product/magnetic-boxes/custom_magnetic_banner_3.webp" alt="Mailer Boxes For All Industries | The Box Lane"/>
                                <h2>Custom Box with Magnetic Closure Just as You Want</h2>
                                <p>You understand your brand, we understand custom packaging. Let’s work together and make magic happen.</p>
                                <p>The range of customizations for magnetic closure boxes is immense. The most used material is rigid, but you can use other materials like cardboard, chipboard, etc.</p>
                                <p>Next comes the artwork and finishes. Give us the artwork or get our help with custom packaging design.</p>
                                <p>Pick a finish that matches your brand personality and connects you to your customer base. Choose from matte, glossy, or soft touch.</p>
                                <p>Move to style and select the magnetic closure box in your required style. The magnetic closure style boxes come in two styles; magnetic collapsible boxes and magnetic assembled boxes.</p>
                                <p>The add-on options are varied, you can choose from embossed, debossed, raised ink, spot UV, and holographic foiling.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
          
         
            
          
          
          </div>
        </>
      )
}

export default MagneticboxMain