import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SelectedOptionContext } from '../../../Utils/Formdata';
import { Icon } from '@iconify/react';
import '../../../App.css'
import { setShowpopup } from "../../../Redux/Slices/LoginSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { AuthContext } from "../../../context/AuthContext";

const MailerBoxForm = ({ xyzvalue, setxyzValues, setShowMailerBox }) => {
    const { setQuantity } = useContext(SelectedOptionContext);
    const { selectedOption, setSelectedOption } = useContext(SelectedOptionContext);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [isCustomizeClicked, setIsCustomizeClicked] = useState(false);
    const [isStandardClicked, setIsStandardClicked] = useState(true);
    const [isToggleOn, setIsToggleOn] = useState(false);
    const Dollar = 270;
    const card_rate = 500;
    const [L, setL] = useState('');
    const [W, setW] = useState('');
    const [D, setD] = useState('');
    const [Q, setQ] = useState(0);
    const [gsm, setGsm] = useState('');
    const [unitCost, setunitCost] = useState(0);
    const [Rush, setRush] = useState(false);
    const [standard, setStandard] = useState(true);
    const { unitprice, setUnitPrice } = useContext(SelectedOptionContext);
    const { estimatedCost, setEstimatedCost } = useContext(SelectedOptionContext);
    const { material, setMaterial } = useContext(SelectedOptionContext);
    const { boxname , setSetboxname } = useContext(SelectedOptionContext);
    const { slides, setSlides } = useContext(SelectedOptionContext);
    const { time, setTime } = useContext(SelectedOptionContext);
    const [lengthErrorMessage, setLengthErrorMessage] = useState("");
    const [widthErrorMessage, setWidthErrorMessage] = useState("");
    const [depthErrorMessage, setDepthErrorMessage] = useState("");
    const [materialErrorMessage, setMaterialErrorMessage] = useState("");
    const [LWDErrorMessage, setLWDErrorMessage] = useState("");
    const [slidesErrorMessage, setSlidesErrorMessage] = useState("");
  
    
    const dispatch = useDispatch();
    useEffect(()=>{
      setSetboxname("Mailer")
    },[])

    const { user } = useContext(AuthContext);


    const [nameError, setnameError] = useState("");
    const [emailError, setemailError] = useState("");
    const [phoneError, setphoneError] = useState("");
  
    const [name, setname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setemail] = useState("");
  
    const handlenamechange = (event) => {
      const val = event.target.value;
      setnameError("")
      setname(val)
    }
  
    const handlephonechange = (event) => {
      const val = event.target.value;
      setphoneError("")
      setPhone(val)
    }
  
    const handleemailchange = (event) => {
      const val = event.target.value;
      setemailError("")
      setemail(val)
    }
  
    const handleCustomizeClick = () => {
      setIsCustomizeClicked(true);
      setxyzValues({ x: '' , y: '', z: '' });
      setIsStandardClicked(false);
      setIsToggleOn(false);
    };
  
  
    const handleSelectChange = (e) => {
      const optionValue = e.target.value;
      setSelectedOption(optionValue);
      setShowMailerBox(true);
     
      
      if(e.target.value.z === 0 || e.target.value.x === 0 || e.target.value.y === 0){
        setLWDErrorMessage(
          <span style={{ color: "red" }}>
            Select L x W x D
          </span>
        );  
  
      }
      else{
        setLWDErrorMessage("");
      }
  
      switch (optionValue) {
        
        case '3 x 3 x 1':
          setxyzValues({ x: 3 , y: 1, z:  3});
          break;
        case '6 x 4 x 2':
          setxyzValues({ x: 4, y: 2, z: 6 });
          break;
        case '6 x 6 x 2':
            setxyzValues({ x: 6, y: 2, z: 6 });
            break;
        case '8 x 8 x 3':
          setxyzValues({ x: 8, y: 3, z: 8 });
          break;
        case '9 x 6 x 2':
          setxyzValues({ x: 6, y: 2, z: 9 });
          break;
        case '10 x 8 x 3':
          setxyzValues({ x: 8, y: 3, z: 10 });
          break;
        case '10 x 8 x 4':
          setxyzValues({ x: 8 , y: 4, z: 10 });
          break;
        case '9 x 12 x 2':
          setxyzValues({ x: 12 , y: 2, z: 9 });
          break;
        case '12 x 10 x 4':
          setxyzValues({ x: 10 , y: 4, z: 12 });
          break;
        case '14 x 10 x 4':
          setxyzValues({ x: 10 , y: 4, z: 14 });
          break;
        default:
          break;
      }
    };
  
    useEffect(() => {
    }, [Rush]);
  
  
    // Destructure quantity from the context
    const { quantity } = useContext(SelectedOptionContext);
  
    const submit = () => {
     
      if(isCustomizeClicked){
        if(xyzvalue.x === 0 || xyzvalue.x === '' || isNaN(xyzvalue.x) ){
          setLengthErrorMessage(
            <span style={{ color: "red" }}>
              Select Length
            </span>
          );  
        }
        else if(xyzvalue.z === 0 || xyzvalue.z === '' || isNaN(xyzvalue.z) ){
          setWidthErrorMessage(
            <span style={{ color: "red" }}>
              Select Width
            </span>
          );  
        }
        else if(xyzvalue.y === 0 || xyzvalue.y === '' || isNaN(xyzvalue.y) ){
          setDepthErrorMessage(
            <span style={{ color: "red" }}>
              Select Height
            </span>
          );  
        }
      }else if((xyzvalue.z === 0 || xyzvalue.z === '' || isNaN(xyzvalue.z)) || (xyzvalue.x === 0 || xyzvalue.x === '' || isNaN(xyzvalue.x)) || (xyzvalue.y === 0 || xyzvalue.y === '' || isNaN(xyzvalue.y))){
        setLWDErrorMessage(
          <span style={{ color: "red" }}>
            Select L x W x D
          </span>
        );
      }
  
      else if(xyzvalue.z < 1 || xyzvalue.z > 30 || isNaN(xyzvalue.z) ){
        setLengthErrorMessage(
          <span style={{ color: "red" }}>
            Length range 1 - 30
          </span>
        );  
      }
  
      else if(xyzvalue.x < 1 || xyzvalue.x > 20 || isNaN(xyzvalue.x) ){
        setWidthErrorMessage(
          <span style={{ color: "red" }}>
            Width range 1 - 20
          </span>
        );  
      }
  
      else if(xyzvalue.y < 1 || xyzvalue.y > 20 || isNaN(xyzvalue.y) ){
        setDepthErrorMessage(
          <span style={{ color: "red" }}>
            Depth range 1 - 20
          </span>
        );  
      }
  
      // else if(name === ''){
      //   setnameError(
      //       <span style={{ color: "red" }}>
      //         Please Enter Name
      //       </span>
      //     )
      //   }
      //   else if(email === '' ){
      //     setemailError(
      //         <span style={{ color: "red" }}>
      //           Please Enter Email
      //         </span>
      //       )
      //     }
      //     else if(phone === '' ){
      //       setphoneError(
      //           <span style={{ color: "red" }}>
      //             Please Enter Phone Number
      //           </span>
      //         )
      //       }
     else if(gsm === '' || gsm === 'select'){
      console.log("gsm on submit is : ", gsm)
        setMaterialErrorMessage(
          <span style={{ color: "red" }}>
            Select Material
          </span>
        )
      }
    
    else if(slides === '' || slides === 'select' ){
      setSlidesErrorMessage(
          <span style={{ color: "red" }}>
            Select Printed Sides
          </span>
        )
      }
  
  
  
  
  
      else if (Q < 50 || isNaN(Q)) {
        setErrorMessage(
          <span style={{ color: "red" }}>Quantity must be at least 50</span>
        );
      
      } 
  
      else {
        setErrorMessage("");
        setLengthErrorMessage("");
        setWidthErrorMessage("");
        setDepthErrorMessage("");
        setLWDErrorMessage("");
        setMaterialErrorMessage("");
        setSlidesErrorMessage("");
        setnameError("");
        setemailError("");
        setphoneError("")
        setname("");
        setemail("");
        setPhone("");
        // navigate("/screen2");
        submitFormtoApi();
      }
  
      setSelectedOption(xyzvalue.z+' x '+xyzvalue.x+' x '+xyzvalue.y)
    };
  
  const submitFormtoApi = () => {
    
    const data = {
      Length: L,
      Width: W,
      Depth: D,
      Quantity: Q,
      Material: gsm,
      unitCost: unitCost,
      estimatedCost: estimatedCost,
      PrintedSides: slides,
      name: name,
      email: email,
      phone: phone,
    }
  
    console.log('Data:', data);
  
    // fetch('https://zjs0ltyijk.execute-api.us-west-2.amazonaws.com/dev/boxlaneEmailApp', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     // Handle the response from the server
    //     console.log('Form data sent successfully:', data);
    //     window.location.href = "https://theboxlane.com/thankyou/";
    //     // Optionally, you can reset the form fields after successful submission
    //   })
    //   .catch(error => {
    //     console.error('Error sending form data:', error);
    //   });

    // const cookieUser = Cookies.get("currentloginUserboxlane");
    // const isuser = localStorage.getItem("currentloginUserboxlane");

    //  console.log("isuser",isuser)

    if(!user){
      dispatch(setShowpopup(true));
    }
    if(user){
    navigate("/shipping-address");
    }
  };
  
    // const handleLengthChange = (event) => {
    //   const newValue = parseFloat(event.target.value, 10);
    
    //   // Check if the new value is an empty string
    //   if (event.target.value === "") {
    //     setLengthErrorMessage("");
    //     setL("");  // Assuming you want to clear the state when the input is empty
    //     setxyzValues((prevValues) => ({ ...prevValues, x: "" }));
    //     setShowMailerBox(true);
    //     // console.log(newValue);
    //     return;
    //   }
    
    //    else if (newValue < 3 || newValue > 15 || isNaN(newValue)) {
    //     setxyzValues((prevValues) => ({ ...prevValues, x: newValue }));
    //     setLengthErrorMessage(
    //       <span style={{ color: "red" }}>
    //         Length range 3 - 15
    //       </span>
    //     );
    //     setTimeout(() => {
    //       setLengthErrorMessage("");
    //     }, 1500);
    //   } 
    //   else {
    //     setLengthErrorMessage("");
    //     setL(newValue);
    //     setxyzValues((prevValues) => ({ ...prevValues, x: newValue }));
    //     setShowMailerBox(true);
    //     // console.log(newValue);
    //   }
    // };

    // const handleWidthChange = (event) => {
    //   const newValue = parseFloat(event.target.value);
    
    //   // Check if the new value is an empty string
    //   if (event.target.value === "") {
    //     setLengthErrorMessage("");
    //     setWidthErrorMessage("");  // Assuming you want to clear the width error message as well
    //     setW("");  // Assuming you want to clear the state when the input is empty
    //     setxyzValues((prevValues) => ({ ...prevValues, z: "" }));
    //     return;
    //   }
    
    //  else if (newValue < 2 || newValue > 13 || isNaN(newValue)) {
    //     setWidthErrorMessage(
    //       <span style={{ color: "red" }}>
    //         Width range 2 - 13
    //       </span>
    //     );
    //     setTimeout(() => {
    //       setWidthErrorMessage("");
    //     }, 1500);
    //   } else {
    //     setLengthErrorMessage("");
    //     setWidthErrorMessage("");
    //     setW(newValue);
    //     setxyzValues((prevValues) => ({ ...prevValues, z: newValue }));
        
    //   }
    // };


    
const handleLengthChange = (event) => {
  if(W >= 2 && W <= 13){
    setWidthErrorMessage("");
  }
      const newValue = parseFloat(event.target.value, 10);
      // Check if the new value is an empty string
      if (event.target.value === "") {
        setLengthErrorMessage("");
        setL("");  // Assuming you want to clear the state when the input is empty
        setxyzValues((prevValues) => ({ ...prevValues, x: "" }));
        setShowMailerBox(true);
        // console.log(newValue);
        return;
      }
    
       else if (newValue < 3 || newValue > 15 || isNaN(newValue)) {
        setL(newValue)
        // setxyzValues((prevValues) => ({ ...prevValues, x: newValue }));
        setLengthErrorMessage(
          <span style={{ color: "red" }}>
            Length range 3 - 15
          </span>
        );
      }
      else {
        setLengthErrorMessage("");
        setL(newValue);
        setxyzValues((prevValues) => ({ ...prevValues, x: newValue }));
        setShowMailerBox(true);
        // console.log(newValue);
      }
    };

    const handleWidthChange = (event) => {
      if(L >= 3 && L <= 15){
        setLengthErrorMessage("");
      }
      const newValue = parseFloat(event.target.value);
    
      // Check if the new value is an empty string
      if (event.target.value === "") {
        // setLengthErrorMessage("");
        setWidthErrorMessage("");  // Assuming you want to clear the width error message as well
        setW("");  // Assuming you want to clear the state when the input is empty
        setxyzValues((prevValues) => ({ ...prevValues, z: "" }));
        return;
      }
    
     else if (newValue < 2 || newValue > 13 || isNaN(newValue)) {
      setW(newValue)
        setWidthErrorMessage(
          <span style={{ color: "red" }}>
            Width range 2 - 13
          </span>
        );
      } else {
        // setLengthErrorMessage("");
        setWidthErrorMessage("");
        setW(newValue);
        setxyzValues((prevValues) => ({ ...prevValues, z: newValue }));
        
      }
    };

    const handleDepthChange = (event) => {

      const newValue = parseFloat(event.target.value);
    
      // Check if the new value is an empty string
      if (event.target.value === "") {
        setDepthErrorMessage("");
        setD("");  // Assuming you want to clear the state when the input is empty
        setxyzValues((prevValues) => ({ ...prevValues, y: "" }));
        return;
      }
    
      if (newValue < 1 || newValue > 6 || isNaN(newValue)) {
        setD(newValue)
        setDepthErrorMessage(
          <span style={{ color: "red" }}>
            Depth range 1 - 6
          </span>
        );
      } else {
        setDepthErrorMessage("");
        setD(newValue);
        setxyzValues((prevValues) => ({ ...prevValues, y: newValue }));
      }
    };
    
    
    // console.log("L:",L,"W:",W,"D:",D);
    // console.log("xyzvalue",xyzvalue)

    // const handleLengthChange = (event) => {
    //   const newValue = parseFloat(event.target.value,10);
    //   if (newValue > W && newValue !== W) {
    //     setLengthErrorMessage( 
    //       <span style={{ color: "red" }}>
    //         Length should not be greater than width
    //       </span>
    //     );
    //     // setxyzValues((prevValues) => ({ ...prevValues, x: L }));
    //   }
    //   else if (newValue < 1 || newValue > 20 || isNaN(newValue)) {
    //     setxyzValues((prevValues) => ({ ...prevValues, x: newValue}));
    //     setLengthErrorMessage(
    //       <span style={{ color: "red" }}>
    //         Length range 1 - 20
    //       </span>
    //     );
    //   } else {
    //     setLengthErrorMessage("");
    //     setL(newValue);
    //     setxyzValues((prevValues) => ({ ...prevValues, x: newValue }));
    //     setShowMailerBox(true)
    //     console.log(newValue)
    //   }
    // };
  

    
    // const handleWidthChange = (event) => {
    //   const newValue = parseFloat(event.target.value);
      
    //   if (newValue < L && newValue !== L) {
    //     setLengthErrorMessage(
    //       <span style={{ color: "red" }}>
    //         Length should not be greater than width
    //       </span>
    //     );
    //   }
    //   else if (newValue < 1 || newValue > 20 || isNaN(newValue)) {
    //     setWidthErrorMessage(
    //       <span style={{ color: "red" }}>
    //         Width range 1 - 20
    //       </span>
    //     );
    //     // if (newValue > L) {
    //     //   setLengthErrorMessage(
    //     //     ""
    //     //   ); 
    //     // }
    //   } else {
    //     // if (newValue > L) {
    //       setLengthErrorMessage(
    //         ""
    //       ); 
    //     // }
    //     setWidthErrorMessage("");
    //     setW(newValue);
    //     setxyzValues((prevValues) => ({ ...prevValues, z: newValue }));
    //   }
    // };
  

    // const handleDepthChange = (event) => {
    //   const newValue = parseFloat(event.target.value);
    //   if (newValue < 1 || newValue > 6 || isNaN(newValue)) {
    //     setDepthErrorMessage(
    //       <span style={{ color: "red" }}>
    //         Depth range 1 - 6
    //       </span>
    //     );
    //   } else {
    //     setDepthErrorMessage("");
    //     setD(newValue);
    //     setxyzValues((prevValues) => ({ ...prevValues, y: newValue }));
    //   }
    // };
  
    const handleQuantityChange = (event) => {
      setQ(parseFloat(event.target.value));
      const qty = parseInt(event.target.value, 10);
  
      if (qty < 50 || isNaN(qty)) {
        setErrorMessage(
          <span style={{ color: "red" }}>Quantity must be at least 50</span>
        );
      } else if (qty > 10000) {
        setErrorMessage(
          <span style={{ color: "red", marginLeft: "1rem" }}>Quantity cannot exceed 10,000</span>
        );
      } else {
        setErrorMessage("");
      }
  
      setQuantity(event.target.value);
    };
  
  
    const handleGsmChange = (event) => {
      setGsm(event.target.value);
      console.log("GSM CHECKED" ,gsm)
      setMaterial(event.target.value)
      if(event.target.value == 'select'){
        setMaterialErrorMessage(
          <span style={{ color: "red" }}>
            Select Material
          </span>
        )
      }
      else{
        setMaterialErrorMessage('');
      }
      
    };
  
    const handleStandardChange = (event) => {
      setRush(false);
      setStandard(true);
      setTime(event.target.value)
    }
  
    const handleRushChange = (event) => {
      setRush(true);
      setStandard(false);
      setTime(event.target.value)
    }
  
    const handlePrintedData = (event) => {
      setSlides(event.target.value)
      if(event.target.valuees == 'select'){
        setSlidesErrorMessage(
            <span style={{ color: "red" }}>
              Select Printed Sides
            </span>
          )
        }
      else{
        setSlidesErrorMessage("");
      }
  
      }
  
  
    useEffect(() => {
      if (isStandardClicked) {
        setL(xyzvalue.x);
        setW(xyzvalue.z);
        setD(xyzvalue.y);
      }
    }, [isStandardClicked, xyzvalue]);
  
    useEffect(() => {
      if (Q <= 10000) {
      let cost =  calculateEstimatedCost()
      if(Rush !== false){
         cost =  parseInt(calculateEstimatedCost())
         var addon = cost * 0.35
        cost = cost + addon
      }

      console.log("L",L)
      console.log("W",W)
      console.log("D",D)

      if ((isNaN(L) || L === 0 || L === '') || (isNaN(W) || W === 0 || W === '') || (isNaN(D) || D === 0 || D === '')) {
        setunitCost('')
        setUnitPrice('')
        setEstimatedCost('')
      }else{
      const UnitCost = parseFloat(cost / Q).toFixed(2);
      setunitCost(UnitCost)
      setUnitPrice(UnitCost)
      setEstimatedCost(cost)
      }
    }
      // Check if quantity is 50 and all dimensions are 1, then set unit price to 121.52
    }, [quantity, Q, gsm, L, W, D ,Rush]);
  
    const calculateEstimatedCost = () => {
        // Check if any input value is NaN or missing
        // (L == 0 || NaN(L) || W == 0 || isNaN(W) || D == 0 || isNaN(D) || Q == 0 || isNaN(Q))
        if ((isNaN(L) || L === 0) && (isNaN(W) || W === 0) && (isNaN(D) || D === 0)) {
        return 0;
      }
  
  
       // Constants
       const Dollar = 270;
       const card_rate = 500;
       const mc = 28 * 40;
       const lw1 = parseFloat((D * 4 + L) * (W * 2 + D *3));
   
       // Calculate gsm_cost based on the selectedMaterial
       let gsm_cost;
       if (gsm === 'Premiumcardstock' || gsm === 'Brownkraftstock') {
         gsm_cost = 350;
       } else {
         gsm_cost = 550;
       }
   
       // Production Cost Logic
       if (lw1 < mc) {
         // Production Cost Calculation
   
         const L1 = D * 4 + L;
         const W1 = W * 2 + D *3;
         const card_weight = ((L1 * W1 * gsm_cost) / 1550000) * (Q + 200);
         const card_cost = card_weight * card_rate;
   
         // Plates Cost
         const plates_cost = 6000;
   
         // Die Cost
         const die_cost = 5000;
   
         // Printing Cost
         let printing_cost;
         if(Q <= 1000){
            printing_cost = 12000;
               }
               else if( Q>=1001 && Q<= 2000){
                       printing_cost = 16000;
               }
               else if( Q>=2001 && Q<= 3000){
                       printing_cost = 24000;
               }
               else if( Q>=3001 && Q<= 4000){
                       printing_cost = 32000;
               }
               else if( Q>=4001 && Q<= 5000){
                       printing_cost = 40000;
               }
               else if( Q>=5001 && Q<= 6000){
                       printing_cost = 48000;
               }
               else if( Q>=6001 && Q<= 7000){
                       printing_cost = 56000;
               }
               else if( Q>=7001 && Q<= 8000){
                       printing_cost = 64000;
               }
               else if( Q>=8001 && Q<= 9000){
                       printing_cost = 72000;
               }
               else if( Q>=9001 && Q<= 10000){
                       printing_cost = 80000;
               }
   
         // Lamination Cost
         const lamination_cost = (L1 * W1) / 144 * (Q + 200) * 5;
   
         // Cutting Cost
         let cutting_cost;
         if(Q <= 1000){
                    cutting_cost = 800;
           }
           else if( Q>=1001 && Q<= 2000){
                cutting_cost = 1600;
           }
           else if( Q>=2001 && Q<= 3000){
                cutting_cost = 2400;
           }
           else if( Q>=3001 && Q<= 4000){
                cutting_cost = 3200;
           }
           else if( Q>=4001 && Q<= 5000){
                cutting_cost = 4000;
           }
           else if( Q>=5001 && Q<= 6000){
                cutting_cost = 4800;
           }
           else if( Q>=6001 && Q<= 7000){
                cutting_cost = 5600;
           }
           else if( Q>=7001 && Q<= 8000){
                cutting_cost = 6400;
           }
           else if( Q>=8001 && Q<= 9000){
                cutting_cost = 7200;
           }
           else if( Q>=9001 && Q<= 10000){
                cutting_cost = 8000;
           }
         // Extra
         const extra = (card_cost + plates_cost + die_cost + printing_cost + lamination_cost + cutting_cost) * 0.2;
   
         // Total production cost
         let production_cost = card_cost + plates_cost + die_cost + printing_cost + lamination_cost + cutting_cost;
         production_cost = (production_cost / Dollar).toFixed(2);
   
         // Get the Shipping Cost
         const weight = (L1 * W1 * gsm_cost / 1550000) * Q;
         const total_weight = weight * 1.1;
         let shipping_cost = total_weight * 2300;
         shipping_cost = (shipping_cost / Dollar).toFixed(2);
   
         // Calculate the estimated cost
         let estimated_cost = (parseFloat(shipping_cost) + parseFloat(production_cost)) * 1.25;
         estimated_cost = estimated_cost.toFixed(2);
   
         return estimated_cost;
       } else if (lw1 > mc) {
         // Two open sizes
         const L11 = D * 2 + L;
         const W11 = D * 2 + W;
         const lw11 = parseFloat(L11 * W11);
   
         const L12 = D * 4 + L;
         const W12 = D * 2 + W;
         const lw12 = parseFloat(L12 * W12);
   
         if (lw11 > mc || lw12 > mc) {
           return 'Size too Big';
         } else {
           // Card Price Calculation
           const card_cost = (Q + 200) * gsm_cost * 1 / 1550000 * card_rate * (lw11 + lw12);
   
           // Plates Cost
           const plates_cost = 12000;
   
           // Die Cost
           const die_cost = 10000;
   
           // Printing Cost
           let printing_cost;
           if(Q <= 1000){
                printing_cost = 18000;
          }
          else if( Q>=1001 && Q<= 2000){
               printing_cost = 30000;
          }
          else if( Q>=2001 && Q<= 3000){
               printing_cost = 42000;
          }
          else if( Q>=3001 && Q<= 4000){
               printing_cost = 54000;
          }
          else if( Q>=4001 && Q<= 5000){
               printing_cost = 66000;
          }
          else if( Q>=5001 && Q<= 6000){
               printing_cost = 78000;
          }
          else if( Q>=6001 && Q<= 7000){
               printing_cost = 90000;
          }
          else if( Q>=7001 && Q<= 8000){
               printing_cost = 102000;
          }
          else if( Q>=8001 && Q<= 9000){
               printing_cost = 114000;
          }
          else if( Q>=9001 && Q<= 10000){
               printing_cost = 126000;
          }
   
           // Lamination Cost
           const lamination_cost = (Q + 200) / 144 * 5 * (lw11 + lw12);
   
           // Cutting Cost
           let cutting_cost;
           if(Q <= 1000){
                cutting_cost = 1500;
          }
          else if( Q>=1001 && Q<= 2000){
               cutting_cost = 3000;
          }
          else if( Q>=2001 && Q<= 3000){
               cutting_cost = 4500;
          }
          else if( Q>=3001 && Q<= 4000){
               cutting_cost = 6000;
          }
          else if( Q>=4001 && Q<= 5000){
               cutting_cost = 7500;
          }
          else if( Q>=5001 && Q<= 6000){
               cutting_cost = 9000;
          }
          else if( Q>=6001 && Q<= 7000){
               cutting_cost = 10500;
          }
          else if( Q>=7001 && Q<= 8000){
               cutting_cost = 12000;
          }
          else if( Q>=8001 && Q<= 9000){
               cutting_cost = 13500;
          }
          else if( Q>=9001 && Q<= 10000){
               cutting_cost = 15000;
          }
   
           // Extra
           const extra = (card_cost + plates_cost + die_cost + printing_cost + lamination_cost + cutting_cost) * 0.2;
   
           // Total production cost
           let production_cost = card_cost + plates_cost + die_cost + printing_cost + lamination_cost + cutting_cost;
           production_cost = (production_cost / Dollar).toFixed(2);
   
           // Get the Shipping Cost
           const weight = (L11 * W11 * gsm_cost / 1550000) * Q;
           const total_weight = weight * 1.1;
           let shipping_cost = total_weight * 2300;
           shipping_cost = (shipping_cost / Dollar).toFixed(2);
   
           // Calculate the estimated cost
           let estimated_cost = (parseFloat(shipping_cost) + parseFloat(production_cost)) * 1.3;
           estimated_cost = estimated_cost.toFixed(2);
   
           return estimated_cost;
         }
       } else {
         return '0';
       }
  
    }
    return (
      <>
        <div className="controunded rounded bg-light">
          <div className="row" style={{margin:'0'}}>
            <div className="form-wrapper" style={{ padding: "0px 0px" }}>
              <div className="form-header">
                <span>Customize & Check Prices</span>
              </div>
              <div className="form-fields">
                <div className="buttoncont w-100 mb-2 mx-auto">
                  <div className="button-container d-flex justify-content-around">
                    <button
                      className={`btn w-100 formwidth ${isStandardClicked ? 'active standard' : ''}`}
                      onClick={() => {
                        setSelectedOption('');
                        setIsCustomizeClicked(false);
                        setIsStandardClicked(true);
                        setIsToggleOn(false);
                      }}
                    >
                      STANDARD SIZES
                    </button>
                    <button
                      type="button"
                      className={`btn w-100 formwidth ${isCustomizeClicked ? 'active' : ''}`}
                      onClick={() => {
                        setxyzValues({ x: '' , y: '', z: '' });
                        setD('');
                        setL('');
                        setW('');
                        handleCustomizeClick();
                        setIsToggleOn(false);
                      }}
                    >
                      CUSTOMIZE SIZES
                    </button>
                  </div>
                </div>
                <div>
                  <div className="px-3">
                  {isCustomizeClicked ? (
                        <div className="row">
                                <div className="col-lg-4 pe-2">
                                  <label for="Length(in)">Length(in)</label>
                                  <input type="number" className="form-control" value={L}  min='3' onChange={handleLengthChange} placeholder="Length(in)" required/>
                                  <div>{lengthErrorMessage}</div>
                                </div>
                                <div className="col-lg-4 pe-2">
                                  <label for="Length(in)">Width(in)</label>
                                  <input type="number" className="form-control" value={W}  min='2' onChange={handleWidthChange} placeholder="Width(in)" required/>
                                  <div>{widthErrorMessage}</div>
                                </div>
                                <div className="col-lg-4">
                                  <label for="Length(in)">Height(in)</label>
                                  <input type="number" className="form-control" value={D}  min='1' onChange={handleDepthChange} placeholder="Height(in)" required/>
                                  <div>{depthErrorMessage}</div>
                                </div>
                        </div>
                  ) : (
                    <>
                      <label for="Interior Dimensions (Length x Width x Height)">Interior Dimensions (Length x Width x Height)</label>
                      <select name="" id="" className='form-select small-input' value={selectedOption} onChange={handleSelectChange} required>
                        <option>Select Dimensions</option>
                        <option value="3 x 3 x 1">3" x 3" x 1"</option>
                        <option value="6 x 4 x 2">6" x 4" x 2"</option>
                        <option value="6 x 6 x 2">6" x 6" x 2"</option>
                        <option value="8 x 8 x 3">8" x 8" x 3"</option>
                        <option value="9 x 6 x 2">9" x 6" x 2"</option>
                        <option value="10 x 8 x 3">10" x 8" x 3"</option>
                        <option value="10 x 8 x 4">10" x 8" x 4"</option>
                        <option value="12 x 10 x 4">12" x 10" x 4"</option>
                        <option value="14 x 10 x 4">14" x 10" x 4"</option>
                      </select>
                      <div>{LWDErrorMessage}</div>
                    </>
                  )}
                  </div>
                    {/* <div className="row px-3">
                      <div className="col-lg-4 pe-2 mt-3">
                        <input type="text" className="form-control" onChange={handlenamechange} placeholder="Full Name" required/>
                        <div>{nameError}</div>
                        
    
                      </div>
                      <div className="col-lg-4 pe-2 mt-3">
                        <input type="email" className="form-control" onChange={handleemailchange} placeholder="Email" required/>
                        <div>{emailError}</div>
                      </div>
                      <div className="col-lg-4 mt-3">
                          <input type="number" className="form-control" onChange={handlephonechange} placeholder="Phone" required/>
                        <div>{phoneError}</div>
                      </div>
                    </div> */}
                  <div>
                    <div className="px-3 mt-2">
                      <label for="Select Material">Select Material</label>
                      <select name="" id="" value={gsm} className='form-select small-input' onChange={handleGsmChange} required>
                        <option value="select">Select Material</option>
                        <option value="Premiumcardstock">Premium Cardstock</option>
                        <option value="Brownkraftstock">Brown Kraft Stock</option>
                        <option value="Whitecorrugated">White Corrugated</option>
                        <option value="Kraftcorrugated">Kraft Corrugated</option>
                      </select>
                      <div>{materialErrorMessage}</div>
                    </div>
                    <div className="px-3 mt-2">
                      <label for="Printed Sides">Printed Sides</label>
                      <select name="" id="" value={slides} className='form-select small-input' onChange={handlePrintedData} required> 
                        <option value="select">Select Printed Sides</option>
                        <option value="Outside Only - Full Color">Outside Only - Full Color</option>
                        <option value="Inside Only - Full Color">Inside Only - Full Color</option>
                        <option value="Outside & Inside - Full Color">Outside & Inside - Full Color</option>
                        <option value="No Printing">No Printing</option>
                      </select>
                      <div>{slidesErrorMessage}</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="px-3 mt-2">
                          <label for="Quantity">Quantity</label>
                          <input type="number" className="form-control w-100 small-input" min='50' onChange={handleQuantityChange} placeholder="Quantity" required/>
                        </div>
                      </div>
                      {Q > 0 &&<div className="col-lg-6 col-md-12">
                        <div className="price-panel">
                          {unitCost && <div className="unitprice">
                            <div className="d-flex justify-content-end ">
                              <span className="me-3 mt-2">Unit Price</span>
                              <p>{Q >= 50 ? "$ " + unitCost : ""}</p>
                            </div>
                          </div>}
                          {estimatedCost &&<div className="subtotal">
                            <div className="d-flex justify-content-end ">
                              <span className="me-3">Subtotal</span>
                              <p>{Q >= 50 ? "$ " +estimatedCost : ""}</p>
                            </div>
                          </div>}
                        </div>
                      </div>}
                    </div>
                   
                    <div>{errorMessage}</div>
                    <div className="row mx-2 mt-3 px-2 g-1">
                      <div className="col-lg-12 col-md-12">
                        <div className="text-light turnaround-panel">
                          <div className="turnaround">Turnaround Time</div>
                            <div className="shipping-check">
                              <div className="form-check">
                                <input class="form-check-input" type="radio" name="shippingOption" value="Standard (10-12 Business Days)"  onChange={handleStandardChange} checked={standard} required/>
                                <label class="form-check-label" for="Standard (10-12 Business Days)">
                                  Standard (10-12 Business Days)
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="shippingOption" value="Rush (5-6 Business Days)" onChange={handleRushChange} required/>
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Rush (5-6 Business Days)
                                </label>
                              </div>
                            </div>
                        </div>
                        <p className="design-s">We Offer Free Design Support</p>
                      </div>
                     
                    </div>
                    <div className="mx-3 mt-1 submit-btn-wrapper">
                      <button onClick={submit}>
                        Order now and Receive Dieline
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default MailerBoxForm