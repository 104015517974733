import React, { useContext, useEffect, useState } from 'react'
import './UserDashboard.css';
import { TailSpin } from "react-loader-spinner";
import OrderComponent from './components/OrderComponent';
import DesignComponent from './components/DesignComponent';
import DetailComponent from './components/DetailComponent';
import ReforestationComponent from './components/ReforestationComponent';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const UserDashboard = () => {
  const [userDetails, setUserdetails] = useState()
  const [orderDetails, setOrderdetails] = useState()
  const [loader, setLoader] = useState(false)

  // const [user, setUser] = useState(()=> localStorage.getItem("currentloginUser"));
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();
  
  useEffect(()=>{
    const fetchCurrentUserDetails = async () => {
      try {
        setLoader(true)
        // Make a request to the /current-user endpoint
        const response = await fetch('https://boxlane-61739ddb4424.herokuapp.com/api/v1/current-user', {
          method: 'GET',
          credentials: "include",
        });

        if (!response.ok) {
          setLoader(false);
          throw new Error('Failed to fetch user details');
        }
        // console.log("response",response)
        // Parse the response JSON
        const data = await response.json();

        console.log("dashboard",data)
        setUserdetails(data.user);
        setLoader(false)
      } catch (error) {
        console.log("error",error)
        setLoader(false)
      }
    };

    const fetchOrderDetails = async () => {
      try {
        // Make a request to the /current-user endpoint
        const response = await fetch('https://boxlane-61739ddb4424.herokuapp.com/api/v1/getorders', {
          method: 'GET',
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Add the JWT token stored in localStorage
          //   'Content-Type': 'application/json',
          // },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }
        // console.log("response",response)
        // Parse the response JSON
        const data = await response.json();

        console.log("data",data)
        setOrderdetails(data);
      } catch (error) {
        console.log("error",error)
      }
    };

    // Call the function to fetch user details
    fetchCurrentUserDetails();
    fetchOrderDetails();

    // if (!user) {
    //   // If user is not available, navigate to /product-boxes
    //   navigate("/product-boxes");
    //   return;
    // }
  },[])

  return (
    <>
    
    {user ?
      <section class="order-tabs my-5">
        <div class="container">
            <ul class="nav nav-tabs d-flex justify-content-center">
            <li class="nav-item item1 btn">
                <a class="nav-link active" data-bs-toggle="tab" href="#order">Order</a>
            </li>
            <li class="nav-item item2 btn">
                <a class="nav-link" data-bs-toggle="tab" href="#design">Saved Orders</a>
            </li>
            <li class="nav-item item3 btn">
                <a class="nav-link" data-bs-toggle="tab" href="#details">Personal Details</a>
            </li>
            {/* <li class="nav-item item4 btn">
                <a class="nav-link" data-bs-toggle="tab" href="#reforestation">Suppot Reforestation</a>
            </li> */}
            </ul>
        </div>        
        {
          loader ?
          <div style={{position:'relative'}}>
            <div style={{textAlign:'center'}}><br/>
            <Spinner/>
            </div>
          </div>
          :
          <div class="tab-content">
            <div id="order" class="container tab-pane active"><br/>
               {orderDetails && <OrderComponent orderDetails={orderDetails}/>}
            </div>
          <div id="design" class="container tab-pane fade"><br/>
            {orderDetails && <DesignComponent orderDetails={orderDetails} setOrderdetails={setOrderdetails}/>}
          </div>
          <div id="details" class="container tab-pane fade"><br/>
            {userDetails && <DetailComponent userDetails={userDetails}/>}
          </div>
          {/* <div id="reforestation" class="container tab-pane fade"><br/>
          <ReforestationComponent/>
          </div> */}
        </div>}
    </section>
    
    :
    <div style={{position:'relative'}}>
    <div style={{position: 'absolute', top: '20%', left: "50%", transform: 'translate(-20%,-50%)'}}><br/>
    <Spinner/>
    </div>
  </div>
    }
    </>
  )
}

export default UserDashboard