import React, { useContext, useState } from 'react'
// import straighttuckendboxes from '../../assets/dashboard_images/straight-tuck-end-boxes.png';
// import straighttuckendboxes2 from '../../assets/dashboard_images/straight-tuck-end-boxes2.png';
import { SelectedOptionContext } from '../../../Utils/Formdata';
import { useNavigate } from 'react-router-dom';
import '../UserDashboard.css';
import AlertComponent from '../../../Components/AlertComponent/AlertComponent';
import useAlert from '../../../hooks/useAlert/useAlert';
import Infopopup from './Infopopup';


const DesignComponent = (props) => {
    console.log("props",props)
    const { paymentmoadal , setPaymentmoadal } = useContext(SelectedOptionContext);
    const { updateorder, setUpdateorder } = useContext(SelectedOptionContext);

    const { quantity, setQuantity } = useContext(SelectedOptionContext);
    const { firstName, setFirstName } = useContext(SelectedOptionContext); //length
    const { secondName, setSecondName} = useContext(SelectedOptionContext); //width
    const {  material , setMaterial} = useContext(SelectedOptionContext);
    const { paper , setPaper } = useContext(SelectedOptionContext);
    const { slides, setSlides } = useContext(SelectedOptionContext);
    const { time, setTime } = useContext(SelectedOptionContext);

    const { estimatedCost, setEstimatedCost } = useContext(SelectedOptionContext);
    const { unitprice, setUnitPrice } = useContext(SelectedOptionContext);
    const { boxname , setSetboxname } = useContext(SelectedOptionContext);
    const { selectedOption, setSelectedOption } = useContext(SelectedOptionContext);


    const { alertmsg, showAlert, hideAlert } = useAlert();

    const orderDetailsCopy = [...props.orderDetails];
    // Check if there are unpaid orders
    // const hasUnpaidOrders = orderDetailsCopy.every(order => order.payment === "not-paid");
    const unpaidOrders = orderDetailsCopy.filter(order => order.payment === "not-paid");


    const navigate = useNavigate();

    const gotopayment = (order) => {
        // console.log("id",id)
        if(order.boxtype === 'Product'){
            setSetboxname(order.boxtype)
            // setSecondName(order.width)
            // setFirstName(order.length)
            setSelectedOption(order.length+' x '+order.width+' x '+order.depth)

            setQuantity(order.quantity)
            setMaterial(order.Coating)
            setPaper(order.paper)
            setSlides(order.printerSides)
            setTime(order.turnaroundTime)
            setEstimatedCost(order.totalPrice)
            setUnitPrice(order.pricePerItem)
        }else{
            setSetboxname(order.boxtype)
            // setSecondName(order.width)
            // setFirstName(order.length)
            setSelectedOption(order.length+' x '+order.width+' x '+order.depth)
            setQuantity(order.quantity)
            setMaterial(order.material)
            setSlides(order.printerSides)
            setTime(order.turnaroundTime)
            setEstimatedCost(order.totalPrice)
            setUnitPrice(order.pricePerItem)
        }
        setUpdateorder(order._id);
        setPaymentmoadal(true);
        navigate('/shipping-address')
    }

    const deleteOrder = async(id) => {
      hideAlert();

        try {
            const response = await fetch(`https://boxlane-61739ddb4424.herokuapp.com/api/v1/deleteorder/${id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: "include",
            });

            // console.log("response",response)
        
            if (!response.ok) {
              throw new Error('Failed to delete order');
            }
        
            props.setOrderdetails(props.orderDetails.filter(order => order._id !== id));
            showAlert("success", "Order Deleted successfully");
            // Order deleted successfully, update UI or fetch orders again to reflect changes
            // For example, you can call a function to fetch orders again
            // fetchOrders(); // Assuming fetchOrders() is a function to fetch orders and update UI
          } catch (error) {
            console.error('Error deleting order:', error.message);
            // Handle error, show error message to the user, etc.
          }
    }
    
    const [selectedOrder, setSelectedOrder] = useState(null);

    const handleViewDetail = (order) => {
        setSelectedOrder(order);
    };

    const handleClosePopup = () => {
        setSelectedOrder(null);
    };

  return (
    <>
    <AlertComponent
          type={alertmsg.type}
          message={alertmsg.message}
          visible={alertmsg.visible}
          onClose={hideAlert}
        />
    <div>
        {unpaidOrders.length > 0 ?
         <div className="row">
         <div className="col">
             <div className="">
                 <div className="card-header">
                 <h3>All Orders</h3>
                 </div>
                 <div className="card">
                     <table className="table">
                         <thead>
                             <tr>
                                 <th  style={{textAlign:'center'}}>#</th>
                                 <th  style={{textAlign:'center'}}>Box Style</th>
                                 <th>Status</th>
                                 <th>Payment</th>
                                 <th>Product Name</th>
                                 <th>Order Date</th>
                                 <th>Actions</th>
                             </tr>
                         </thead>
                         <tbody>
                             {unpaidOrders.map((order, index) => (
                                 <tr key={index}>
                                     <th style={{textAlign:'center'}}>{index+1}</th>
                                     <td>
                                         <div class="order-item">
                                     {order.boxtype === "Product" &&<img src="https://theboxlane.com/products/assets/product-images/Tuck-Boxes/2-tuck-box.png" alt=""/>}
                                     {order.boxtype === "Mailer" &&<img src="https://theboxlane.com/products/assets/product-images/Shipping-and-mailer-boxes/Mailer-Boxes-2.png" alt=""/>}
                                     {order.boxtype === "Magnetic" &&<img src="https://theboxlane.com/assets/web-images/magnetic-closure-rigid-boxes/1_magnetic_closure_rigid_boxes.webp" alt=""/>}
                                     {order.boxtype === "mylarbag" &&<img src="https://theboxlane.com/assets/web-images/ediblemylarbags/1_edibles_mylar_bags.webp" alt=""/>}
                                 </div></td>
                                 <td style={{textTransform: 'uppercase'}}>{order.status}</td>
                                     <td style={{textTransform: 'uppercase'}}>{order.payment}</td>
                                     <td style={{textTransform: 'uppercase'}}>{order.boxtype}</td>
                                     <td>{new Date(order.createdAt).toLocaleDateString('en-US')}</td>
                                     <td>
                                         <button style={{backgroundColor:'#635675',color:'#ffffff'}} className="btn btn-sm mx-2" onClick={() => handleViewDetail(order)}>View Detail</button>
                                         <button className="btn btn-sm btn-yellow-table mx-2" onClick={() => gotopayment(order)}>Pay Now</button>
                                         <button style={{color:'#ffffff'}} className="btn btn-sm btn-danger mx-2" onClick={() => deleteOrder(order._id)}>Delete It</button>
                                     </td>
                                     
                                 </tr>
                             ))}
                         </tbody>
                     </table>
                 </div>
             </div>
         </div>
          </div>
           :
           <div class="row w-100 justify-content-center mx-0">
               <div class="col-12 col-md-7 col-lg-6 col-xl-5 border py-3 mt-3">
                   <h3 class="text-center">You have no saved Design yet</h3>
                   <div class="d-flex justify-content-center shopnow">
                    {/* <span>Shop now</span> */}
                    <a href="https://theboxlane.com/">Shop now</a>
                    </div>
               </div>
           </div>}
           {selectedOrder &&
                <Infopopup order={selectedOrder} onClose={handleClosePopup} />
            }
    </div>
    </>
  )
}

export default DesignComponent