import React, {useContext, useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/style.css'
import '../../assets/css/menu.css'
import '../../assets/css/responsive.css'
import Main from '../signup-login/Main';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageSlice, setShowpopup } from '../../Redux/Slices/LoginSlice';
import AlertComponent from '../AlertComponent/AlertComponent';
import useAlert from '../../hooks/useAlert/useAlert';
import { AuthContext } from '../../context/AuthContext';
import Cookies from "js-cookie";

const Navbar = () => {
    // const [user, setUser] = useState(()=> localStorage.getItem("currentloginUser"));
    // const [user, setUser] = useState(() => {
    //     const storedUser = localStorage.getItem("currentloginUser");
    //     return storedUser ? JSON.stringify(storedUser) : null;
    //   });

    // const [user, setUser] = useState(()=> JSON.parse(Cookies.get("currentloginUserboxlane")));

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { alertmsg, showAlert, hideAlert } = useAlert();

    // const [show, setShow] = useState(false);
    
    const { showpopup } = useSelector((state) => state.loginpopup);

    const { messageSlice } = useSelector((state) => state.loginpopup);

    useEffect(() => {
        // const currentuser = localStorage.getItem("currentloginUser");

        // const cookieUser = Cookies.get("currentloginUserboxlane");
        // const currentuser = JSON.parse(cookieUser)
        // console.log("cookieUser",cookieUser)
        // setUser(currentuser);
        
       
      }, [showpopup]); // Update user state when showpopup changes

    //   console.log("user",JSON.parse(user))

      useEffect(() => {
        // console.log("user",user)
        let alertShown = false;
    
        if (messageSlice && messageSlice === 'Login') {
            showAlert("success", "Login Succesfully");
            alertShown = true;
        }
    
        return () => {
            if (alertShown && messageSlice) {
                dispatch(setMessageSlice(""));
                alertShown = false;
            }
        };
    }, [messageSlice, dispatch, showAlert]);
    
const handleLogout = () => {
    // localStorage.removeItem("currentloginUserboxlane")
    Cookies.remove("currentloginUserboxlane");  
    // localStorage.removeItem("currentloginUser")
    window.location.reload();
}
  return (
    <>

<AlertComponent
          type={alertmsg.type}
          message={alertmsg.message}
          visible={alertmsg.visible}
          onClose={hideAlert}
        />
    <div className="white-header">
    
      <div className="back-to-top-button">
            <button>
                <i className="fas fa-level-up-alt"></i>
            </button>
        </div>
        <div className="upper-header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="upper-header-content">
                        <p>Eligible orders produced in 10 business days or less 📦 <a href="#">Save 20% On Your First Order +</a></p>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <div className="header menu-increase">
        
            <div className="container">
                <div className="header-bottom">
                    <div className="row">
                        <div className="col-xl-2 col-lg-2 d-xl-flex d-lg-flex align-items-center">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-6 d-xl-block d-lg-block d-flex align-items-center">
                                    <div className="logo">
                                        <a href="/">
                                            <img style={{ width: '180px' }} src="https://theboxlane.com/assets/images/logo-black.webp" alt="The Box lane"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-6 d-xl-none d-lg-none d-block">
                                    <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="fas fa-bars"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-10 d-flex align-items-center">
                            <div className="main-menu">
                                <nav className="navbar navbar-expand-lg">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav ml-auto">
                                            <li className="nav-item dropdown drop-up-1">
                                                <a className="nav-link dropdown-toggle d-none d-lg-block"  id="megaMenu" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Box By Style <i className="drop-toggle float-end"></i>
                                                </a>
                                                <div className="dropdown-menu mega-menu menu-position-style" style={{width: '1000px',borderRadius: '15px',boxShadow: '0px 5px 23px 12px #0000000A',}} aria-labelledby="megaMenu">
                                                    <div className="container">
                                                        <div className="row w-100">
                                                            <div className="col-md-4 pt-5 ps-0 ms-0">
                                                                <p className="ps-2 pb-4">BOX BY STYLE</p>
                                                                <ul className="list-unstyled w-100">
                                                                   <li className="dropdown-item py-0 my-0 ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-1.svg" className="img-fluid " alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-window-box-packaging/">Window Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-9.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-label-and-stickers/">Labels & Stickers</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-2.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/customized-mylar-bags/">Mylar Bags</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-15.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-magnetic-boxes/">Magnetic Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-11.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-hang-tab-boxes/">Hang Tab Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-12.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-pillow-boxes/">Pillow Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-13.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-hexagon-boxes/">Hexagon Boxes</a></li>
                                                                    <li className="dropdown-item ps-2"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-5.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item" href="https://theboxlane.com/custom-two-piece-boxes/">Two Piece Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 pt-5 ">
                                                                <ul className="list-unstyled w-100" style={{ paddingTop: '40px' }}>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-3.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-tuck-boxes/">Tuck Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-4.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-display-packaging-boxes/">Custom Display Boxes</a></li>

                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-14.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-packaging-sleeves/">Packaging Sleeves</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-6.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-cigarette-boxes/">Cigarette Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-7.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-gable-boxes/">Gable Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-8.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-dispenser-boxes/">Dispenser Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets\images\menu-box-by-style\menu-a-8.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-tray-and-sleeves-boxes/">Tray and Sleeves</a></li>

                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 pt-5 ">
                                                                <ul className="list-unstyled w-100">
                                                                    <li><form><input type="search" placeholder="Search" className="form-control"/></form><button className="btn form-control btn-yellow px-0 py-0">Search</button></li>
                                                                    <li><img src="https://theboxlane.com/assets\images\menu-box-by-style\Rectangle-18794.png" className="Ã¯mg-fluid img-tools" alt=""/></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link d-block d-lg-none" role="button" data-bs-toggle="collapse" data-bs-target="#collapse1">
                                                    Box By Style <i className="drop-toggle float-end"></i>
                                                </a>
                                                <div className="collapse mini-menu d-lg-none" id="collapse1">
                                                    <div className="container">
                                                    <p className="py-3">BOX BY STYLE</p>
                                                        <div className="row w-100">
                                                            <div className="col-6 pb-5 border-end border-bottom">
                                                                <ul className="w-100">
                                                                     <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-indow-box-packaging/">Window Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-label-and-stickers/">Labels & Stickers</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/customized-mylar-bags/">Mylar Bags</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-magnetic-boxes/">Magnetic Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-hang-tab-boxes/">Hang Tab Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-pillow-boxes/">Pillow Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-hexagon-boxes/">Hexagon Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-two-piece-boxes/">Two Piece Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-6 pb-5 border-bottom">
                                                                <ul className="w-100">
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-tuck-boxes/">Tuck Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-display-packaging-boxes/">Custom Display Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-magnetic-boxes/">Magnetic Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-packaging-sleeves/">Packaging Sleeves</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-cigarette-boxes/">Cigarette Boxes</a></li>
                                                                    <li><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-gable-boxes/">Gable Boxes</a></li>
                                                                    <li><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-dispenser-boxes/">Dispenser Boxes</a></li>
                                                                      <li><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-tray-and-sleeves-boxes/">Tray and Sleeves</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item dropdown drop-up-2">
                                                <a className="nav-link dropdown-toggle d-none d-lg-block"  id="megaMenu" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Box By Industry <i className="drop-toggle float-end"></i>
                                                </a>
                                                <div className="dropdown-menu mega-menu menu-position-style-1" style={{width: '1000px',borderRadius: '15px',boxShadow: '0px 5px 23px 12px #0000000A',}} aria-labelledby="megaMenu">
                                                    <div className="container">
                                                        <div className="row w-100">
                                                            <div className="col-md-4 pt-5 ps-0 ms-0">
                                                                <p className="ps-2 pb-4">BOX BY INDUSTRY</p>
                                                                <ul className="list-unstyled w-100">
                                                                    <li className="dropdown-item py-0 my-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/fa-solid_cannabis.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-cbd-boxes/">Custom CBD Packaging</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/emojione-monotone_womans-clothes.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-apparel-boxes/">Apparel & Fashion Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/Vector.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-candle-boxes/">Candle Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/map_jewelry-store.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-jewelry-boxes/">Jewelry Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/akar-icons_vape-kit.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-vape-boxes/">Custom Vape Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/ph_hand-soap-fill.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-soap-boxes/">Custom Soap Boxes</a></li>
                                                                    <li className="dropdown-item ps-2 mb-0 pb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/mdi_food.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item mb-0 pb-0" href="https://theboxlane.com/custom-food-packaging/">Food Boxes</a></li>
                                                                    <li className="dropdown-item ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/tabler_gift-filled.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item" href="https://theboxlane.com/custom-printed-gift-boxes/">Gift Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 pt-5 ">
                                                                <ul className="list-unstyled w-100" style={{paddingTop: '40px'}}>
                                                                                              <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/mdi_bakery.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-bakery-boxes/">Bakery Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/fluent-emoji-high-contrast_chocolate-bar.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-chocolate-boxes/">Chocolate Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/solar_cosmetic-bold.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-cosmetic-boxes/">Beauty Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/material-symbols_box-sharp.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-retail-packaging-boxes/">Retail Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/pre-roll-box.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-pre-roll-packaging/">Pre Roll Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/icon-park-solid_christmas-tree.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-christmas-boxes/">Christmas Boxes</a></li>
                                                                    <li className="dropdown-item"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/dried-freeze-pack.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item" href="https://theboxlane.com/custom-freeze-dried-packaging/">Dried Freeze Packaging</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 pt-5 ">
                                                                <ul className="list-unstyled w-100">
                                                                      <li><form><input type="search" placeholder="Search" className="form-control"/></form><button className="btn form-control btn-yellow px-0 py-0">Search</button></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/game-icons_hemp.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-hemp-boxes/">Hemp Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/clarity_book-solid.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-stationery-packaging-boxes/">Stationary Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/fluent_sport-american-football-24-filled.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-sports-packaging-boxes/">Sports Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/incense-boxes.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/incense-packaging/">Incense Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/ri_shopping-bag-fill.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-mailer-boxes/">Shipping & Mailer Boxes</a></li>
                                                                <li className="dropdown-item pb-0 mb-0 ps-0"><img src="https://theboxlane.com/assets/images/menu-box-by-industry/ri_shopping-bag-fill.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-healthcare-packaging-boxes/">Healthcare Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link d-block d-lg-none" role="button" data-bs-toggle="collapse" data-bs-target="#collapse2">
                                                    Box By Industry <i className="drop-toggle float-end"></i>
                                                </a>
                                                <div className="collapse mini-menu d-lg-none" id="collapse2">
                                                    <div className="container">
                                                    <p className="py-3">BOX BY INDUSTRY</p>
                                                        <div className="row w-100">
                                                            <div className="col-6 pb-5 border-end border-bottom">
                                                                <ul className="w-100">
                                                                     <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-cbd-boxes/">Custom CBD Packaging</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-apparel-boxes/">Apparel & Fashion Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-candle-boxes/">Candle Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-jewelry-boxes/">Jewelry Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-vape-boxes/">Custom Vape Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-soap-boxes/">Custom Soap Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-food-packaging/">Food Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-printed-gift-boxes/">Gift Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-6 pb-5 border-bottom">
                                                                <ul className="w-100">
                                                                       <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-bakery-boxes/">Bakery Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-chocolate-boxes/">Chocolate Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-cosmetic-boxes/">Beauty Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-retail-packaging-boxes/">Retail Boxes</a></li>
                                                                    <li><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-pre-roll-packaging/">Pre Roll Boxes</a></li>
                                                                    <li><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-christmas-boxes/">Christmas Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-freeze-dried-packaging/">Dried Freeze Packaging</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-6 pb-5 border-bottom">
                                                                <ul className="w-100">
                                                                   <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-hemp-boxes/">Hemp Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-stationery-packaging-boxes/">Stationary Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-sports-packaging-boxes/">Sports Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/incense-packaging/">Incense Boxes</a></li>
                                                                    <li><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-mailer-boxes/">Shipping & Mailer Boxes</a></li>
                                                                    <li><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-healthcare-packaging-boxes/">Healthcare Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item dropdown drop-up-3">
                                                <a className="nav-link dropdown-toggle d-none d-lg-block " id="megaMenu" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Box By Materials <i className="drop-toggle float-end"></i>
                                                </a>
                                                <div className="dropdown-menu mega-menu menu-position-style-3" style={{width: '1000px',borderRadius: '15px',boxShadow: '0px 5px 23px 12px #0000000A',}} aria-labelledby="megaMenu">
                                                    <div className="container">
                                                        <div className="row w-100">
                                                            <div className="col-md-4 pt-5 ps-0 ms-0">
                                                                <p className="ps-2 pb-4">BOX BY MATERIALS</p>
                                                                <ul className="list-unstyled w-100">
                                                                     <li className="dropdown-item py-0 my-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-materials/kraft-boxes.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-kraft-boxes/">Kraft Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0 ps-2"><img src="https://theboxlane.com/assets/images/menu-box-by-materials/rigid-boxes.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-rigid-boxes/">Rigid Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 pt-5 ">
                                                                <ul className="list-unstyled w-100" style={{paddingTop: '40px'}}>
                                                                       <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-materials/cardboard-boxes.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-cardboard-boxes/">Cardboard Boxes</a></li>
                                                                    <li className="dropdown-item pb-0 mb-0"><img src="https://theboxlane.com/assets/images/menu-box-by-materials/cardboard-boxes.svg" className="img-fluid" alt=""/><a className="ps-2 dropdown-item pb-0 mb-0" href="https://theboxlane.com/custom-printed-corrugated-boxes/">Corrugated Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-4 pt-5 ">
                                                                <ul className="list-unstyled w-100 pb-5">
                                                                    <li><form><input type="search" placeholder="Search" className="form-control"/></form><button className="btn form-control btn-yellow px-0 py-0">Search</button></li>
                                                                    <li className="img-tools"><img src="https://theboxlane.com/assets\images\menu-box-by-materials\Rectangle-18801.svg" className="Ã¯mg-fluid " alt=""/>
                                                                    <img src="https://theboxlane.com/assets\images\menu-box-by-materials\Rectangle-18802.svg" className="Ã¯mg-fluid " alt=""/></li>
                                                                    <li>
                                                                    <img src="https://theboxlane.com/assets\images\menu-box-by-materials\Rectangle-18803.svg" className="Ã¯mg-fluid " alt=""/>
                                                                    <img src="https://theboxlane.com/assets\images\menu-box-by-materials\Rectangle-18804.svg" className="Ã¯mg-fluid " alt=""/></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link d-block d-lg-none " role="button" data-bs-toggle="collapse" data-bs-target="#collapse3">
                                                    Box By Materials <i className="drop-toggle float-end"></i>
                                                </a>
                                                <div className="collapse mini-menu d-lg-none" id="collapse3">
                                                    <div className="container">
                                                    <p className="py-3">BOX BY MATERIALS</p>
                                                        <div className="row w-100">
                                                            <div className="col-6 pb-5 border-end border-bottom">
                                                                <ul className="w-100">
                                                                   <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-kraft-boxes/">Kraft Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a  href="https://theboxlane.com/custom-rigid-boxes/">Rigid Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-6 pb-5 border-bottom">
                                                                <ul className="w-100">
                                                               <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-cardboard-boxes/">Cardboard Boxes</a></li>
                                                                    <li ><i className="fa fa-arrow-right"></i><a href="https://theboxlane.com/custom-printed-corrugated-boxes/">Corrugated Boxes</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link scroll-link" href="https://theboxlane.com/eco-friendly-packaging-boxes/">
                                                   Eco-Friendly Materials
                                                </a>
                                            </li>
                                           
                                            <li>
                                                <div className="row w-100">
                                                    <div className="pt-3 pb-5 col-12 d-block d-lg-none">
                                                        <div className="d-flex div-btn"><form className="w-75"><input type="search" placeholder="Search" className="form-control w-100"/></form><button className="btn form-control btn-yellow w-25 px-0">Search</button></div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="header-contact-info d-lg-flex align-items-end mobile-version">
                                            <ul>
                                                <li className="call-header"><span>Call Us Toll Free</span><br/><a href="tel:484-224-2666">
                                                <i className="fa fa-solid fa-phone"></i>484-224-2666
                                                </a></li>
                                                <li className="email-header"><span>Email Address</span><br/><a href="mailto:info@theboxlane.com">
                                                <i className="fa fa-solid fa-envelope"></i>info@theboxlane.com
                                                </a></li>
                                            </ul>
                                        </div>
                                        <div className="header-social mobile-version">
                                            <span>Letâ€™s Connect!</span><br/>
                                            <ul>
                                                <li><a target="_blank" href="https://www.pinterest.com/officialtheboxlane/"><i className="fab fa-pinterest"></i></a></li>
                                                <li><a target="_blank" href="https://www.youtube.com/@TheBoxLane"><i className="fab fa-youtube"></i></a></li>
                                                <li><a target="_blank" href="https://www.facebook.com/theboxlane"><i className="fab fa-facebook"></i></a></li>
                                                <li><a target="_blank" href="https://www.instagram.com/theboxlane/"><i className="fab fa-instagram"></i></a></li>
                                                <li><a target="_blank" href="https://www.linkedin.com/company/87438685/"><i className="fab fa-linkedin"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-2 col-md-12 col-sm-12">
                            <div className="row">
                                {/* <div className="col-lg-6">
                                    <div className="header-contact-info d-none d-lg-flex align-items-end desktop-version">
                                        <ul>
                                            <li className="call-header"><span>Call Us Toll Free</span><br/>
                                                <a href="tel:484-224-2666">
                                                    <i className="fa fa-solid fa-phone"></i>484-224-2666
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                                <div className="col-lg-12">
                                    <div className="header-contact-info d-lg-flex align-items-end justify-content-end">
                                        <ul>
                                           {!user ? <li className="login" onClick={() => dispatch(setShowpopup(true))}>
                                                <span>
                                                    Sign in <i className="fa fa-sign-in-alt"></i>
                                                </span>
                                            </li>
                                            :
                                            <li className="user-login">
                                            <DropdownButton id="dropdown-basic-button" title={<>
                                                    <img src="https://theboxlane.com/assets/images/user-3d.png"  alt=""/>
                                                    <div className="">
                                                        <span className="welcome-text">Welcome</span>
                                                        <span className="username">{user.firstName}</span>
                                                    </div>
                                                </>}
                                                // localStorage.getItem("boxlaneloginuser")
                                                >
                                                <Dropdown.Item onClick={()=> navigate('/dashboard')}>Your Order</Dropdown.Item>
                                                <Dropdown.Item onClick={()=> navigate('/account-setting')}>Account Setting</Dropdown.Item>
                                                <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
                                                </DropdownButton>
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         {/* header end */}
         <Main show={showpopup}/>
    </div>
    </>
  );
};

export default Navbar;
