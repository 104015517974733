import React from "react";
import { Button, Modal } from "react-bootstrap";

const Infopopup = ({ order, onClose }) => {
  return (
    <div>
      <Modal
        show={true}
        onHide={onClose}
        size="md"
        centered
        className="signup-modal"
      >
        {/* <Modal.Header closeButton>
    </Modal.Header> */}
        <Modal.Body>
          <div className="row">
            <div className="col">
              <div className="">
                <div className="card-header">
                  <h3>Order Details</h3>
                </div>
                <div className="card">
                  <table className="table table-striped">
                    <tbody>
                      {order.status && (
                        <tr>
                          <th>Status</th>
                          <td style={{textTransform: 'uppercase'}}>{order.status}</td>
                        </tr>
                      )}
                      {order.payment && (
                        <tr>
                          <th>Payment</th>
                          <td style={{textTransform: 'uppercase'}}>{order.payment}</td>
                        </tr>
                      )}
                      {order.boxtype && (
                        <tr>
                          <th>Box Type</th>
                          <td style={{textTransform: 'uppercase'}}>{order.boxtype}</td>
                        </tr>
                      )}
                      {order.length && (
                        <tr>
                          <th>Length</th>
                          <td>{order.length}</td>
                        </tr>
                      )}
                      {order.width && (
                        <tr>
                          <th>Width</th>
                          <td>{order.width}</td>
                        </tr>
                      )}
                      {order.depth && (
                        <tr>
                          <th>Height</th>
                          <td>{order.depth}</td>
                        </tr>
                      )}
                      {order.paper && (
                        <tr>
                          <th>Paper</th>
                          <td>{order.paper}</td>
                        </tr>
                      )}
                      {order.material && (
                        <tr>
                          <th>Material</th>
                          <td>{order.material}</td>
                        </tr>
                      )}
                      {order.Coating && (
                        <tr>
                          <th>Coating</th>
                          <td>{order.Coating}</td>
                        </tr>
                      )}
                      {order.printerSides && (
                        <tr>
                          <th>Printed Sides</th>
                          <td>{order.printerSides}</td>
                        </tr>
                      )}
                      {order.quantity && (
                        <tr>
                          <th>Quantity</th>
                          <td>{order.quantity}</td>
                        </tr>
                      )}
                      {order.turnaroundTime && (
                        <tr>
                          <th>Turn Around Time</th>
                          <td>{order.turnaroundTime}</td>
                        </tr>
                      )}
                      {order.pricePerItem && (
                        <tr>
                          <th>Price (each)</th>
                          <td>${order.pricePerItem}</td>
                        </tr>
                      )}
                      {order.totalPrice && (
                        <tr>
                          <th>Price (subtotal)</th>
                          <td>${order.totalPrice}</td>
                        </tr>
                      )}
                      {order.createdAt && (
                        <tr>
                          <th>Order Date</th>
                          <td>{new Date(order.createdAt).toLocaleString()}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Infopopup;
