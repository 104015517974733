import React, { useState } from 'react'
import useAlert from '../../../hooks/useAlert/useAlert';
import AlertComponent from '../../AlertComponent/AlertComponent';
import { Spinner } from 'react-bootstrap';

const Signup = (props) => {

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [signuperror, setSignuperror] = useState(null);

  const [loader, serLoader] = useState(false);

  const [verificationpopup, setVerificationpopup] = useState(false);
  const [code, setCode] = useState("");

  const { alertmsg, showAlert, hideAlert } = useAlert();

  const handleSignup = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    // Validate the form fields
    if (!userData.firstName || !userData.lastName || !userData.email || !userData.password) {
      console.error('All fields are required');
      showAlert("info", "All Fields Must be Filled");
      return;
    }
    serLoader(true)
    // Validate email using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userData.email)) {
      console.error('Invalid email format');
      return;
    }
    
    // Display a loading indicator or disable the submit button
    // (Add code for displaying/loading indicator as needed)
    // https://boxlane-61739ddb4424.herokuapp.com
    try {
      const response = await fetch('https://boxlane-61739ddb4424.herokuapp.com/api/v1/registration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
  
      const data = await response.json();
      if (response.ok) {
        console.log("data",data)

        console.log(data.message);
        // Update the UI with a success message or redirect to the login page
        // if(response.status === 201){
        //   props.setSigninsection(true)
        //   }
        serLoader(false)
        setVerificationpopup(true)
      } else {
        console.error('Registration failed:', data.message);
        setSignuperror(response.status)
        // Update the UI with an error message
      }
    } catch (error) {
      console.error('Error during registration:', error.message);
      // Update the UI with an error message
    } finally {
      // Remove the loading indicator or enable the submit button
      // (Add code to remove the loading indicator as needed)
    }
  };
  
  const handleVerificationSignup = async (e) =>{
    e.preventDefault(); // Prevent the default form submission behavior
    serLoader(true)
    // Display a loading indicator or disable the submit button
    // (Add code for displaying/loading indicator as needed)
    // https://boxlane-61739ddb4424.herokuapp.com
    const details = {
      firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    password: userData.password,
    code: code
    }
    try {
      // https://boxlane-61739ddb4424.herokuapp.com
      const response = await fetch('https://boxlane-61739ddb4424.herokuapp.com/api/v1/registration-verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(details),
      });
  
      const data = await response.json();
      if (response.ok) {
        console.log("data",data)

        console.log(data.message);
        // Update the UI with a success message or redirect to the login page
        if(response.status === 200){
          // props.setSigninsection(true)
          props.setSigninsection('Signin')
          }
        setVerificationpopup(false)
      } else {
        console.error('Registration failed:', data.message);
        showAlert("failure", "Invalid verification code");
        setSignuperror(response.status)
        serLoader(false)
        // Update the UI with an error message
      }
    } catch (error) {
      serLoader(false)
      console.error('Error during registration:', error.message);
      // Update the UI with an error message
    } finally {
      // Remove the loading indicator or enable the submit button
      // (Add code to remove the loading indicator as needed)
      
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setSignuperror(null)
  };

  return (
    <>
    <AlertComponent
          type={alertmsg.type}
          message={alertmsg.message}
          visible={alertmsg.visible}
          onClose={hideAlert}
        />
    <div class="col-lg-6">

              {!verificationpopup ? 
              <div className="singup-wrapper">
                <form action="" className="row">
                  <div className="col-lg-6">
                    <label>First Name</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                        <svg width="20" height="18" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.475 18.8875L19.775 20.3125C20.0593 21.5445 19.7786 22.8391 19.0096 23.8427C18.2405 24.8463 17.0635 25.4541 15.8 25.5H4.2C2.93646 25.4541 1.75946 24.8463 0.990429 23.8427C0.221403 22.8391 -0.0592962 21.5445 0.224996 20.3125L0.524996 18.8875C0.87004 16.9585 2.52827 15.5409 4.4875 15.5H15.5125C17.4717 15.5409 19.13 16.9585 19.475 18.8875ZM15.8 23.6125C16.4347 23.6052 17.0321 23.3111 17.425 22.8125V22.825C17.9071 22.2203 18.0949 21.4323 17.9375 20.675L17.6375 19.25C17.471 18.194 16.5808 17.4033 15.5125 17.3625H4.4875C3.41921 17.4033 2.52895 18.194 2.3625 19.25L2.0625 20.675C1.90894 21.4283 2.09658 22.2108 2.575 22.8125C2.96792 23.3111 3.56526 23.6052 4.2 23.6125H15.8Z" fill="#666666"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.625 13H9.37498C6.61355 13 4.37498 10.7614 4.37498 8.00002V4.70002C4.37164 3.58509 4.81307 2.51487 5.60145 1.72649C6.38983 0.938114 7.46005 0.496686 8.57498 0.500019H11.425C12.5399 0.496686 13.6101 0.938114 14.3985 1.72649C15.1869 2.51487 15.6283 3.58509 15.625 4.70002V8.00002C15.625 10.7614 13.3864 13 10.625 13ZM8.57498 2.37502C7.29092 2.37502 6.24998 3.41596 6.24998 4.70002V8.00002C6.24998 9.72591 7.64909 11.125 9.37498 11.125H10.625C12.3509 11.125 13.75 9.72591 13.75 8.00002V4.70002C13.75 4.08339 13.505 3.49202 13.069 3.056C12.633 2.61997 12.0416 2.37502 11.425 2.37502H8.57498Z" fill="#666666"/>
                        </svg>

                        </span>
                      </div>
                      <input type="text" class="form-control" placeholder="First Name" name="firstName" aria-label="First Name" value={userData.firstName} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Last Name</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <svg width="20" height="18" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.475 18.8875L19.775 20.3125C20.0593 21.5445 19.7786 22.8391 19.0096 23.8427C18.2405 24.8463 17.0635 25.4541 15.8 25.5H4.2C2.93646 25.4541 1.75946 24.8463 0.990429 23.8427C0.221403 22.8391 -0.0592962 21.5445 0.224996 20.3125L0.524996 18.8875C0.87004 16.9585 2.52827 15.5409 4.4875 15.5H15.5125C17.4717 15.5409 19.13 16.9585 19.475 18.8875ZM15.8 23.6125C16.4347 23.6052 17.0321 23.3111 17.425 22.8125V22.825C17.9071 22.2203 18.0949 21.4323 17.9375 20.675L17.6375 19.25C17.471 18.194 16.5808 17.4033 15.5125 17.3625H4.4875C3.41921 17.4033 2.52895 18.194 2.3625 19.25L2.0625 20.675C1.90894 21.4283 2.09658 22.2108 2.575 22.8125C2.96792 23.3111 3.56526 23.6052 4.2 23.6125H15.8Z" fill="#666666"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.625 13H9.37498C6.61355 13 4.37498 10.7614 4.37498 8.00002V4.70002C4.37164 3.58509 4.81307 2.51487 5.60145 1.72649C6.38983 0.938114 7.46005 0.496686 8.57498 0.500019H11.425C12.5399 0.496686 13.6101 0.938114 14.3985 1.72649C15.1869 2.51487 15.6283 3.58509 15.625 4.70002V8.00002C15.625 10.7614 13.3864 13 10.625 13ZM8.57498 2.37502C7.29092 2.37502 6.24998 3.41596 6.24998 4.70002V8.00002C6.24998 9.72591 7.64909 11.125 9.37498 11.125H10.625C12.3509 11.125 13.75 9.72591 13.75 8.00002V4.70002C13.75 4.08339 13.505 3.49202 13.069 3.056C12.633 2.61997 12.0416 2.37502 11.425 2.37502H8.57498Z" fill="#666666"/>
                          </svg>
                        </span>
                      </div>
                      <input type="text" class="form-control" placeholder="Last Name" name="lastName" aria-label="Last Name" value={userData.lastName} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label>Email Address</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <svg width="18" height="18" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.505 2.96665L23.4966 8.57832C24.7391 9.45153 25.479 10.8747 25.48 12.3933V20.7117C25.4769 22.0329 24.9491 23.2988 24.0126 24.2308C23.0762 25.1629 21.8079 25.6847 20.4866 25.6817H7.32665C6.00543 25.6847 4.73709 25.1629 3.80066 24.2308C2.86422 23.2988 2.3364 22.0329 2.33331 20.7117V12.3933C2.33431 10.8747 3.07418 9.45153 4.31665 8.57832L12.3083 2.96665C13.2065 2.12212 14.6068 2.12212 15.505 2.96665ZM22.7752 22.9934C23.3834 22.3895 23.7269 21.5687 23.73 20.7117V12.3933C23.7136 11.4628 23.2542 10.5959 22.4933 10.06L14.5016 4.44832L14.3733 4.35498C14.2504 4.22957 14.0822 4.15889 13.9066 4.15889C13.7311 4.15889 13.5629 4.22957 13.44 4.35498L13.3116 4.44832L5.31998 10.06C4.55909 10.5959 4.09965 11.4628 4.08331 12.3933V20.7117C4.0864 21.5687 4.42985 22.3895 5.03809 22.9934C5.64633 23.5972 6.46955 23.9348 7.32665 23.9317H20.4866C21.3437 23.9348 22.167 23.5972 22.7752 22.9934Z" fill="#959697"/>
                          <path d="M20.9883 12.8483L14.525 17.3633C14.3336 17.5547 14.0709 17.6573 13.8005 17.6462C13.5301 17.6351 13.2767 17.5114 13.1016 17.305L6.82498 12.8483C6.57285 12.6303 6.21872 12.5744 5.91172 12.7043C5.60471 12.8341 5.39816 13.1271 5.37903 13.4599C5.35991 13.7927 5.53153 14.1075 5.82164 14.2717L12.005 18.6817C12.512 19.1537 13.1789 19.4163 13.8716 19.4167C14.5232 19.4002 15.1442 19.1375 15.61 18.6817L21.9916 14.225C22.3585 13.9534 22.455 13.4455 22.2133 13.0583C21.9306 12.666 21.3856 12.5725 20.9883 12.8483Z" fill="#959697"/>
                          </g>
                          </svg>
                        </span>
                      </div>
                      <input type="email" class="form-control" placeholder="Email Address" name="email" aria-label="Email Address" value={userData.email} onChange={handleChange}/>
                    </div>
                    {signuperror === 409 && <span style={{fontSize:'12px', color: 'red'}}>User Already Exist</span>}
                  </div>
                  <div className="col-lg-12">
                    <label>Password</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <svg width="20" height="18" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8283 15.3775C11.8283 14.2315 10.8993 13.3025 9.75331 13.3025C8.60732 13.3025 7.67831 14.2315 7.67831 15.3775C7.67659 15.8634 7.84962 16.3336 8.16581 16.7025C8.4844 17.0345 8.59484 17.5147 8.45331 17.9525L8.19081 18.7275C8.08929 19.076 8.1564 19.452 8.37223 19.7438C8.58806 20.0357 8.92784 20.21 9.29081 20.215H10.2158C10.5908 20.2149 10.9427 20.0339 11.1609 19.7289C11.3791 19.424 11.4368 19.0325 11.3158 18.6775L11.0033 17.9025C10.8618 17.4647 10.9722 16.9845 11.2908 16.6525C11.6141 16.3038 11.8044 15.8525 11.8283 15.3775Z" fill="#959697"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6908 8.15252V4.25252C15.6915 3.23367 15.2776 2.2584 14.5443 1.55105C13.811 0.8437 12.8215 0.465167 11.8033 0.502525H7.70331C6.68514 0.465167 5.69561 0.8437 4.96232 1.55105C4.22903 2.2584 3.81512 3.23367 3.81581 4.25252V8.15252C1.22645 9.0642 -0.348293 11.6888 0.0658096 14.4025L1.25331 21.615C1.83901 24.4881 4.3969 26.5302 7.32831 26.465H12.1783C15.1307 26.5391 17.7068 24.475 18.2783 21.5775L19.4658 14.365C19.8574 11.6574 18.2744 9.05224 15.6908 8.15252ZM5.6908 4.25252C5.73813 3.18707 6.63764 2.36019 7.7033 2.40252H11.8033C12.869 2.36019 13.7685 3.18707 13.8158 4.25252V7.71502H5.6908V4.25252ZM16.0033 21.2025L17.2533 13.965C17.3774 13.0287 17.0798 12.0856 16.4408 11.39C15.6638 10.5088 14.5405 10.0112 13.3658 10.0275H6.14081C4.97005 10.0048 3.84745 10.4931 3.06581 11.365C2.44208 12.0773 2.16727 13.03 2.31581 13.965L3.50331 21.2025C3.89087 22.9854 5.50543 24.2307 7.32831 24.1525H12.1783C14.0012 24.2307 15.6157 22.9854 16.0033 21.2025Z" fill="#959697"/>
                          </svg>
                        </span>
                      </div>
                      <input type="password" class="form-control" placeholder="Password" name="password" aria-label="password" value={userData.password} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="login-btn" onClick={handleSignup}>
                      <button className="">{loader ? <Spinner/> : "CREATE ACCOUNT"}</button>
                    </div>
                  </div>                      
                </form>
                <div className="signup-footer">
                    <p>By clicking "Create Account" you agree to Theboxlane's Terms of Use and Privacy Policy</p>
                    <span onClick={()=> props.setSigninsection('Signin')} className="cre-acc">Already have an account? Sign In</span>
                    
                </div>
              </div>
              :
              <div className="singup-wrapper">
              <form action="" className="row">
                <div className="col-lg-6">
                  <label>Enter Verification Code</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-regular fa-user"></i></span>
                    </div>
                    <input type="number" class="form-control" placeholder="Enter Code" name="verificationcode" aria-label="verificationcode" value={code} onChange={(e) => setCode(e.target.value)}/>
                  </div>
                </div>  
                <div className="col-lg-12">
                    <div className="login-btn" onClick={handleVerificationSignup}>
                      <button className="">{loader && code ? <Spinner/> : "Enter"}</button>
                    </div>
                  </div>                    
              </form>
            </div>
              }
            </div>
            </>
  )
}

export default Signup;