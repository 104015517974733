import React, { useEffect } from 'react';
import './AlertComponent.css';

const AlertComponent = ({ type, message, visible, onClose }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [visible, onClose]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return visible ? (
    <div className={`alerttoast ${type}`}>
      <span>{message}</span>
      <button className="close-button" onClick={handleClose}>
        &times;
      </button>
    </div>
    
  ) : null;
};

export default AlertComponent;
