import React from 'react';
import { useState } from 'react';


export const SelectedOptionContext = React.createContext();

export const SelectedOptionProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [unitprice, setUnitPrice] = useState(0);
  const [customSizes, setCustomSizes] = useState({ x: 0, y: 0, z: 0 });
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [showMailerBox, setShowMailerBox] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [adress, setAdress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [material , setMaterial] = useState("");
  const [paper , setPaper] = useState("");
  const [Coating , setCoating] = useState("");
  const [boxname , setSetboxname] = useState("");
  const [slides, setSlides]= useState("");
  const [paymentmoadal , setPaymentmoadal] = useState(false);
  const [time, setTime]= useState("Standard (10-12 Business Days)");
  const [updateorder, setUpdateorder]= useState(null);
  return (
    <SelectedOptionContext.Provider value={{
      selectedOption, setSelectedOption,
      selectedOption1, setSelectedOption1, quantity, setQuantity,
      totalPrice, setTotalPrice , unitprice, setUnitPrice ,
      customSizes , setCustomSizes , 
      estimatedCost, setEstimatedCost,
      showMailerBox, setShowMailerBox,
      firstName, setFirstName,
      secondName, setSecondName,
      Coating , setCoating,
      boxname , setSetboxname,
      adress, setAdress,
      zipcode, setZipcode,
      city, setCity,
      phone, setPhone,
      material , setMaterial,
      paper , setPaper,
      slides, setSlides,
      time, setTime,
      state , setState,
      email, setEmail,
      updateorder, setUpdateorder,
      paymentmoadal , setPaymentmoadal
      
      }}>
      {children}

    </SelectedOptionContext.Provider>
  );
};
