import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SelectedOptionContext } from "../../../Utils/Formdata";
import { Icon } from "@iconify/react";
import "../../../App.css";
import { setShowpopup } from "../../../Redux/Slices/LoginSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { AuthContext } from "../../../context/AuthContext";

const MylarBagForm = ({ xyzvalue, setxyzValues, setShowMailerBox }) => {
  const { setQuantity } = useContext(SelectedOptionContext);
  const { selectedOption, setSelectedOption } = useContext(
    SelectedOptionContext
  );
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isCustomizeClicked, setIsCustomizeClicked] = useState(false);
  const [isStandardClicked, setIsStandardClicked] = useState(true);
  const [isToggleOn, setIsToggleOn] = useState(false);

  const [Llength, setLlength] = useState("");
  const [Hheight, setHheight] = useState("");

  const [Q, setQ] = useState(0);
  const [gsm, setGsm] = useState("");
  const [unitCost, setunitCost] = useState(0);
  const [Rush, setRush] = useState(false);
  const [standard, setStandard] = useState(true);
  const { unitprice, setUnitPrice } = useContext(SelectedOptionContext);
  const { estimatedCost, setEstimatedCost } = useContext(SelectedOptionContext);
  const { material, setMaterial } = useContext(SelectedOptionContext);
  const { boxname, setSetboxname } = useContext(SelectedOptionContext);
  const { slides, setSlides } = useContext(SelectedOptionContext);
  const { time, setTime } = useContext(SelectedOptionContext);

  const [materialErrorMessage, setMaterialErrorMessage] = useState("");
  const [LWDErrorMessage, setLWDErrorMessage] = useState("");
  const [slidesErrorMessage, setSlidesErrorMessage] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    setSetboxname("mylarbag");
    setMaterial("");
    setQuantity(500)
    setQ(500)
  }, []);

  const { user } = useContext(AuthContext);


  const [nameError, setnameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [phoneError, setphoneError] = useState("");

  const [name, setname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setemail] = useState("");

  const handleSelectChange = (e) => {
    const optionValue = e.target.value;
    setSelectedOption(optionValue);
    setShowMailerBox(true);

    if (e.target.value.z === 0 || e.target.value.y === 0) {
      setLWDErrorMessage(<span style={{ color: "red" }}>Select L x H</span>);
    } else {
      setLWDErrorMessage("");
    }

    switch (optionValue) {
      case "4 x 6":
        setxyzValues({ y: 4, z: 6 });
        break;
      case "5 x 8":
        setxyzValues({ y: 5, z: 8 });
        break;
      case "6 x 9":
        setxyzValues({ y: 6, z: 9 });
        break;

      default:
        break;
    }
  };

  useEffect(() => {}, [Rush]);

  // Destructure quantity from the context
  const { quantity } = useContext(SelectedOptionContext);

  const submit = () => {
    if (
      xyzvalue.z === 0 ||
      xyzvalue.z === "" ||
      isNaN(xyzvalue.z) ||
      xyzvalue.y === 0 ||
      xyzvalue.y === "" ||
      isNaN(xyzvalue.y)
    ) {
      setLWDErrorMessage(
        <span style={{ color: "red" }}>Select Length x Height</span>
      );
    } else if (slides === "" || slides === "select") {
      setSlidesErrorMessage(
        <span style={{ color: "red" }}>Select Printed Sides</span>
      );
    } else if (Q < 500 || isNaN(Q)) {
      setErrorMessage(
        <span style={{ color: "red" }}>Quantity must be at least 500</span>
      );
    } else {
      setErrorMessage("");
      setLWDErrorMessage("");
      setMaterialErrorMessage("");
      setSlidesErrorMessage("");
      setnameError("");
      setemailError("");
      setphoneError("");
      setname("");
      setemail("");
      setPhone("");
      // navigate("/screen2");
      submitFormtoApi();
    }
    setSelectedOption(xyzvalue.y + " x " + xyzvalue.z);
  };

  const submitFormtoApi = () => {
    const data = {
      // Length: L,
      Length: Llength,
      Depth: Hheight,
      Quantity: Q,
      Material: gsm,
      unitCost: unitCost,
      estimatedCost: estimatedCost,
      PrintedSides: slides,
      name: name,
      email: email,
      phone: phone,
    };

    // const cookieUser = Cookies.get("currentloginUserboxlane");
    // const isuser = localStorage.getItem("currentloginUserboxlane");

    //  console.log("isuser",isuser)
    if(!user){
      dispatch(setShowpopup(true));
    }
    if(user){
    navigate("/shipping-address");
    }
  };


  const handleQuantityChange = (qty) => {
    console.log("qty", qty);
    setQ(qty);
    console.log("selectedOption", selectedOption);
    
    if (
      xyzvalue.z === 0 ||
      xyzvalue.z === "" ||
      isNaN(xyzvalue.z) ||
      xyzvalue.y === 0 ||
      xyzvalue.y === "" ||
      isNaN(xyzvalue.y)
    ) {
      setLWDErrorMessage(
        <span style={{ color: "red" }}>Select Length x Height</span>
      );
    }
    if (qty < 500 || isNaN(qty)) {
      setErrorMessage(
        <span style={{ color: "red", marginLeft: "1rem" }}>
          Quantity must be at least 500
        </span>
      );
    } else if (qty > 10000) {
      setErrorMessage(
        <span style={{ color: "red", marginLeft: "1rem" }}>
          Quantity cannot exceed 5000
        </span>
      );
    } else {
      setErrorMessage("");
    }
    setQuantity(qty);
  };

  const handleStandardChange = (event) => {
    setRush(false);
    setStandard(true);
    setTime(event.target.value);
  };

  const handleRushChange = (event) => {
    setRush(true);
    setStandard(false);
    setTime(event.target.value);
  };

  const handlePrintedData = (event) => {
    setSlides(event.target.value);
    if (event.target.valuees === "select") {
      setSlidesErrorMessage(
        <span style={{ color: "red" }}>Select Printed Sides</span>
      );
    } else {
      setSlidesErrorMessage("");
    }
  };

  useEffect(() => {
    if (Q <= 5000) {
      let cost = calculateEstimatedCost();
      if (Rush !== false) {
        cost = parseInt(calculateEstimatedCost());
        var addon = cost * 0.35;
        cost = cost + addon;
      }
      setEstimatedCost(cost);
      console.log("cost", cost);
    }
    // Check if quantity is 50 and all dimensions are 1, then set unit price to 121.52
  }, [Q, Rush, selectedOption]);

  const calculateEstimatedCost = () => {
    let totalOrderPrice;
    if (selectedOption === "4 x 6") {
      let UnitCost;

      if (Q === 500) {
        UnitCost = 0.72;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 1000) {
        UnitCost = 0.59;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 2500) {
        UnitCost = 0.47;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 5000) {
        UnitCost = 0.42;
        totalOrderPrice = Q * UnitCost;
      }

      setUnitPrice(UnitCost);
    }
    if (selectedOption === "5 x 8") {
      let UnitCost;

      if (Q === 500) {
        UnitCost = 0.76;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 1000) {
        UnitCost = 0.65;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 2500) {
        UnitCost = 0.5;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 5000) {
        UnitCost = 0.47;
        totalOrderPrice = Q * UnitCost;
      }

      setUnitPrice(UnitCost);
    }
    if (selectedOption === "6 x 9") {
      let UnitCost;

      if (Q === 500) {
        UnitCost = 0.99;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 1000) {
        UnitCost = 0.85;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 2500) {
        UnitCost = 0.76;
        totalOrderPrice = Q * UnitCost;
      }
      if (Q === 5000) {
        UnitCost = 0.67;
        totalOrderPrice = Q * UnitCost;
      }

      setUnitPrice(UnitCost);
    }
    return totalOrderPrice;
  };

  return (
    <>
      <div className="controunded rounded bg-light">
        <div className="row" style={{ margin: "0" }}>
          <div className="form-wrapper" style={{ padding: "0px 0px" }}>
            <div className="form-header">
              <span>Customize & Check Prices</span>
            </div>
            <div className="form-fields">
              <div className="buttoncont w-100 mb-2 mx-auto">
                <div className="button-container d-flex justify-content-around">
                  <button
                    className={`btn w-100 formwidth ${
                      isStandardClicked ? "active standard" : ""
                    }`}
                    onClick={() => {
                      setSelectedOption("");
                      setIsCustomizeClicked(false);
                      setIsStandardClicked(true);
                      setIsToggleOn(false);
                    }}
                  >
                    STANDARD SIZES
                  </button>
                </div>
              </div>
              <div>
                <div className="px-3">
                  <>
                    <label for="Interior Dimensions (Length x Width x Height)">
                      Interior Dimensions (Length x Height)
                    </label>
                    <select
                      name=""
                      id=""
                      className="form-select small-input"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      required
                    >
                      <option>Select Dimensions</option>
                      <option value="4 x 6">4" x 6"</option>
                      <option value="5 x 8">5" x 8"</option>
                      <option value="6 x 9">6" x 9"</option>
                    </select>
                    <div>{LWDErrorMessage}</div>
                  </>
                </div>
                <div>
                  <div className="px-3 mt-2">
                    <label for="Printed Sides">Printed Sides</label>
                    <select
                      name=""
                      id=""
                      value={slides}
                      className="form-select small-input"
                      onChange={handlePrintedData}
                      required
                    >
                      <option value="select">Select Printed Sides</option>
                      <option value="Outside Only - Full Color">
                        Outside Only - Full Color
                      </option>
                      <option value="Inside Only - Full Color">
                        Inside Only - Full Color
                      </option>
                      <option value="Outside & Inside - Full Color">
                        Outside & Inside - Full Color
                      </option>
                      <option value="No Printing">No Printing</option>
                    </select>
                    <div>{slidesErrorMessage}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="px-3 mt-2">
                        <label htmlFor="Quantity">Quantity</label>
                        <select
                          name=""
                          id=""
                          className="form-control w-100 small-input"
                          value={Q}
                          onChange={(e) =>
                            handleQuantityChange(parseInt(e.target.value))
                          }
                          required
                        >
                          <option disabled value="select">
                            Select Quantity
                          </option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                          <option value="2500">2500</option>
                          <option value="5000">5000</option>
                        </select>
                        {errorMessage}
                      </div>
                    </div>
                    {xyzvalue.y &&
                      xyzvalue.z &&
                      unitprice &&
                      estimatedCost &&
                      Q >= 500 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="price-panel">
                            <div className="unitprice">
                              <div className="d-flex justify-content-end">
                                <span className="me-3 mt-2">Unit Price</span>
                                <p>{"$ " + unitprice}</p>
                              </div>
                            </div>
                            <div className="subtotal">
                              <div className="d-flex justify-content-end">
                                <span className="me-3">Subtotal</span>
                                <p>{"$ " + estimatedCost}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="custom-quantity-container">
                    <label htmlFor="CustomQuantity">For Custom Order</label>

                    <a
                      className="custom-quantity-btn"
                      href="https://theboxlane.com/contact-us/"
                    >
                      Contact Us <i className="fa fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                  <div className="row mx-2 mt-3 px-2 g-1">
                    <div className="col-lg-12 col-md-12">
                      <div className="text-light turnaround-panel">
                        <div className="turnaround">Turnaround Time</div>
                        <div className="shipping-check">
                          <div className="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="shippingOption"
                              value="Standard (10-12 Business Days)"
                              onChange={handleStandardChange}
                              checked={standard}
                              required
                            />
                            <label
                              class="form-check-label"
                              for="Standard (10-12 Business Days)"
                            >
                              Standard (10-12 Business Days)
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="shippingOption"
                              value="Rush (5-6 Business Days)"
                              onChange={handleRushChange}
                              required
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              Rush (5-6 Business Days)
                            </label>
                          </div>
                        </div>
                      </div>
                      <p className="design-s">We Offer Free Design Support</p>
                    </div>
                  </div>
                  <div className="mx-3 mt-1 submit-btn-wrapper">
                    <button onClick={submit}>
                      Order now and Receive Dieline
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MylarBagForm;
