// ForgotPasswordForm.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import useAlert from '../../../hooks/useAlert/useAlert';
import AlertComponent from '../../AlertComponent/AlertComponent';
import { useDispatch } from 'react-redux';
import { setMessageSlice } from '../../../Redux/Slices/LoginSlice';

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const { alertmsg, showAlert, hideAlert } = useAlert();

  const [loader, serLoader] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    serLoader(true)
    try {
      await axios.post('https://boxlane-61739ddb4424.herokuapp.com/api/v1/forgot-password', { email });
      setMessage('Password reset instructions sent to your email');
      serLoader(false)
      dispatch(setMessageSlice("Email-sent"));
      props.setSigninsection('Signin')
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  const handleClose = () => {
    navigate('/')
  };

  return (
    <>
    <AlertComponent
          type={alertmsg.type}
          message={alertmsg.message}
          visible={alertmsg.visible}
          onClose={hideAlert}
        />


<div className="col-lg-6">
      <div className="singup-wrapper">
        <form onSubmit={handleSubmit} className="row mx-auto">
          <div className="col-lg-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1"><i className="fa fa-solid fa-envelope"></i></span>
              </div>
              <label className="sr-only">Email Address</label>
              <input
          type="email"
          className="form-control"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          required
        />
            </div>
            </div>
            <div className="col-lg-12">
            <div className="login-btn">
              <button type="submit">{loader ? <Spinner/> : "Reset Password"}</button>
            </div>
          </div>
            </form>
            {message && <p>{message}</p>}
            </div>
    </div>
    </>
  );
};

export default ForgotPasswordForm;
