import React, { useState } from 'react';
import Infopopup from './Infopopup';

const OrderComponent = (props) => {

    const orderDetailsCopy = [...props.orderDetails];
    // Check if there are unpaid orders
    // const hasUnpaidOrders = orderDetailsCopy.every(order => order.payment === "not-paid");
    const paidOrders = orderDetailsCopy.filter(order => order.payment === "paid");


    const [selectedOrder, setSelectedOrder] = useState(null);

    const handleViewDetail = (order) => {
        setSelectedOrder(order);
    };

    const handleClosePopup = () => {
        setSelectedOrder(null);
    };

    return (
        <div className="container mt-4">
            {paidOrders.length > 0  ?
                <div className="row">
                    <div className="col">
                        <div className="">
                            <div className="card-header">
                                <h3>All Orders</h3>
                            </div>
                            <div className="card">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th  style={{textAlign:'center'}}>#</th>
                                            <th  style={{textAlign:'center'}}>Box Style</th>
                                            <th>Status</th>
                                            <th>Payment</th>
                                            <th>Product Name</th>
                                            <th>Order Date</th>
                                            <th>Order Detail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paidOrders.map((order, index) => (
                                            <tr key={index}>
                                                <th  style={{textAlign:'center'}}>{index+1}</th>
                                                <td>
                                                    <div class="order-item">
                                                {order.boxtype === "Product" &&<img src="https://theboxlane.com/products/assets/product-images/Tuck-Boxes/2-tuck-box.png" alt=""/>}
                                                {order.boxtype === "Mailer" &&<img src="https://theboxlane.com/products/assets/product-images/Shipping-and-mailer-boxes/Mailer-Boxes-2.png" alt=""/>}
                                                {order.boxtype === "Magnetic" &&<img src="https://theboxlane.com/assets/web-images/magnetic-closure-rigid-boxes/1_magnetic_closure_rigid_boxes.webp" alt=""/>}
                                                {order.boxtype === "mylarbag" &&<img src="https://theboxlane.com/assets/web-images/ediblemylarbags/1_edibles_mylar_bags.webp" alt=""/>}
                                            </div></td>
                                                <td style={{textTransform: 'uppercase'}}>{order.status}</td>
                                                <td style={{textTransform: 'uppercase'}}>{order.payment}</td>
                                                <td style={{textTransform: 'uppercase'}}>{order.boxtype}</td>
                                                <td>{new Date(order.createdAt).toLocaleDateString('en-US')}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-yellow-table" onClick={() => handleViewDetail(order)}>View Detail</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div class="row w-100 justify-content-center mx-0">
               <div class="col-12 col-md-7 col-lg-6 col-xl-5 border py-3 mt-3">
                   <h3 class="text-center">You have no order yet</h3>
                   <div class="d-flex justify-content-center shopnow">
                    {/* <span>Shop now</span> */}
                    <a href="https://theboxlane.com/">Shop now</a>
                    </div>
               </div>
           </div>
            }
            {selectedOrder &&
                <Infopopup order={selectedOrder} onClose={handleClosePopup} />
            }
        </div>
    );
};

export default OrderComponent;
