import { configureStore } from "@reduxjs/toolkit";

import LoginSlice from "../Slices/LoginSlice.js";
import UserSlice from "../Slices/UserSlice.js";

export const store = configureStore({
    reducer: {
        loginpopup: LoginSlice, //login store
        user: UserSlice
    }
})