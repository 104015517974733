import React from 'react'
import Productbox from './ProductBoxModel/Productbox'
import MaterialTab from '../../Components/MaterialTab/MaterialTab'

const ProductboxMain = () => {

    return (
    <>
      <div>       
        <div className="breadcrump-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="breadcrump-menu">
                            <ul>
                                <li><a href="/"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.35888 13.7714C9.2618 13.7312 9.17881 13.6632 9.12042 13.5758C9.06203 13.4885 9.03085 13.3858 9.03083 13.2807L9.03083 9.03069L2.65583 9.03069C2.51494 9.03069 2.37981 8.97472 2.28018 8.87509C2.18055 8.77546 2.12458 8.64034 2.12458 8.49944C2.12458 8.35855 2.18055 8.22342 2.28018 8.12379C2.37981 8.02416 2.51494 7.96819 2.65583 7.96819L9.03083 7.96819L9.03083 3.71819C9.03075 3.61306 9.06186 3.51027 9.12023 3.42283C9.1786 3.33539 9.2616 3.26723 9.35872 3.22699C9.45585 3.18674 9.56273 3.17622 9.66584 3.19675C9.76895 3.21729 9.86364 3.26795 9.93794 3.34233L14.7192 8.12358C14.7686 8.17292 14.8078 8.23151 14.8345 8.296C14.8612 8.3605 14.875 8.42963 14.875 8.49944C14.875 8.56926 14.8612 8.63839 14.8345 8.70288C14.8078 8.76737 14.7686 8.82596 14.7192 8.8753L9.93794 13.6566C9.8636 13.7308 9.76891 13.7814 9.66584 13.8018C9.56276 13.8223 9.45594 13.8117 9.35888 13.7714Z" fill="#d7ab48" ></path>
                                    </svg>Home</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="breadcrump-links">
                            <ul>
                                <li><a href="https://theboxlane.com/faqs/">Help &amp; FAQ</a></li>
                                <span></span>
                                <li>Questions? <a href="https://theboxlane.com/contact-us/"><b>Message Now</b></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
       
        <div className="product-form-gallery"><Productbox /></div>
        

        <MaterialTab/>

        
        
        <section className="product-content-banner-wrapper" style={{ background: '#F3F4F6', marginTop: 0 }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="product-content-banner-img">
                            <img src="https://theboxlane.com/assets/images/3d-product/product-boxes/banner_1_product_boxes.webp" alt="What are Custom Mailer Boxes | The Box Lane"/>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                        <div className="product-content-banner-wrap">
                            <h2>What are tuck boxes?</h2>
                            <p>Custom tuck boxes are a popular style of packaging that is both handy and appealing. They are used by brands to showcase their products and make them stand out from the competition.</p>
                            <p>Tuck-end boxes have two openings that can either open in the same or opposite direction with one opening on the top and one on the bottom of the box. Sizes typically range anywhere from 0.75” x 0.75” x 1.75” to 12” x 6” x 12”.</p>
                            <p>There are two types of tuck-end boxes: straight tuck-end boxes and reverse tuck-end boxes. Straight tuck-end boxes are the most common type of folded box used for products and other types of packaging. Reverse tuck-end boxes are very similar to straight tuck-end boxes, but the top and bottom openings open in opposite directions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <div className="faq">
            <div className="container">
                <div className="row d-flex justify-content-center pb-2">
                    <div className="col-xl-9 col-lg-9">
                        <div className="heading-section">
                            <h2>Frequently Asked <span className="sign-line z-index-2">Questions</span></h2>
                            <p>These are some of the repeated questions we get. Ask us on WhatsApp or Chat if we missed something.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="faq-content responsive-pd">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                <div className="card-header" id="headingOne">
                                       
                                            <button className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                      Can I order custom tuck boxes in the design and size that I want?    

                                            </button>
                                      
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                     We at The Box Lane make fully custom tuck boxes. We keep sizes and designs as per the customers’ requirements. You get the boxes the way you want.      

                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                      
                                            <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                         Do you offer help with packaging design?   
                                            </button>
                                      
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                      Certainly, you can always get help from our custom packaging experts for the size and design of custom packaging. 
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                       
                                            <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            How quickly can you process my order?     
                                            </button>
                                        
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                          We make and ship all expedited orders within 8-10 days (about 1 and a half weeks) and all regular orders within 14-18 days (about 2 and a half weeks).
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingSeven">
                                       
                                            <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                 

                                           What is your minimum order quantity (MOQ)?      
                                            </button>
                                       
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                         Our minimum order quantity is 100 units for most items.  This MOQ is per size, per design, and box style. Please contact us to find out the MOQ for your desired product.   
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingEight">
                                       
                                            <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                           Can I purchase a sample before placing an order?      
                                            </button>
                                       
                                    </div>
                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                     Yes, you can order a sample. See a 2D or 3D mockup or get a free sample to check the quality of our materials, finishing, and add-ons. Also, see what your custom packaging will actually look like.       
                                        </div>
                                    </div>
                                </div>
                                      <div className="card">
                                    <div className="card-header" id="headingnine">
                                       
                                            <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                            Can you provide me with a dieline template?      
                                            </button>
                                       
                                    </div>
                                    <div id="collapsenine" className="collapse" aria-labelledby="headingnine" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                      Yes, you can get a dieline once you have placed a sample or a batch order.   

  
                                        </div>
                                    </div>
                                </div>
                                   <div className="card">
                                    <div className="card-header" id="headingten">
                                       
                                            <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                           What payment methods do you accept? 
                                            </button>
                                       
                                    </div>
                                    <div id="collapseten" className="collapse" aria-labelledby="headingten" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                    We accept PayPal, Square, Credit Card, Cash Deposit, or Wire.
                                        </div>
                                    </div>
                                </div>
                                         <div className="card">
                                    <div className="card-header" id="headingeleven">
                                       
                                            <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false" aria-controls="collapseeleven">
                                       Do you ship worldwide?         
                                            </button>
                                       
                                    </div>
                                    <div id="collapseeleven" className="collapse" aria-labelledby="headingeleven" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                    We ship to the whole world. You get free shipping in the USA, UK, Australia, and Canada.    
                                        </div>
                                    </div>
                                </div>
                    
                            </div>
                            <div className="more-faqs">
                                <a href="https://theboxlane.com/faq/"><i className="fa fa-solid fa-arrow-right"></i>MORE QUESTION & ANSWERS   </a>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
        </div>
     
        <section className="category-content-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="category-content-left">
                        <h2>Custom Tuck Packaging Boxes for Every Industry</h2>
                        <p>Tuck boxes are versatile and popular types of packaging that can be used for various products, from food to cosmetics to electronics.</p>
                        <p>They are perfect for small businesses and large corporations alike. Tuck boxes are made from cardboard or paperboard and can be printed with your logo or product information. They are often used for retail packaging products, such as clothing, cosmetics, and other small items.</p>
                        <p>Tuck-in boxes are one of the most popular types of packaging boxes. They are easy to assemble and offer a great way to protect your products. Tuck-in boxes are usually shipped flat and require minimal assembly.</p>
                            <a href="https://theboxlane.com/get-a-free-quote/" className="btn btn-black w-icon-right">Get a Free Quote <i className="fa fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="category-content-right">
                            <img src="https://theboxlane.com/assets/images/3d-product/product-boxes/custom_product_banner_3.webp" alt="Mailer Boxes For All Industries | The Box Lane"/>
                            <h2>Why Choose The Box Lane?</h2>
                              <p>The Box Lane has been in the custom packaging field for over 8 years. We offer solutions for a wide range of products and industries.</p>
                              <p>Here are a few reasons on why you should choose The Box Lane for your brand’s custom packaging.</p>
                              <p><b>Customized and Recyclable</b>: The Box Lane offers 100% customized and 100% recyclable tuck boxes that suit your specific needs and preferences. You can choose from a variety of sizes, shapes, colors, and designs to create your own unique packaging.</p>
                              <p><b>Personalized and Professional</b>: The Box Lane has a team of design experts who will work with you to create eye-catching and compelling tuck boxes that will boost your product’s visibility and appeal. You can also get free 2D and 3D digital proofs and physical samples before production.</p>
                              <p><b>Fast and Affordable</b>: The Box Lane delivers high-quality tuck boxes in 8-10 business days with standard shipping and 4-6 business days with express shipping. You can also get instant quotes and competitive prices for your orders.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
      </div>
    </>
  )

}

export default ProductboxMain