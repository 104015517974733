import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import TWEEN from "@tweenjs/tween.js";
// import Form from "../../Form";
import Form from '../../../Form';
import "../../../App.css";
// import mailer1 from './Mailer Box.png';
import { Icon } from '@iconify/react';
import MagneticBoxForm from "../MagneticBoxForm/MagneticBoxForm";

const Magneticbox = () => {
  const [xyzvalue, setxyzValues] = useState({ x: '' , y: '', z: '' });
  const [animateLid, setAnimateLid] = useState(false);
  const [showMailerBox, setShowMailerBox] = useState(false); // Updated initial state
  const [buttonType, setButtonType] = useState('reverse');
 
  // const [selectedColor, setSelectedColor] = useState(0xffa500);
  const [getLid, setLid] = useState({ x: 1 , y: 1, z: 1 });

  const box = new URL("../../../Models_gltf/MagneticBox.gltf", import.meta.url);
  const containerRef = useRef();
  const lidRef = useRef();

  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  
    renderer.setPixelRatio(window.devicePixelRatio * 1);

    renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
    renderer.domElement.style.position = "relative";
    renderer.domElement.style.right = "0%";
    renderer.domElement.style.top = "0%";

    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }

    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#fbfaf5"); // Set the background color to light blue
    let cameraFOV = 10; // Default camera FOV
    const camera = new THREE.PerspectiveCamera(cameraFOV, window.innerWidth / window.innerHeight, .5, 1000);
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0.7, 0.7, 1);
    scene.add(directionalLight);
    
    const hemisphereLight = new THREE.HemisphereLight("", "" , 1);
    scene.add(hemisphereLight);
    renderer.setClearColor(0xffffff);

    const orbit = new OrbitControls(camera, renderer.domElement);
    camera.position.set(25, 2.9, -7);

    // camera.position.set(8, 5, 15);
    orbit.update();
    
    let mixer;

    if (showMailerBox) {
      const mailerbox = new GLTFLoader();
      mailerbox.load(box.href, function (gltf) {

        const model = gltf.scene;
        
        // scene.add(model);

        // const model = gltf.scene;
      model.position.set(0, -0.4, 0);  // Set the position to center
      scene.add(model);

        // Animation Part Start
        mixer = new THREE.AnimationMixer(model);

        const clips = gltf.animations;

        clips.forEach(function (clip) {
          const action = mixer.clipAction(clip);
          action.play();
        });
        // Find the lid object and store it in a ref
        const lid = model.getObjectByName("Bone");
        const lid1 = model.getObjectByName("Bone_1");

        // fixedLid(lid);
 
        lidRef.current = lid;
        console.log(lid.rotation)

        // lid.rotation.y = -1
        lid1.rotation.y = 0.5
        // lid.scale.set(1, 1, 1);
      
        // lid1.scale.set(0.8,1,1.6)

        lid.rotation.x = -1.57;
        lid.rotation.z = 0;
        // lid.rotation.y = animateLid ? Math.PI / 1 : -1.5;
        lid.rotation.y = -1.4;
        // lid.scale.set(1, 1, 1);

        lid1.scale.set(1, 1, 1);
        lid.scale.set(1, 1, 1);

          // CAMERA POSITION START
        const maxDimension = xyzvalue.x + xyzvalue.y + xyzvalue.z;
        // const maxDimension = Math.sqrt(xyzvalue.x ** 2 + xyzvalue.y ** 2 + xyzvalue.z ** 2);

        console.log("maxDimension",maxDimension)

        // if (maxDimension < 12) {
        //   cameraFOV = 20; // Adjust the FOV for small boxes
        // }
        // else if (maxDimension < 17) {
        //   cameraFOV = 8; // Adjust the FOV for small boxes
        // }

        // else if (maxDimension < 25) {
        //   cameraFOV = 13; // Adjust the FOV for small boxes
        // }
        // else if (maxDimension < 32) {
        //   cameraFOV = 18; // Adjust the FOV for small boxes
        // }
        // else if (maxDimension < 40) {
        //   cameraFOV = 22; // Adjust the FOV for small boxes
        // }
       
        // else {
        //   cameraFOV = 45 ; // Adjust the FOV for larger boxes
        // }

        // if (maxDimension < 18) {
        //   cameraFOV = 8;
        //   // model.position.set(0, -0.2, 0);
        // } else if (maxDimension >= 18 && maxDimension < 28) {
        //   cameraFOV = 9;
        //   // model.position.set(0, -0.4, 0);
        // } else if (maxDimension >= 28 && maxDimension < 38) {
        //   cameraFOV = 10;
        //   // model.position.set(0, -0.6, 0);
        // }
        // else if (maxDimension >= 38 && maxDimension < 48) {
        //   cameraFOV = 12;
        //   // model.position.set(0, -0.6, 0);
        // }
        // else if (maxDimension >= 48 && maxDimension < 60) {
        //   cameraFOV = 14;
        //   // model.position.set(0, -0.6, 0);
        // }  else {
        //   cameraFOV = 16;
        // }

        // Update camera position and FOV on every change in x, y, or z
      
      //   const updateCamera = () => {
      //   const averageDimension = (xyzvalue.x + xyzvalue.y + xyzvalue.z) / 3;

      //   // Adjust the FOV using linear interpolation
      //   const minDimension = 5; // Minimum average dimension
      //   const maxDimension = 35; // Maximum average dimension
      //   const minFOV = 10; // Minimum FOV
      //   const maxFOV = 35; // Maximum FOV

      //   const normalizedValue = (averageDimension - minDimension) / (maxDimension - minDimension);
      //   const interpolatedFOV = minFOV + normalizedValue * (maxFOV - minFOV);

      //   return interpolatedFOV;
      // };
      
      if (maxDimension <= 4) {
        cameraFOV = 2;
        // model.position.x = 0.3
      } else if (maxDimension > 4 && maxDimension <= 9) {
        cameraFOV = 2.9;
        // model.position.x = 0.3
      } else if (maxDimension > 9 && maxDimension <= 19) {
        cameraFOV = 3.4;
      } 
      // else if (maxDimension >= 14 && maxDimension < 19) {
      //   cameraFOV = 4;
      //   // model.position.x = 0.3
      // }
      else if (maxDimension > 19 && maxDimension <= 24) {
        cameraFOV = 3.9;
        // model.position.x = 0.3
      } else if (maxDimension > 24 && maxDimension <= 29) {
        cameraFOV = 4.5;
        // model.position.x = 0.3
      } else if (maxDimension > 29 && maxDimension < 34) {
        cameraFOV = 5;
        // model.position.x = 0.3
      } 
      else {
        cameraFOV = 6;
      }

      if (xyzvalue.y === 6) {
        model.position.y = -1;
      }
       else if (xyzvalue.y === 5) {
        model.position.y = -0.8;
      } else if (xyzvalue.y === 4) {
        model.position.y = -0.7;
      } else if (xyzvalue.y === 3) {
        model.position.y = -0.5;
      }
      else if (xyzvalue.y === 2) {
        model.position.y = -0.4;
      } else {
        model.position.y = -0.2;
      }

      // camera.fov = cameraFOV;
        
        // camera.position.set(5,5,-15);
        // camera.fov = updateCamera();

        camera.fov = cameraFOV;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        // CAMERA POSITION END

        // Set the initial scale of the model
        setxyzValues((prevValues) => {
          const modelObject = model.getObjectByName(model.name);
          modelObject.scale.x = prevValues.x * 0.1
          modelObject.scale.y = prevValues.y * 0.2
          modelObject.scale.z = prevValues.z * 0.1

          // modelObject.scale.x = prevValues.x * 0.1
          // modelObject.scale.y = prevValues.y * 0.1
          // modelObject.scale.z = prevValues.z; * 0.2

          return prevValues;
        });

        // const scaleMultiplier = 0.1;  
        // model.scale.set(xyzvalue.x * scaleMultiplier, xyzvalue.y * scaleMultiplier, xyzvalue.z * scaleMultiplier);

        const clock = new THREE.Clock();
        function animate() {
          if (mixer) mixer.update(clock.getDelta());
          renderer.render(scene, camera);
          TWEEN.update();
        }

        renderer.setAnimationLoop(animate);
        // Animation part Close
      });
    }

    return () => {
      if (containerRef.current && renderer.domElement && containerRef.current.contains(renderer.domElement)) {
        containerRef.current.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, [xyzvalue, showMailerBox]);



  const handleButtonClick = () => {

    // fixedLid(lid);

    const lid = lidRef.current;
    // lid.scale.set(1,1,1)
    setButtonType('customized');
    // Update the animateLid state variable
    setAnimateLid(true);

    // Delay the start of the TWEEN animation to close the lid
    setTimeout(() => {
      // Create a TWEEN animation to rotate the lid
      
      lid.scale.set(1,1,1)
      
      new TWEEN.Tween(lid.rotation)
        .to({ y: 1.57 }, 1000)
        .onComplete(() => {
          // Reset the animateLid state variable when the animation is complete
          
          setAnimateLid(false);

          // Create a TWEEN animation to rotate the second lid
          const lid1 = lidRef.current.getObjectByName("Bone_1");
          console.log(lid1.rotation)
          new TWEEN.Tween(lid1.rotation)
            .to({ y: 1.57 }, 500)
            .start()
            .onComplete(() =>{
              lid.scale.set(0.1,1,1)
              lid1.scale.set(1,1,1)
            })
        })
        .start();
    }, 0);
  };

  const handleReverseButtonClick = () => {
  setButtonType('reverse');
  // Create a TWEEN animation to rotate the child lid back to its original position
  const lid1 = lidRef.current.getObjectByName("Bone_1");
  const lid = lidRef.current;

  // lid.rotation.x = -1.57;
  // lid.rotation.z = 0;
  // lid.rotation.y = animateLid ? Math.PI / 1 : -1.5;

  lid.scale.set(1, 1, 1);
  lid1.scale.set(1, 1, 1);

  const childLidAnimation = new TWEEN.Tween(lid1.rotation)
    .to({ y: 0.57 }, 500)
    .onComplete(() => {
      // Once the child lid animation is complete, animate the parent lid
      const parentLidAnimation = new TWEEN.Tween(lid.rotation)
        .to({ x: -1.57, y:-1.4, z:0 }, 1000)
        .start();
        // if(xyzvalue.x <= 3){
          lid1.scale.set(1, 1, 1);
        // }else{
          lid.scale.set(1, 1, 1);
        
      parentLidAnimation.start();
    });

  childLidAnimation.start();
  
};

  const handleSwitchToggle = () => {
    setShowMailerBox(!showMailerBox);
  };

  return (
    <div className="container" style={{ padding: "0px 15px" }}>
      <div className="row">
        <div className="col-lg-7">
          <div className="product-details">
            <div className="product-content">
              {/* <p className="text-dark"> <span className="rounded-circle text-center  homeicon bg-purple"><Icon icon="heroicons:home-solid" color="white" /></span> <span>/</span> <b>Mailer Box</b></p> */}
              <h1 className="text-dark">Magnetic Boxes </h1>
              <p className="text-dark">High quality printing on durable  Corrugated  Cardboard.</p>
            </div>  
            <div className="product-gallery">
              {/* {showMailerBox ?   <div className="app">
              <div className="sidebar">
                {colorsData.map((color, index) => (
                  <div
                    key={index}
                    className={`color-circle ${selectedColor === color.code ? 'active' : ''}`}
                    style={{ backgroundColor: color.code }}
                    onClick={() => handleColorClick(color.code)}
                  ></div>
                ))}
              </div> */}
              {/* <div className="main-content">
                {selectedColor && <p>Selected Color: {selectedColor.name}</p>}
              </div> */}
            {/* </div> : ''} */}
           
              <div className="model-container">
                {!showMailerBox && (
                  <img src="https://theboxlane.com/assets/web-images/home/magnetic_.webp" alt="Mailer 1" />
                )}
                {/* {showMailerBox ? xyzvalue.z === 0 ? <span class="threed-modal-alternative">Please select the dimensions</span> : <div ref={containerRef}></div>
                :undefined} */}
              {
                showMailerBox ?
                (xyzvalue.x === '' || xyzvalue.y === '' || xyzvalue.z === '') ?
                <span class="threed-modal-alternative">Please select the dimensions</span>:
                  <div ref={containerRef}></div>
                : null
                }
                {showMailerBox && (
                  <div className="btn-group border rounded-pill open-close-toggle ">
                    <button onClick={handleReverseButtonClick} className={`btn btngrouphover ${buttonType === 'reverse' ? 'active' : ''} rounded-pill`}>
                      Open Lid
                    </button>
                    <button onClick={handleButtonClick} className={`btn  btngrouphover ${buttonType === 'customized' ? 'active' : ''} rounded-pill`}>
                      Close Lid
                    </button>
                  </div>
                )}
              </div>
              <div className="threed-toggle-btn">
                    <label className="switch">
                      <input type="checkbox" checked={showMailerBox} onChange={handleSwitchToggle} />
                      <span className="slider round "></span>
                    </label>
                    <span className="preview-btn">3d Preview</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="form-container">
            <MagneticBoxForm xyzvalue={xyzvalue} setxyzValues={setxyzValues} setShowMailerBox={setShowMailerBox} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Magneticbox;
